import { useCallback, useMemo } from 'react';
import _ from 'lodash';
import api from '../../api';
import { setCommunicatieOverlayState } from '../../one-off-components/CommunicatieOverlay';
import { ECommunicatieTabblad } from '../../one-off-components/CommunicatieOverlay/MenuHandle';
import { defaultOpstellenFormulierFormikValues } from '../../components/communicatie/EmailWerkblad/OpstellenFormulier';
import { IOphalenFacturenBasisResultElement } from '../../../../shared/src/api/v2/factuur';

interface IUseFacturenVersturenParams {
  facturen: IOphalenFacturenBasisResultElement[] | null;
  // relatie: IOphalenRelatiesResultElementV2;
}

interface IUseTPBVersturenOutput {
  versturenFacturen?: () => Promise<void>;
}

const useFacturenVersturen = (params: IUseFacturenVersturenParams): IUseTPBVersturenOutput => {
  const versturenFacturen = useMemo(() => {
    if (params.facturen === null) {
      return undefined;
    }

    const relID = _.uniq(params.facturen.map((x) => x.RelID))[0];

    return async () => {
      const relatie = (
        await api.v2.relatie.ophalenRelaties({
          filterSchema: {
            filters: [
              {
                naam: 'IDS',
                data: [relID],
              },
            ],
          },
        })
      ).relaties[0];

      const contextdatasResult = await api.v2.sjabloon.ophalenContextdatas({
        contextBepaalData: [
          {
            naamEnum: 'PERSOON',
            data: {
              factIDs: [651191], // params.facturen!.map((factuur) => factuur.FactID),
            },
          },
        ],
      });
      const resolveResult = await api.v2.sjabloon.resolve({
        communicatieKanaalSelectie: {
          naamEnum: 'EMAIL',
        },
        sjabloonSelectie: {
          naamEnum: 'facturen.versturen',
        },
        contexten: contextdatasResult.contexten,
      });

      const persoon = relatie.persoon!;

      setCommunicatieOverlayState!((curr) => ({
        ...curr,
        geselecteerdTabblad: ECommunicatieTabblad.Email,
        emailContext: {
          ...curr.emailContext,
          formulier: {
            ...defaultOpstellenFormulierFormikValues,
            taalID: resolveResult.taalID,
            subject: resolveResult.onderwerp ?? '',
            recipients: [
              {
                emailAdres: persoon.Email!,
                orgID: null,
                persID: persoon.PersID,
              },
            ],
            body: resolveResult.inhoud ?? '',
          },
        },
      }));
    };
  }, [params.facturen]);

  return {
    versturenFacturen,
  };
};

export default useFacturenVersturen;
