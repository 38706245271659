import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import PaginaHeader from '../PaginaHeader';
import { NavLink } from 'react-router-dom';
import Meldingen from './Meldingen';
import Opdrachten from './Opdrachten';
import Werkbonnen from './Werkbonnen';
import Diensten from '../Diensten/Beheer/Service';

interface IProps extends RouteComponentProps<{}> {}

const Service: React.FC<IProps> = (props) => {
  const { match } = props;
  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <PaginaHeader>
        <ul>
          <li>
            <NavLink
              to={`${match.url}/meldingen`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              {/* <IcoonService /> */}
              &nbsp; Meldingen
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${match.url}/opdrachten`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              {/* <IcoonService /> */}
              &nbsp; Rep.opdrachten
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${match.url}/werkbonnen`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              {/* <IcoonService /> */}
              &nbsp; Werkbonnen
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${match.url}/diensten`}
              style={{ paddingLeft: 20, paddingRight: 20 }}
              className="d-flex align-items-center justify-content-center"
            >
              {/* <IcoonService /> */}
              &nbsp; Servicediensten
            </NavLink>
          </li>
        </ul>
      </PaginaHeader>

      <Route path={`${match.path}/meldingen`} component={Meldingen} />
      <Route path={`${match.path}/opdrachten`} component={Opdrachten} />
      <Route path={`${match.path}/werkbonnen`} component={Werkbonnen} />
      <Route path={`${match.path}/diensten`} component={Diensten} />

      <Route
        exact={true}
        path={match.path}
        render={() => <Redirect to={`${match.path}/meldingen`} />}
      />
    </div>
  );
};

export default Service;
