import { makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';
import ICheckData from '../../../shared/src/models/ICheckData';

import { v4 as makeUuid } from 'uuid';

export interface IOptions {
  dialoogIndex?: number;
}

export interface IControlerenParams {
  titel?: JSX.Element | string;
  checkData: ICheckData;
  options?: IOptions;
}

export interface IBevestigenParams {
  titel?: JSX.Element | string;
  inhoud?: JSX.Element | string;
  /**
   * Soms is het de bedoeling dat er tijdens het indrukken van de bevestigingsknop er nog een
   * actie wordt uitgevoerd waarbij het dialoog nog niet gesloten mag worden. Je kan hier dus een
   * functie opgeven die geawait wordt voordat het dialoog pas echt wordt afgesloten.
   */
  asynchroneActieNaBevestigingFn?: () => Promise<void>;
}

interface IBevestigenData {
  bezigMetAsynchroonBevestigen?: boolean;
}

export interface IBevestigenResult {
  type: EResultType;
}

export interface IMeldenParams {
  titel?: JSX.Element | string;
}

export enum EResultType {
  Ok,
  Annuleren,
}

export interface IControlerenResult {
  type: EResultType;
  heeftGebruikersinteractieGehad: boolean;
}

const defaultOptions: IOptions = {};

class CheckStore {
  public controlerenChecks: {
    [id: string]: { params: IControlerenParams; resolve: (result: IControlerenResult) => void };
  } = {};

  public bevestigenChecks: {
    [id: string]: {
      params: IBevestigenParams;
      data?: IBevestigenData;
      resolve: (result: IBevestigenResult) => void;
    };
  } = {};

  public meldenDatas: {
    [id: string]: { params: IMeldenParams; resolve: () => void };
  } = {};

  // tslint:disable-next-line:variable-name
  private _rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;

    makeObservable(this, {
      controlerenChecks: observable,
      bevestigenChecks: observable,
      meldenDatas: observable,
    });
  }

  public controleren = (params: IControlerenParams): Promise<IControlerenResult> => {
    return new Promise((resolve) => {
      const { warnings, errors } = params.checkData;
      // Als de check data fouten of errors bevat, dan moet er een visuele controle gedaan worden.
      if (warnings.length > 0 || errors.length > 0) {
        const id = makeUuid();

        this.controlerenChecks = {
          ...this.controlerenChecks,
          [id]: {
            params,
            resolve,
          },
        };

        return;
      }

      // Meteen Ok teruggeven als er geen warnings of errors zijn
      resolve({ type: EResultType.Ok, heeftGebruikersinteractieGehad: false });
    });
  };

  public afhandelenCheck = (id: string) => {
    const newChecks = { ...this.controlerenChecks };
    delete newChecks[id];
    this.controlerenChecks = newChecks;
  };

  public bevestigen = (params: IBevestigenParams): Promise<IBevestigenResult> => {
    return new Promise((resolve) => {
      const id = makeUuid();

      this.bevestigenChecks = {
        ...this.bevestigenChecks,
        [id]: {
          params,
          resolve,
        },
      };
    });
  };

  public melden = (params: IMeldenParams): Promise<void> => {
    return new Promise((resolve) => {
      const id = makeUuid();

      this.meldenDatas = {
        ...this.meldenDatas,
        [id]: {
          params,
          resolve,
        },
      };
    });
  };
}

export default CheckStore;
