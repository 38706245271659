import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IUploadVoortgangVanLokaleBestanden } from '../components/BestandDragAndDropZone';
import ILokaalBestand from '../models/ILokaalBestand';
import api from '../api';
import { Bestand, BestandType, IExternBestand } from '../components/BijlagenContainer';
import { IOphalenBestandenResultElement } from '../../../shared/src/api/v2/bestand/bestand';

interface IUploadOutput {
  outputs: IOphalenBestandenResultElement[];
}

interface IUseUploadParams {
  automatischUploaden: boolean;
  initieleBestanden: Bestand[] | null;
  onAlleBestandenGeuploaded: (bestandIDs: number[]) => void;
}

export interface IUseUploadOutput {
  uploadProgresses: IUploadVoortgangVanLokaleBestanden;
  upload: () => Promise<IOphalenBestandenResultElement[] | null>;
  isBezigMetUploaden: boolean;
  moetNogUploaden: boolean | null;
  bestanden: Bestand[] | null;
  muteerBestanden: (cb: (bestanden: Bestand[]) => Bestand[]) => Promise<Bestand[]>;
}

const useUpload = (params: IUseUploadParams): IUseUploadOutput => {
  // Alleen als de informatie werkelijk verandert, dan pas bijwerken
  const p = useMemo<IUseUploadParams>(() => params, [JSON.stringify(params)]);

  const uploadProgressesSyncRef = useRef<IUploadVoortgangVanLokaleBestanden>({});
  const [uploadProgresses, setUploadProgresses] = useState<IUploadVoortgangVanLokaleBestanden>({});
  const [bestanden, setBestanden] = useState<Bestand[] | null>(p.initieleBestanden);
  useEffect(() => {
    if (bestanden === null && p.initieleBestanden !== null) {
      setBestanden(p.initieleBestanden);
    }
  }, [p.initieleBestanden]);
  const onAlleBestandenGeuploadedBestandIDsRef = useRef<number[] | null>(null);
  const moetNogUploaden = useMemo(
    () => (bestanden === null ? null : bestanden.some((x) => x.type === BestandType.Lokaal)),
    [bestanden],
  );
  const isBezigMetUploaden = useMemo(() => Object.keys(uploadProgresses).length > 0, [
    uploadProgresses,
  ]);

  const uploadFn = useCallback(
    async (lokaleBestanden: ILokaalBestand[]): Promise<IUploadOutput> => {
      const uploadPromises = lokaleBestanden.map(
        async (bestand) =>
          await api.v2.bestand.upload(
            {
              naam: bestand.naam,
              mediaType: bestand.mediaType,
              url: bestand.url,
              file: bestand.file,
              grootte: bestand.grootte,
            },
            (percentage) => {
              const newProgress: IUploadVoortgangVanLokaleBestanden = {
                ...uploadProgressesSyncRef.current!,
                [bestand.url]: percentage,
              };
              if (percentage === 100) {
                delete newProgress[bestand.url];
              }
              uploadProgressesSyncRef.current = newProgress;
              setUploadProgresses(newProgress);
            },
          ),
      );

      const uploadResults = await Promise.all(uploadPromises);

      return {
        outputs: uploadResults,
      };
    },
    [setUploadProgresses, uploadProgressesSyncRef],
  );

  const upload = useCallback(async (): Promise<IOphalenBestandenResultElement[] | null> => {
    if (bestanden === null) {
      return null;
    }
    const uploadbareBestanden = bestanden.filter(
      (best) =>
        best.type === BestandType.Lokaal && uploadProgressesSyncRef.current[best.url] === undefined,
    ) as ILokaalBestand[];
    if (uploadbareBestanden.length === 0) {
      return bestanden.map((x) => (x as IExternBestand).bestand);
    }
    const result = await uploadFn(uploadbareBestanden);

    return await new Promise((resolve) => {
      setBestanden((bestanden) => {
        const newValue: Bestand[] = [
          ...(bestanden || []).filter(
            (x) =>
              x.type === BestandType.ASPDrive ||
              !uploadbareBestanden.some((lb) => lb.url === x.url),
          ),
          ...result.outputs.map((x) => ({
            type: BestandType.ASPDrive as BestandType.ASPDrive,
            bestand: x,
          })),
        ];
        resolve(newValue.map((x) => (x as IExternBestand).bestand));
        return newValue;
      });
    });
  }, [bestanden, uploadFn]);

  const muteerBestanden = useCallback<IUseUploadOutput['muteerBestanden']>(
    (cb) => {
      return new Promise((resolve) => {
        setBestanden((x) => {
          const value = cb(x || []);
          resolve(value);
          return value;
        });
      });
    },
    [setBestanden],
  );

  useEffect(() => {
    if (!p.automatischUploaden) {
      return;
    }
    (async () => await upload())();
  }, [bestanden, p.automatischUploaden]);

  useEffect(() => {
    if (bestanden === null) {
      return;
    }
    if (onAlleBestandenGeuploadedBestandIDsRef.current === null) {
      const aspDriveBestanden = bestanden.filter(
        (x) => x.type === BestandType.ASPDrive,
      ) as IExternBestand[];
      onAlleBestandenGeuploadedBestandIDsRef.current = aspDriveBestanden.map((x) => x.bestand.ID);
      return;
    }
    if (isBezigMetUploaden || moetNogUploaden) {
      return;
    }
    const bestandIDs = (bestanden as IExternBestand[]).map((x) => x.bestand.ID);
    onAlleBestandenGeuploadedBestandIDsRef.current = bestandIDs;
    p.onAlleBestandenGeuploaded(bestandIDs);
  }, [bestanden, isBezigMetUploaden, moetNogUploaden]);

  return {
    uploadProgresses,
    upload,
    bestanden,
    moetNogUploaden,
    isBezigMetUploaden,
    muteerBestanden,
  };
};

export default useUpload;
