import {
  DataTypeProvider,
  EditingState,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { RouteComponentProps, withRouter } from 'react-router';
import { IOphalenProductsoortenResultElement } from '../../../../../../shared/src/api/v2/product/soort';
import { IOphalenTekstenResultElement } from '../../../../../../shared/src/api/v2/tekst';
import api from '../../../../api';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { EBestandDragAndDropZoneSelectieModus } from '../../../../components/BestandDragAndDropZone';
import BestandenDragAndDropDialoog, {
  IBestandenDragAndDropDialoogResult,
} from '../../../../components/BestandenDragAndDropDialoog';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import {
  EFunctioneleIcon,
  functioneleIconMap,
  IconKruis,
  IconVerwijderen,
} from '../../../../components/Icons';
import MenuLayout from '../../../../components/MenuLayout';
import nameOf from '../../../../core/nameOf';
import useUrlState from '../../../../core/useUrlState';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import { GlobaleRendererContext } from '../../../../one-off-components/GlobaleRenderer';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import ToevoegenDialoog from './ToevoegenDialoog';
import WijzigenDialoog from './WijzigenDialoog';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';

interface IProps extends RouteComponentProps {}

enum EFilter {
  Actief = 'ACTIEF',
}

export interface IRegel extends IOphalenProductsoortenResultElement {}

interface IWijzigenDialoogState {
  id: number;
}

interface IToevoegenDialoogState {}

interface IUrlState {
  selectie: number[];
  wijzigDialoogState: IWijzigenDialoogState | null;
  toevoegenDialoogState: IToevoegenDialoogState | null;
  filterdata: IFilterData<EFilter>[];
}
const defaultUrlState: IUrlState = {
  selectie: [],
  wijzigDialoogState: null,
  toevoegenDialoogState: null,
  filterdata: [
    {
      naam: EFilter.Actief,
      data: true,
      isActief: true,
    },
  ],
};

const messages = {
  noData: 'Geen productsoorten',
};
const IconToevoegen = functioneleIconMap[EFunctioneleIcon.Toevoegen];

const Productsoorten: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterdata));

  const { checkStore } = useContext(RootStoreContext);
  const globaleRenderer = useContext(GlobaleRendererContext);

  const [productsoorten, setProductsoorten] = useState<IRegel[] | null>(null);
  const [teksten, setTeksten] = useState<IOphalenTekstenResultElement[] | null>(null);

  const ophalenProductsoorten = useCallback(async () => {
    const result = await api.v2.product.soort.ophalenProductsoorten({
      filterSchema,
      orderSchema: { orders: [{ naam: 'SORTNR', richting: 'ASC' }] },
    });

    setTeksten(null);
    setProductsoorten(result);
  }, [filterSchema]);

  useEffect(() => {
    ophalenProductsoorten();
  }, [ophalenProductsoorten]);

  const ophalenTeksten = useCallback(async () => {
    if (productsoorten === null) {
      return;
    }
    const result = await api.v2.tekst.ophalenTeksten({
      taalID: 1,
      tekstIDs: productsoorten.filter((x) => x.Slug_TekstID !== null).map((x) => x.Slug_TekstID!),
    });

    setTeksten(result);
  }, [productsoorten]);

  useEffect(() => {
    ophalenTeksten();
  }, [ophalenTeksten]);

  const handleVerwijderen = useCallback(async (id: number) => {
    const checkData = await api.v2.product.soort.checkVerwijderenProductsoorten({
      prodSrtIDs: [id],
    });
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: `Wil je deze productsoort verwijderen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.product.soort.verwijderenProductsoorten({
      prodSrtIDs: [id],
    });

    ophalenProductsoorten();
  }, []);

  const keyExtractor = useCallback((row: IRegel) => row.ProdSrtID, []);

  const kolommen = useMemo<TypedColumn<IRegel>[]>(
    () => [
      { name: 'Naam', title: 'Naam' },
      { name: 'NaamKort', title: 'Naam kort' },
      { name: '__slug', title: 'Slug (NL)' },
      { name: 'AantalGebruikersTarief', title: 'Atl. gbr. tarief' },
      { name: 'AantalGebruikersMax', title: 'Atl. gbr. max' },
      { name: 'LeeftijdGrens', title: 'Lft.grens' },
      { name: 'StandaardVolume', title: 'M3' },
      { name: 'SortNr', title: 'Sort.nr' },
      { name: 'Actief', title: 'Actief' },
      { name: '__actieInlezenAfbeelding' as any, title: 'Afbeelding' },
      {
        name: 'RecordGewijzigd',
        title: 'Gewijzigd',
      },
    ],
    [],
  );

  const kolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(
    () => [
      {
        columnName: 'Naam',
        width: 200,
      },
      {
        columnName: 'NaamKort',
        width: 125,
      },
      {
        columnName: '__slug' as any,
        width: 185,
      },
      {
        columnName: 'AantalGebruikersTarief',
        width: 125,
      },
      {
        columnName: 'AantalGebruikersMax',
        width: 125,
      },
      {
        columnName: 'LeeftijdGrens',
        width: 125,
      },
      {
        columnName: 'StandaardVolume',
        width: 80,
      },
      {
        columnName: 'SortNr',
        width: 90,
      },
      {
        columnName: 'Actief',
        width: 90,
      },
      {
        columnName: '__actieInlezenAfbeelding' as any,
        width: 125,
      },
      {
        columnName: 'RecordGewijzigd',
        width: 200,
      },
    ],
    [],
  );

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.Actief,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Alleen actieve soorten</span>;
        },
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Productsoorten</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="mt-2 d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
                onClick={() => setUrlStateSync('toevoegenDialoogState', {})}
              >
                <span>
                  <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
                </span>
                <span className="ml-2">Nieuwe productsoort</span>
              </button>

              <div className="ml-2 flex-fill">
                <FilterBalkV2
                  filters={filters}
                  filterData={urlState.filterdata}
                  onFilterDataChange={(x) => setUrlStateSync('filterdata', x)}
                  onFilterSchemaChange={(x) => setFilterSchema(x)}
                />
              </div>
            </div>
          </>
        }
        body={
          productsoorten === null || teksten === null ? (
            <LoadingSpinner />
          ) : (
            <GridStyleWrapper height="calc(100vh - 150px)">
              <Grid getRowId={keyExtractor} rows={productsoorten} columns={kolommen}>
                <DataTypeProvider
                  for={['__slug']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;

                    if (rij.Slug_TekstID === null) {
                      return <span></span>;
                    }

                    const slug = teksten.find((x) => x.TekstID === rij.Slug_TekstID)!.Tekst;
                    return <span>{slug}</span>;
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IRegel>('Actief')]}
                  formatterComponent={(formatterProps) => {
                    return <span>{formatterProps.value ? 'Ja' : 'Nee'}</span>;
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IRegel>('StandaardVolume')]}
                  formatterComponent={(formatterProps) => {
                    return (
                      <span>{formatterProps.value !== null ? formatterProps.value : '-'}</span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={[nameOf<IRegel>('RecordToegevoegd'), nameOf<IRegel>('RecordGewijzigd')]}
                  formatterComponent={(formatterProps) => {
                    if (formatterProps.value === null) {
                      return <span></span>;
                    }
                    return (
                      <span>{format(new Date(formatterProps.value), 'dd-MM-yyyy HH:mm')}</span>
                    );
                  }}
                />

                <DataTypeProvider
                  for={['__actieInlezenAfbeelding']}
                  formatterComponent={(formatterProps) => {
                    const rij: IRegel = formatterProps.row;

                    if (rij.Afbeelding_BestandID !== null) {
                      return (
                        <span className="d-flex align-items-center">
                          <a
                            href="#"
                            onClick={async () => {
                              if (
                                (
                                  await checkStore.bevestigen({
                                    inhoud: `Wil je de afbeelding voor deze productsoort verwijderen?`,
                                  })
                                ).type === EResultType.Annuleren
                              ) {
                                return;
                              }

                              await api.v2.product.soort.verwijderenAfbeelding({
                                prodSrtID: rij.ProdSrtID,
                              });
                              await ophalenProductsoorten();
                              toast.success('Verwijderen afbeelding gelukt');
                            }}
                          >
                            {/* <IconKruis style={{ width: 18, height: 18, fill: Kleur.Rood }} /> */}
                            <span>Verwijderen</span>
                          </a>
                        </span>
                      );
                    }

                    return (
                      <span className="d-flex align-items-center">
                        <a
                          href="#"
                          onClick={async () => {
                            const bestandenResult = await globaleRenderer.render<
                              IBestandenDragAndDropDialoogResult
                            >((renderProps) => (
                              <BestandenDragAndDropDialoog
                                open
                                onSuccess={(result) => renderProps.destroy(result)}
                                onAnnuleren={() => renderProps.destroy()}
                                toegestaneBestandstypen={[
                                  {
                                    weergaveNaam: 'JPG',
                                    mediaType: 'image/jpeg',
                                  },
                                  {
                                    weergaveNaam: 'GIF',
                                    mediaType: 'image/gif',
                                  },
                                  {
                                    weergaveNaam: 'PNG',
                                    mediaType: 'image/png',
                                  },
                                  {
                                    weergaveNaam: 'SVG',
                                    mediaType: 'image/svg+xml',
                                  },
                                ]}
                                selectieModus={EBestandDragAndDropZoneSelectieModus.Enkel}
                              />
                            ));

                            if (bestandenResult === undefined) {
                              return;
                            }
                            const bestand = bestandenResult.bestanden[0];

                            await api.v2.product.soort.toevoegenAfbeelding({
                              prodSrtID: rij.ProdSrtID,
                              bestandID: bestand.ID,
                            });
                            await ophalenProductsoorten();
                            toast.success('Toevoegen afbeelding gelukt');
                          }}
                        >
                          Toevoegen
                        </a>
                      </span>
                    );
                  }}
                />

                <EditingState
                  onAddedRowsChange={() => {}}
                  onEditingRowIdsChange={(x) => {
                    const id = x[x.length - 1] as number;
                    setUrlStateSync('wijzigDialoogState', { id });
                  }}
                  onCommitChanges={async (changes) => {
                    if (changes.deleted === undefined) {
                      return;
                    }
                    const deleted = changes.deleted;
                    const id = deleted[deleted.length - 1] as number;
                    await handleVerwijderen(id);
                  }}
                />

                <RowDetailState defaultExpandedRowIds={[]} />
                {/* <VirtualTable estimatedRowHeight={43} /> */}

                <SortingState defaultSorting={[]} />
                <IntegratedSorting />
                <VirtualTable messages={messages} />

                <TableColumnResizing defaultColumnWidths={kolomBreedtes} />
                <TableHeaderRow showSortingControls />
                <TableEditColumn
                  width={65}
                  // showAddCommand={true}
                  showEditCommand
                  showDeleteCommand
                  commandComponent={DXCommandComponent}
                />
                {/* <TableRowDetail
                  contentComponent={DetailComp}
                  toggleCellComponent={DXTableToggleCellComponent}
                /> */}
                {/* <SelectionState
                  selection={urlState.selectie}
                  onSelectionChange={(x) => setUrlStateSync('selectie', x as number[])}
                />
                <TableSelection cellComponent={DXTableCheckboxComponent} /> */}
              </Grid>
            </GridStyleWrapper>
          )
        }
      />
      {urlState.wijzigDialoogState !== null && (
        <WijzigenDialoog
          open
          id={urlState.wijzigDialoogState.id}
          onSuccess={() => {
            ophalenProductsoorten();
            setUrlStateSync('wijzigDialoogState', null);
          }}
          onAnnuleren={() => [setUrlStateSync('wijzigDialoogState', null)]}
        />
      )}
      {urlState.toevoegenDialoogState !== null && (
        <ToevoegenDialoog
          open
          onSuccess={() => {
            ophalenProductsoorten();
            setUrlStateSync('toevoegenDialoogState', null);
          }}
          onAnnuleren={() => [setUrlStateSync('toevoegenDialoogState', null)]}
        />
      )}
    </>
  );
};

export default withRouter(Productsoorten);
