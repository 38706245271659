import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import * as Yup from 'yup';
import { IOphalenProductmodellenResultElement } from '../../../../../../../../shared/src/api/v2/aanbod/productmodel';
import { IConcurrentProductKoppeling } from '../../../../../../../../shared/src/api/v2/concurrent';
import api from '../../../../../../api';
import LoadingSpinner from '../../../../../../components/Gedeeld/LoadingSpinner';
import Dialoog from '../../../../../../components/dialogen/Dialoog';
import ProductmodelSelectieCombobox from '../../../../../../components/formulier/ProductmodelSelectieCombobox';
import IDialoogProps from '../../../../../../core/IDialoogProps';
import nameOf from '../../../../../../core/nameOf';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  koppelBasisID: number;
}

interface IFormikValues {
  prodModID: number | null;
  notities: string;
}

const KoppelenDialoog: React.FC<IProps> = observer((props) => {
  const { dialoogIndex, onAnnuleren } = props;

  const [koppelbareData, setKoppelbareData] = useState<
    IOphalenProductmodellenResultElement[] | null
  >(null);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (values.prodModID === null) return;

      let params: IConcurrentProductKoppeling = {
        ConcurrentProductID: props.koppelBasisID,
        ProdModID: values.prodModID,
        Notities: values.notities,
      };

      console.log(values);

      await api.v2.concurrentie.koppelenProductModel(params);

      actions.setSubmitting(false);
      props.onSuccess({});
    },
    [],
  );

  const ophalenKoppelbareData = async () => {
    const results = await api.v2.product.model.ophalenProductmodellen({
      filterSchema: {
        filters: [
          {
            naam: 'ACTIEF',
            data: true,
          },
        ],
      },
    });
    setKoppelbareData(results.modellen);
  };

  useEffect(() => {
    ophalenKoppelbareData();
  }, []);

  const initialValues = useMemo<IFormikValues | null>(() => {
    return {
      prodModID: null,
      notities: '',
    };
  }, []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        prodModID: Yup.number().required(),
      }),
    [],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Koppelen Productmodel</ModalTitle>
      </ModalHeader>
      {initialValues === null ? (
        <LoadingSpinner />
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label>Productmodel</label>
                        <Field
                          name={nameOf<IFormikValues>('prodModID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return !koppelbareData ? (
                              <LoadingSpinner></LoadingSpinner>
                            ) : (
                              <>
                                <ProductmodelSelectieCombobox
                                  prodModID={fieldProps.field.value}
                                  productmodellen={koppelbareData}
                                  onChange={(x) => {
                                    form.setFieldValue(field.name, x);
                                  }}
                                />
                              </>
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label>Notities</label>
                        <Field
                          name={nameOf<IFormikValues>('notities')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field } = fieldProps;
                            return (
                              <>
                                <input type="text" className="form-control" {...field} />
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default KoppelenDialoog;
