import React, { useContext, useMemo, useRef, useState } from 'react';
import { Root } from './style';
import {
  getIconFile,
  IconDownload,
  IconOpenInNew,
  IconUitvergroten,
  IconVerwijderen,
} from '../../Icons';
import Spinner from 'react-bootstrap/Spinner';
import { BestandType, EWeergaveFocus, IBijlage, IBijlageVisualisatieInfo } from '../index';
import { Kleur } from '../../../bedrijfslogica/constanten';
import Overlay from 'react-bootstrap/Overlay';
import downloadUrl from '../../../core/downloadUrl';
import LoadingSpinner from '../../Gedeeld/LoadingSpinner';
import { formatteerBestandsgrootte } from '../../../helpers';
import AfbeeldingBijlageItemVisualisatie from './visualisaties/AfbeeldingBijlageItemVisualisatie';
import PDFBijlageItemVisualisatie from './visualisaties/PDFBijlageItemVisualisatie';
import UitlegTooltip from '../../formulier/UitlegTooltip';
import { EResultType } from '../../../stores/CheckStore';
import { RootStoreContext } from '../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { IOphalenBestandenResultElement } from '../../../../../shared/src/api/v2/bestand/bestand';

export interface IBijlageItemProps {
  bestandinfo: IOphalenBestandenResultElement | null;
  bestand: IBijlage;
  onRemoveClick?: () => void;
  onEnlargeClick: () => void;
  weergaveFocus: EWeergaveFocus;
}

const BijlageItem: React.FC<IBijlageItemProps> = observer((props) => {
  const { bestand, bestandinfo } = props;
  const { checkStore } = useContext(RootStoreContext);
  const [hovered, setHovered] = useState(false);
  const rootRef = useRef<HTMLDivElement>(null);

  const info = useMemo<IBijlageVisualisatieInfo | null>(() => {
    if (bestand.bestand.type === BestandType.Lokaal) {
      return bestand.bestand;
    } else {
      return bestandinfo === null
        ? null
        : {
            naam: bestandinfo.Naam,
            mediaType: bestandinfo.MediaType,
            url: bestandinfo.url,
            grootte: bestandinfo.Grootte,
          };
    }
  }, [bestand, bestandinfo]);

  const IconFile: React.ComponentType<any> | null =
    info === null ? null : getIconFile(info.mediaType);

  const [isDownloading, setIsDownloading] = useState(false);
  const isImage = useMemo(() => info !== null && info.mediaType.startsWith('image/'), [info]);
  const isPDF = useMemo(() => info !== null && info.mediaType === 'application/pdf', [info]);
  const canEnlarge = useMemo(() => isImage || isPDF, [isImage, isPDF]);

  const backgroundImageSrc = useMemo<string | null>(() => {
    if (!isImage || info === null) {
      return null;
    }
    return info.url;
  }, [isImage, info]);

  const acties = useMemo(() => {
    return (
      <div className="d-flex align-items-center" style={{ height: 21 }}>
        {hovered && canEnlarge ? (
          <UitlegTooltip inhoud="Uitvergroten middels mediaweergave">
            <button
              style={{ background: 0, border: 0, outline: 0, padding: 0 }}
              onClick={() => {
                props.onEnlargeClick();
              }}
            >
              <IconUitvergroten style={{ width: 15, height: 15, fill: Kleur.Grijs }} />
            </button>
          </UitlegTooltip>
        ) : (
          <div style={{ width: 15 }} />
        )}
        {hovered && (
          <UitlegTooltip inhoud="Openen in nieuw venster">
            <button
              style={{
                background: 0,
                border: 0,
                outline: 0,
                padding: 0,
                marginLeft: 3,
              }}
              onClick={() => {
                // props.onEnlargeClick();
                window.open(`${info!.url}?inline=true`, '_blank');
              }}
            >
              <IconOpenInNew
                style={{
                  fill: Kleur.Grijs,
                  width: 15,
                  height: 15,
                }}
              />
            </button>
          </UitlegTooltip>
        )}
        {isDownloading ? (
          <div style={{ marginLeft: 5, position: 'relative', top: -1 }}>
            <LoadingSpinner grootte="12px" dikte="2px" />
          </div>
        ) : hovered ? (
          <UitlegTooltip inhoud="Downloaden">
            <button
              style={{
                background: 0,
                border: 0,
                outline: 0,
                padding: 0,
                marginLeft: 5,
              }}
              onClick={async () => {
                if (isDownloading) {
                  return;
                }
                setIsDownloading(true);
                await downloadUrl(info!.url, info!.naam);
                setIsDownloading(false);
              }}
            >
              <IconDownload style={{ fill: Kleur.Grijs, width: 12, height: 12 }} />
            </button>
          </UitlegTooltip>
        ) : null}
        {hovered && props.onRemoveClick !== undefined ? (
          <UitlegTooltip inhoud="Verwijderen">
            <button
              style={{
                background: 0,
                border: 0,
                outline: 0,
                padding: 0,
                marginLeft: 3,
              }}
              onClick={async () => {
                // TODO Dennis Ik heb deze bevestiging eruit gehaald omdat er anders steeds 2 x om gevraagd werd, is dit Ok?
                // if (
                //   (
                //     await checkStore.bevestigen({
                //       titel: `Bevestig verwijderen '${info!.naam}'`,
                //     })
                //   ).type === EResultType.Annuleren
                // ) {
                //   return;
                // }
                props.onRemoveClick!();
              }}
            >
              <IconVerwijderen
                style={{
                  fill: Kleur.Grijs,
                  width: 15,
                  height: 15,
                }}
              />
            </button>
          </UitlegTooltip>
        ) : (
          <div style={{ width: 15 }} />
        )}
      </div>
    );
  }, [
    props.onRemoveClick,
    info,
    hovered,
    isDownloading,
    setIsDownloading,
    canEnlarge,
    props.onEnlargeClick,
  ]);

  return (
    <>
      {isImage && props.weergaveFocus === EWeergaveFocus.Visualisatie ? (
        <AfbeeldingBijlageItemVisualisatie
          ref={rootRef}
          hovered={hovered}
          onHoveredChange={setHovered}
          imageSrc={backgroundImageSrc!}
          info={info}
          acties={acties}
        />
      ) : isPDF && props.weergaveFocus === EWeergaveFocus.Visualisatie ? (
        <PDFBijlageItemVisualisatie
          ref={rootRef}
          hovered={hovered}
          onHoveredChange={setHovered}
          info={info}
          acties={acties}
        />
      ) : (
        <Root
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          ref={rootRef}
          backgroundImageSrc={backgroundImageSrc || undefined}
        >
          <div className="d-flex">
            {info === null ? (
              <Spinner animation="border" />
            ) : (
              <div className="d-flex align-items-center" style={{}}>
                {IconFile && <IconFile style={{ width: 25, height: 25 }} />}
                <div className="d-flex flex-column ml-3" style={{ marginTop: 3, marginBottom: 3 }}>
                  <span
                    style={{
                      textOverflow: 'ellipsis',
                      width: 155,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textAlign: 'left',
                    }}
                    title={info.naam}
                  >
                    {info.naam}
                  </span>
                  <div className="d-flex" style={{}}>
                    <span className="text-muted" style={{ fontSize: 11 }}>
                      {info.grootte === null
                        ? 'Bestandsgrootte onbekend'
                        : formatteerBestandsgrootte(info.grootte)}
                    </span>
                    <div className="flex-fill" />
                    {acties}
                  </div>
                </div>
              </div>
            )}
          </div>
          {props.bestand.uploadProgress && (
            <div
              style={{
                height: 1,
                width: `${props.bestand.uploadProgress}%`,
                backgroundColor: Kleur.Blauw,
                marginTop: 5,
                marginBottom: 3,
              }}
            />
          )}
        </Root>
      )}
      {/*<Overlay target={rootRef.current!} show={hovered} placement="bottom-start">*/}
      {/*  {(overlayProps: any) => (*/}
      {/*    <div*/}
      {/*      {...overlayProps}*/}
      {/*      style={{*/}
      {/*        backgroundColor: Kleur.Wit,*/}
      {/*        ...overlayProps.style,*/}
      {/*        zIndex: 9999,*/}
      {/*      }}*/}
      {/*      onMouseEnter={() => setHovered(true)}*/}
      {/*      onMouseLeave={() => setHovered(false)}*/}
      {/*    >*/}
      {/*      <ul className="list-group">*/}
      {/*        {info !== null && (*/}
      {/*          <li*/}
      {/*            className="list-group-item d-flex align-items-center"*/}
      {/*            style={{ padding: '5px 10px', cursor: isDownloading ? 'default' : 'pointer' }}*/}
      {/*            onClick={async () => {*/}
      {/*              if (isDownloading) {*/}
      {/*                return;*/}
      {/*              }*/}
      {/*              setIsDownloading(true);*/}
      {/*              await downloadUrl(info!.url, info!.naam);*/}
      {/*              setIsDownloading(false);*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            <IconDownload style={{ fill: Kleur.Grijs, width: 13, height: 13 }} />*/}
      {/*            <span className="ml-2" style={{ fontSize: 12 }}>*/}
      {/*              Download*/}
      {/*            </span>*/}
      {/*            {isDownloading && (*/}
      {/*              <span className="ml-2">*/}
      {/*                <LoadingSpinner grootte="15px" dikte="2px" />*/}
      {/*              </span>*/}
      {/*            )}*/}
      {/*          </li>*/}
      {/*        )}*/}

      {/*        {props.onRemoveClick !== undefined && (*/}
      {/*          <li*/}
      {/*            className="list-group-item d-flex align-items-center"*/}
      {/*            style={{ padding: '5px 10px', cursor: 'pointer' }}*/}
      {/*            onClick={() => props.onRemoveClick!()}*/}
      {/*          >*/}
      {/*            <IconVerwijderen style={{ fill: Kleur.Grijs, width: 13, height: 13 }} />*/}
      {/*            <span className="ml-2" style={{ fontSize: 12 }}>*/}
      {/*              Verwijderen*/}
      {/*            </span>*/}
      {/*          </li>*/}
      {/*        )}*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*</Overlay>*/}
    </>
  );
});

export default BijlageItem;
