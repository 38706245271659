import {
  Column,
  DataTypeProvider,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  SortingState,
  TableColumnWidthInfo,
  EditingState,
  VirtualTable as VirtualTableBase,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
  TableRowDetail,
} from '@devexpress/dx-react-grid-bootstrap4';
import { format } from 'date-fns';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { IOphalenDienstenResultElement } from '../../../../../shared/src/api/v2/dienst/service';
import { IOphalenOpdrachtenResultElement } from '../../../../../shared/src/api/v2/service/opdracht';
import { IOphalenWerkbonnenResultElement } from '../../../../../shared/src/api/v2/service/werkbon';
import api from '../../../api';
import LoadingSpinner from '../../../components/Gedeeld/LoadingSpinner';
import MenuLayout from '../../../components/MenuLayout';
import FormatteerBedrag from '../../../components/MutatieBedrag';
import nameOf from '../../../core/nameOf';
import useUrlState from '../../../core/useUrlState';
import {
  DXCommandComponent,
  DXTableCheckboxComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../helpers/dxTableGrid';
import WerkbonMutatieDialoog from '../../../components/service/WerkbonMutatieDialoog';
import RelatieVisualisatie from '../../../components/personalia/RelatieVisualisatie';
import { IOphalenMeldingenResultElement } from '../../../../../shared/src/api/v2/service/melding';
import VinkVeld from '../../../components/formulier/VinkVeld';
import { IFilterSchema, IFilterSchemaFilter } from '../../../../../shared/src/models/filter';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../components/FilterBalkV2';
import ZoektermFilter from './ZoektermFilter';
import BijlageKnop from '../../../components/BijlageKnop';
import Bijlage from './Bijlage';
import ContractVisualisatie from '../../../components/entiteitVisualisaties/ContractVisualisatie';
import { Kleur as EKleur } from '../../../bedrijfslogica/constanten';
import { IconInformatie, IconVerwijderen } from '../../../components/Icons';
import WerkbonInfoDialoog from '../../../components/service/WerkbonInfoDialoog';
import { RootStoreContext } from '../../../stores/RootStore';
import { EResultType } from '../../../stores/CheckStore';
import { Helmet } from 'react-helmet';
import ServiceopdrachtVisualisatie from '../../../components/entiteitVisualisaties/ServiceopdrachtVisualisatie';

enum EFilter {
  KostenDoorberekenen = 'KOSTENDOORBEREKENEN',
  Zoekterm = 'ZOEKTERM',
  IsBeoordeeld = 'IS_BEOORDEELD',
}

interface IWerbonInfoDialoogState {
  id: number;
}

interface IProps extends RouteComponentProps {}

export interface IUrlState {
  filterdata: IFilterData<EFilter>[];
  selectie: number[];
  werbonInfoDialoogState: IWerbonInfoDialoogState | null;
}

const defaultUrlState: IUrlState = {
  filterdata: [
    {
      naam: EFilter.KostenDoorberekenen,
      data: false,
      isActief: true,
    },
    {
      naam: EFilter.IsBeoordeeld,
      data: true,
      isActief: false,
    },
    {
      naam: EFilter.Zoekterm,
      data: '',
      isActief: false,
    },
  ],
  selectie: [],
  werbonInfoDialoogState: null,
};

interface IState {
  werkbonnen: IOphalenWerkbonnenResultElement[];
  opdrachten: IOphalenOpdrachtenResultElement[];
  diensten: IOphalenDienstenResultElement[];
  meldingen: IOphalenMeldingenResultElement[];
}

export interface IRegel extends IOphalenWerkbonnenResultElement {}

export interface IMutatieWerkbonDialoogState {
  werkbonID: number;
}

const Werkbonnen: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [state, setState] = useState<IState | null>(null);

  const [
    mutatieWerkbonDialoogState,
    setMutatieWerkbonDialoogState,
  ] = useState<IMutatieWerkbonDialoogState | null>(null);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.KostenDoorberekenen,
        altijdWeergevenInBalk: true,
        weergave: () => <span>Kosten doorberekenen</span>,
      },
      {
        naam: EFilter.IsBeoordeeld,
        altijdWeergevenInBalk: true,
        weergave: () => <span>Is niet beoordeeld</span>,
      },
      {
        naam: EFilter.Zoekterm,
        altijdWeergevenInBalk: true,
        weergave: ZoektermFilter,
      },
    ],
    [],
  );
  const [filterSchema, setFilterSchema] = useState(maakFilterSchema(urlState.filterdata));

  const ophalenWerkbonnen = useCallback(async () => {
    if (filterSchema.filters === undefined) {
      return;
    }
    const nieuwFilterSchema: IFilterSchema = {
      filters: filterSchema.filters.map((filter) => {
        if (filter.naam === 'IS_BEOORDEELD') {
          return {
            ...filter,
            inverteren: true,
          };
        }
        return filter;
      }),
    };

    const werkbonnen = (
      await api.v2.service.ophalenWerkbonnen({
        filterSchema: nieuwFilterSchema,
        paginatie: { index: 0, aantal: 500 },
        orderSchema: { orders: [{ naam: 'BEZOEKDATUM', richting: 'DESC' }] },
      })
    ).werkbonnen;

    const servDienstIDs = _.uniq(
      werkbonnen.filter((x) => x.ServDienstID !== null).map((x) => x.ServDienstID),
    );

    const opdrachten = (
      await api.v2.service.ophalenOpdrachten({
        filterSchema: {
          filters: [{ naam: 'IDS', data: werkbonnen.map((x) => x.ServOpdID) }],
        },
      })
    ).opdrachten;

    const meldingen = (
      await api.v2.service.ophalenMeldingen({
        filterSchema: {
          filters: [{ naam: 'IDS', data: opdrachten.map((x) => x.melding.ID) }],
        },
      })
    ).meldingen;

    const diensten = (
      await api.v2.dienst.service.ophalenDiensten({
        filterSchema: {
          filters: [
            {
              naam: 'IDS',
              data: servDienstIDs,
            },
          ],
        },
      })
    ).diensten;

    // const werkbonnenGesorteerd = _.orderBy(werkbonnenResult, ['Bezoekdatum'], ['asc']);

    setState({
      werkbonnen,
      meldingen,
      opdrachten,
      diensten,
    });
  }, [filterSchema]);

  useEffect(() => {
    ophalenWerkbonnen();
  }, [ophalenWerkbonnen]);

  const handleVerwijderen = useCallback(async () => {
    const checkData = await api.v2.service.checkVerwijderenWerkbonnen({
      ids: urlState.selectie,
    });
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }
    if (
      (
        await checkStore.bevestigen({
          inhoud: `Wil je de ${urlState.selectie.length} werkbonnen verwijderen?`,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.service.verwijderenWerkbonnen({
      ids: urlState.selectie,
    });

    setUrlStateSync('selectie', []);

    ophalenWerkbonnen();
  }, [urlState.selectie]);

  const keyExtractor = useCallback((rij: IRegel) => rij.ID, []);
  const kolommen = useMemo<TypedColumn<IRegel>[]>(() => {
    return [
      {
        name: 'Bezoekdatum',
        title: 'Bezoekdatum',
        getCellValue: (x) => {
          return x.Bezoekdatum !== null ? new Date(x.Bezoekdatum) : new Date('2099-12-31');
        },
      },
      {
        name: '__opdracht' as any,
        title: 'Opdracht',
      },
      {
        name: '__info' as any,
        title: ' ',
      },
      // {
      //   name: '__locatie',
      //   title: 'Locatie',
      // },
      { name: '__relatie' as any, title: 'Relatie' },
      { name: '__contract' as any, title: 'Contract' },
      // { name: 'KostenTotaal', title: 'Kosten (excl. btw)' },
      {
        name: 'Werkzaamheden',
        title: 'Werkzaamheden',
      },
      { name: '__dienst' as any, title: 'Uitv. dienst' },
      {
        name: 'IsBeoordeeld',
        title: 'Beoord.',
      },
      {
        name: 'KostenDoorberekenen',
        title: 'Doorber.',
      },
      {
        name: '__bijlage',
        title: 'Bijlage',
      },
      // {
      //   name: 'KostenDoorberekend',
      //   title: 'Vord.',
      // },
      {
        name: 'RecordToegevoegd',
        title: 'Geregistreerd',
      },
    ];
  }, []);

  const kolombreedtes = useMemo<TypedTableColumnWidthInfo<IRegel>[]>(() => {
    return [
      {
        columnName: 'Bezoekdatum',
        width: 125,
      },
      {
        columnName: '__opdracht',
        width: 100,
      },
      {
        columnName: '__info' as any,
        width: 65,
      },
      {
        columnName: '__locatie' as any,
        width: 200,
      },
      {
        columnName: 'Werkzaamheden',
        width: 350,
      },
      {
        columnName: '__bijlage' as any,
        width: 150,
      },
      {
        columnName: 'KostenTotaal',
        width: 150,
      },
      {
        columnName: '__dienst' as any,
        width: 135,
      },
      {
        columnName: 'KostenDoorberekenen',
        width: 100,
      },
      {
        columnName: 'KostenDoorberekend',
        width: 90,
      },
      {
        columnName: 'IsBeoordeeld',
        width: 100,
      },
      {
        columnName: '__relatie' as any,
        width: 250,
      },
      {
        columnName: '__contract' as any,
        width: 100,
      },
      {
        columnName: 'RecordToegevoegd',
        width: 150,
      },
    ];
  }, []);

  const kolomExtensies: VirtualTableBase.ColumnExtension[] = useMemo(() => {
    return [
      {
        columnName: `__bijlage`,
        align: 'right',
      },
      // {
      //   columnName: `KostenTotaal`,
      //   align: 'right',
      // },
    ];
  }, []);

  return state === null ? (
    <div className="flex-fill d-flex align-items-center justify-content-center">
      <LoadingSpinner />
    </div>
  ) : (
    <>
      <Helmet>
        <title>Werkbonnen</title>
      </Helmet>
      <MenuLayout
        menu={
          <div>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                onClick={() => handleVerwijderen()}
              >
                <span>
                  <IconVerwijderen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                </span>
                <span className="ml-2">Verwijderen</span>
              </button>

              <div className="d-flex flex-fill ml-3">
                <FilterBalkV2
                  filters={filters}
                  filterData={urlState.filterdata}
                  onFilterDataChange={(x) => setUrlStateSync('filterdata', x)}
                  onFilterSchemaChange={setFilterSchema}
                />
              </div>
            </div>
          </div>
        }
        body={
          <GridStyleWrapper height="calc(100vh - 150px)">
            <Grid rows={state.werkbonnen} columns={kolommen} getRowId={keyExtractor}>
              <DataTypeProvider
                for={['__locatie']}
                formatterComponent={(formatterProps) => {
                  const x: IRegel = formatterProps.row;
                  const opdracht = state.opdrachten.find((o) => o.ServOpdID === x.ServOpdID)!;
                  const melding = state.meldingen.find((m) => m.ID === opdracht.melding.ID)!;

                  const locatie =
                    melding.locatie.Straatnaam +
                    ' ' +
                    melding.locatie.Huisnummer +
                    (melding.locatie.Bisnummer !== null ? ' ' + melding.locatie.Bisnummer : '');

                  return <span>{locatie}</span>;
                }}
              />

              <DataTypeProvider
                for={['__opdracht']}
                formatterComponent={(formatterProps) => {
                  const rij: IRegel = formatterProps.row;
                  return <ServiceopdrachtVisualisatie servOpdID={rij.ServOpdID!} />;
                }}
              />
              <DataTypeProvider
                for={[nameOf<IRegel>('Bezoekdatum')]}
                formatterComponent={(x) => {
                  if (x.value === null) {
                    return <span></span>;
                  }
                  return <span>{format(new Date(x.value), 'dd-MM-yyyy')}</span>;
                }}
              />
              <DataTypeProvider
                for={['__info']}
                formatterComponent={(formatterProps) => {
                  const rij: IRegel = formatterProps.row;
                  return (
                    <div>
                      <a
                        href="#"
                        className="ml-1"
                        style={{ color: EKleur.LichtBlauw }}
                        onClick={() => {
                          setUrlStateSync('werbonInfoDialoogState', { id: rij.ID });
                        }}
                      >
                        <IconInformatie style={{ width: 15, height: 15, fill: EKleur.Blauw }} />
                      </a>
                    </div>
                  );
                }}
              />
              <DataTypeProvider
                for={[nameOf<IRegel>('RecordToegevoegd')]}
                formatterComponent={(x) => {
                  if (x.value === null) {
                    return <span></span>;
                  }
                  return <span>{format(new Date(x.value), 'dd-MM-yyyy HH:mm')}</span>;
                }}
              />
              <DataTypeProvider
                for={['__bijlage']}
                formatterComponent={(formatterProps) => {
                  const x: IRegel = formatterProps.row;
                  return (
                    <Bijlage
                      bestand={x.info}
                      onBestIDChange={async (bestID) => {
                        await api.v2.service.wijzigenWerkbon({
                          werkbonID: x.ID,
                          bronBestandID: x.bron?.ID ?? null,
                          bezoekdatum: x.Bezoekdatum,
                          kostenDoorberekend: x.KostenDoorberekend,
                          kostenDoorberekenen: x.KostenDoorberekenen,
                          kostenTotaal: x.KostenTotaal,
                          servDienstID: x.ServDienstID,
                          werkzaamheden: x.Werkzaamheden,
                          infoBestandID: bestID,
                          isBeoordeeld: x.IsBeoordeeld,
                        });
                        await ophalenWerkbonnen();
                      }}
                    />
                  );
                }}
              />
              <DataTypeProvider
                for={[nameOf<IRegel>('KostenTotaal')]}
                formatterComponent={(x) => {
                  const bedrag = x.value;
                  return <FormatteerBedrag bedrag={bedrag} />;
                }}
              />
              <DataTypeProvider
                for={['__dienst']}
                formatterComponent={(x) => {
                  const rij: IRegel = x.row;

                  if (rij.ServDienstID === null) {
                    return <span></span>;
                  }

                  const dienst = state.diensten.find((d) => d.ID === rij.ServDienstID)!;
                  return <RelatieVisualisatie relID={dienst.RelID} />;
                }}
              />
              <DataTypeProvider
                for={[nameOf<IRegel>('KostenDoorberekenen')]}
                formatterComponent={(x) => {
                  return <span>{x.value ? 'Ja' : 'Nee'}</span>;
                }}
              />
              <DataTypeProvider
                for={[nameOf<IRegel>('KostenDoorberekend'), nameOf<IRegel>('IsBeoordeeld')]}
                formatterComponent={(x) => {
                  return <span>{x.value ? 'Ja' : 'Nee'}</span>;
                }}
              />
              <DataTypeProvider
                for={['__relatie']}
                formatterComponent={(formatterProps) => {
                  const rij: IRegel = formatterProps.row;

                  const opdracht = state.opdrachten.find((x) => x.ServOpdID === rij.ServOpdID)!;
                  const relID = opdracht.melding.RelID;

                  if (relID === null) {
                    return <span></span>;
                  }
                  return <RelatieVisualisatie relID={relID!} />;
                }}
              />
              <DataTypeProvider
                for={['__contract']}
                formatterComponent={(formatterProps) => {
                  const rij: IRegel = formatterProps.row;
                  const opdracht = state.opdrachten.find((x) => x.ServOpdID === rij.ServOpdID)!;

                  if (opdracht === null || opdracht.melding.contract === null) {
                    return <span></span>;
                  }
                  return <ContractVisualisatie cntID={opdracht.melding.contract.CntID} />;
                }}
              />
              <SortingState defaultSorting={[]} />
              <IntegratedSorting />
              <VirtualTable columnExtensions={kolomExtensies} />
              <TableColumnResizing defaultColumnWidths={kolombreedtes as TableColumnWidthInfo[]} />
              <TableHeaderRow showSortingControls />
              <EditingState
                onAddedRowsChange={() => {}}
                onEditingRowIdsChange={(x) => {
                  const id = x[x.length - 1] as number;
                  setMutatieWerkbonDialoogState({ werkbonID: id });
                }}
                onCommitChanges={() => null}
              />
              <TableEditColumn width={35} showEditCommand commandComponent={DXCommandComponent} />
              <SelectionState
                selection={urlState.selectie}
                onSelectionChange={(value) => setUrlStateSync('selectie', value as number[])}
              />
              <TableSelection cellComponent={DXTableCheckboxComponent} />
            </Grid>
          </GridStyleWrapper>
        }
      />

      {mutatieWerkbonDialoogState !== null && (
        <WerkbonMutatieDialoog
          open={true}
          serviceopdracht={null}
          werkbonID={mutatieWerkbonDialoogState.werkbonID}
          onSuccess={() => {
            setMutatieWerkbonDialoogState(null);
            ophalenWerkbonnen();
          }}
          onAnnuleren={() => setMutatieWerkbonDialoogState(null)}
        />
      )}
      {urlState.werbonInfoDialoogState !== null && (
        <WerkbonInfoDialoog
          open
          id={urlState.werbonInfoDialoogState.id}
          onSuccess={() => {
            setMutatieWerkbonDialoogState(null);
            ophalenWerkbonnen();
          }}
          onAnnuleren={() => setUrlStateSync('werbonInfoDialoogState', null)}
        />
      )}
    </>
  );
};

export default Werkbonnen;
