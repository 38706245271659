import api from '../../index';
import {
  IConcurrent,
  IConcurrentAanbodscan,
  IConcurrentProduct,
  IConcurrentProductData,
  IConcurrentProductKoppeling,
  IOphalenConcurrentProductenParams,
} from '../../../../../shared/src/api/v2/concurrent';
import {
  ILeegGeneriekResult,
  IOphalenGeneriekParams,
  ISelectieGeneriekParams,
} from '../../../../../shared/src/api/v2/generiek';

const endpoints = {
  // concurrenten
  ophalenConcurrenten: async (): Promise<IConcurrent[]> => {
    return await api.post('/v2/concurrentie/ophalen-concurrenten', {});
  },
  toevoegenOfWijzigenConcurrent: async (params: IConcurrent): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/concurrentie/toevoegen-of-wijzigen-concurrent', params);
  },
  verwijderenConcurrenten: async (
    params: ISelectieGeneriekParams,
  ): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/concurrentie/verwijderen-concurrenten', params);
  },

  //producten
  ophalenProducten: async (
    params: IOphalenConcurrentProductenParams,
  ): Promise<IConcurrentProduct[]> => {
    return await api.post('/v2/concurrentie/ophalen-producten', params);
  },
  toevoegenOfWijzigenProduct: async (params: IConcurrentProduct): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/concurrentie/toevoegen-of-wijzigen-product', params);
  },
  verwijderenProducten: async (params: ISelectieGeneriekParams): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/concurrentie/verwijderen-product', params);
  },

  ophalenProductData: async (params: IOphalenGeneriekParams): Promise<IConcurrentProductData[]> => {
    return await api.post('/v2/concurrentie/ophalen-product-data', params);
  },

  ophalenProductModelKoppelingen: async (
    params: IOphalenGeneriekParams,
  ): Promise<IConcurrentProductKoppeling[]> => {
    return await api.post('/v2/concurrentie/ophalen-productmodel-koppelingen', params);
  },
  koppelenProductModel: async (
    params: IConcurrentProductKoppeling,
  ): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/concurrentie/koppelen-productmodel', params);
  },
  ontkoppelenProductModellen: async (
    params: ISelectieGeneriekParams,
  ): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/concurrentie/ontkoppelen-productmodellen', params);
  },

  ophalenScans: async (params: IOphalenGeneriekParams): Promise<IConcurrentAanbodscan[]> => {
    return await api.post('/v2/concurrentie/ophalen-scans', params);
  },
  startenScan: async (params: any): Promise<ILeegGeneriekResult> => {
    return await api.post('/v2/concurrentie/starten-scan', params);
  },
};

export default endpoints;
