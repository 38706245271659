import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import BijlageItem, { IBijlageItemProps } from './BijlageItem';
import { IUploadVoortgangVanLokaleBestanden } from '../BestandDragAndDropZone';
import MediaWeergaveDialoog from '../dialogen/MediaWeergaveDialoog';
import { RootStoreContext } from '../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { EMediaWeergaveType, MediaWeergaveItem } from '../MediaWeergave';
import {
  arrayMove,
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
} from 'react-sortable-hoc';
import styled from 'styled-components';
import { EResultType } from '../../stores/CheckStore';
import { IOphalenBestandenResultElement } from '../../../../shared/src/api/v2/bestand/bestand';

const Root = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  .sortable-item {
    //pointer-events: auto !important;
    height: 100%;
  }
  //
  //.sortable-container > .sortable-item:hover {
  //  cursor: grab;
  //}
  //
  //body > .sortable-item:hover {
  //  cursor: grabbing !important;
  //}
`;

export enum BestandType {
  Lokaal,
  ASPDrive,
}

export enum EWeergaveFocus {
  Bestand,
  Visualisatie,
}

export type Bestand = ILokaalBestand | IExternBestand;

export interface IBijlageVisualisatieInfo {
  naam: string;
  mediaType: string;
  url: string;
  grootte: number | null;
}

export interface ILokaalBestand extends IBijlageVisualisatieInfo {
  type: typeof BestandType.Lokaal;
  grootte: number;
  file: File;
}

export interface IExternBestand {
  type: typeof BestandType.ASPDrive;
  bestand: IOphalenBestandenResultElement;
}

export interface IBijlage {
  key: string;
  uploadProgress?: number;
  bestand: Bestand;
}

interface IProps {
  bestanden: Bestand[] | null;
  uploadProgresses?: IUploadVoortgangVanLokaleBestanden;
  onBestandenChange?: (bestanden: Bestand[]) => void;
  bestandenMuterenToegestaan?: boolean;
  geenBestandenWeergaveComponent?: React.ComponentType;
  weergaveFocus?: EWeergaveFocus;
  herordenenToestaan?: boolean;
  bevestigenVerwijdering?: (bestandID: number) => Promise<boolean>;
}

const HerordenenContainer: React.ComponentClass<SortableContainerProps> = SortableContainer(
  ({ children }: any) => {
    return (
      <div
        // className="d-flex flex-fill flex-wrap w-100 sortable-container justify-content-center"
        className="d-flex flex-fill flex-wrap w-100 sortable-container"
        style={{ gap: 5 }}
      >
        {children}
      </div>
    );
  },
);

export const extraheerIdVanBestand = (bestand: Bestand) =>
  bestand.type === BestandType.ASPDrive
    ? bestand.bestand.ID
    : bestand.type === BestandType.Lokaal
    ? bestand.url
    : (() => {
        throw new Error();
      })();

const BijlagenContainer: React.FC<IProps> = observer((props) => {
  const { bestandStore, checkStore } = useContext(RootStoreContext);
  // Zodat we niet overnieuw bestandsinformatie ophalen voor hetzelfde bestand
  const externeBestandenFetchQueue = useRef<number[]>([]);

  useEffect(() => {
    if (props.bestanden === null) {
      return;
    }
    // Bestanden die extern zijn en nog niet voorkomen in de fetch queue
    const externeBestanden = props.bestanden.filter(
      (bestand) =>
        bestand.type === BestandType.ASPDrive &&
        externeBestandenFetchQueue.current.findIndex((x) => x === bestand.bestand.ID) === -1,
    ) as IExternBestand[];
    if (externeBestanden.length === 0) {
      return;
    }

    externeBestanden.forEach((bestand) => {
      bestandStore.setBestandinfo(bestand.bestand.ID, bestand.bestand);
    });

    // const onbepaaldeBestandinfoBestanden = externeBestanden.filter(
    //   (x) => bestandStore.bestandinfos[x.bestand.ID] === undefined,
    // );
    //
    // (async () => {
    //   const bestIDs = onbepaaldeBestandinfoBestanden.map((x: any) => x.bestand.bestand.ID);
    //   bestandStore.scheduleOphalenBestandinfos(bestIDs);
    //
    //   // const result = await api.v2.bestand.ophalenBestandsinfos({
    //   //   ids: onbepaaldeBestandinfoBestanden.map((bestand) => bestand.bestID),
    //   // });
    //   // result.bestandinfos.forEach((info) => {
    //   //   bestandStore.setBestandinfo(info.ID, info);
    //   // });
    // })();
  }, [props.bestanden]);

  const bestanden = useMemo<IBijlage[]>(() => {
    if (props.bestanden === null) {
      return [];
    }
    return props.bestanden.map((bestand) => {
      const isLokaal = (bestand as ILokaalBestand).type === BestandType.Lokaal;
      const key = isLokaal
        ? (bestand as ILokaalBestand).url
        : String((bestand as IExternBestand).bestand.ID);
      const uploadProgress = isLokaal
        ? props.uploadProgresses === undefined
          ? undefined
          : props.uploadProgresses[(bestand as ILokaalBestand).url]
        : undefined;

      return {
        key,
        bestand,
        uploadProgress,
      };
    });
  }, [props.bestanden, props.uploadProgresses]);

  const handleRemoveClick = useCallback(
    async (bijlage: IBijlage) => {
      const bestand = bijlage.bestand as IExternBestand;
      if (props.bevestigenVerwijdering !== undefined) {
        const doorgaan = await props.bevestigenVerwijdering(bestand.bestand.ID);
        if (!doorgaan) {
          return;
        }
      } else {
        if (
          (await checkStore.bevestigen({ inhoud: 'Confirmatie verwijderen' })).type ===
          EResultType.Annuleren
        ) {
          return;
        }
      }

      const nieuweBestanden = bestanden
        .filter((bestand) => bestand.key !== bijlage.key)
        .map((bestand) => bestand.bestand);
      props.onBestandenChange!(nieuweBestanden);
    },
    [bestanden, props.onBestandenChange, props.bevestigenVerwijdering],
  );

  const GeenBestandenWeergaveComponent = useMemo(() => {
    if (props.geenBestandenWeergaveComponent) {
      return props.geenBestandenWeergaveComponent;
    }
    return () => <div className="p-2">Geen bestanden</div>;
  }, [props.geenBestandenWeergaveComponent]);

  const mediaWeergaven = useMemo<MediaWeergaveItem[]>(() => {
    const isAfbeeldingMediaType = (mediaType: string) => mediaType.startsWith('image/');
    const isVideoMediaType = (mediaType: string) => mediaType.startsWith('video/');
    const isPDFMediaType = (mediaType: string) => mediaType === 'application/pdf';

    return bestanden
      .filter((bijlage) => {
        const mediaType =
          bijlage.bestand.type === BestandType.Lokaal
            ? bijlage.bestand.mediaType
            : bestandStore.bestandinfos[(bijlage.bestand as IExternBestand).bestand.ID]
                ?.MediaType ?? null;
        if (mediaType === null) {
          return false;
        }
        return (
          isAfbeeldingMediaType(mediaType) ||
          isVideoMediaType(mediaType) ||
          isPDFMediaType(mediaType)
        );
      })
      .map((bijlage) => {
        const mediaType =
          bijlage.bestand.type === BestandType.Lokaal
            ? bijlage.bestand.mediaType
            : bestandStore.bestandinfos[(bijlage.bestand as IExternBestand).bestand.ID]
                ?.MediaType ?? null;

        let url: string;
        if (bijlage.bestand.type === BestandType.Lokaal) {
          url = bijlage.bestand.url;
        } else {
          const aspDriveBestand = bijlage.bestand as IExternBestand;
          const bi = bestandStore.bestandinfos[aspDriveBestand.bestand.ID];
          url = bi.url;
        }

        const type: EMediaWeergaveType | null = isAfbeeldingMediaType(mediaType)
          ? EMediaWeergaveType.Afbeelding
          : isVideoMediaType(mediaType)
          ? EMediaWeergaveType.Video
          : isPDFMediaType(mediaType)
          ? EMediaWeergaveType.PDF
          : null;

        return {
          id: bijlage.key,
          src: url,
          type: type!,
          mediaType,
        };
      });
  }, [bestanden, bestandStore.bestandinfos]);
  const [mediaWeergaveId, setMediaWeergaveId] = useState<string | null>(null);

  const BijlageItemEl: React.ComponentType<IBijlageItemProps & SortableElementProps> = useMemo(
    () =>
      (props.herordenenToestaan
        ? SortableElement((props: any) => (
            <div className="sortable-item" style={{ zIndex: 99999 }}>
              <BijlageItem {...props} />
            </div>
          ))
        : BijlageItem) as any,
    [props.herordenenToestaan],
  );

  // const BijlageItemEl = SortableElement((props: any) => (
  //   <div className={bestanden.length - 1 !== i ? 'mr-2' : ''}>
  //     <BijlageItem {...props} />
  //   </div>

  // ));

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      if (
        props.onBestandenChange === undefined ||
        props.bestanden === null ||
        !props.bestandenMuterenToegestaan
      ) {
        return;
      }
      props.onBestandenChange(arrayMove(props.bestanden, oldIndex, newIndex));
    },
    [props.bestanden, props.onBestandenChange, props.bestandenMuterenToegestaan],
  );

  return (
    <Root>
      {bestanden.length === 0 ? (
        <GeenBestandenWeergaveComponent />
      ) : (
        <HerordenenContainer
          onSortEnd={onSortEnd}
          axis="xy"
          // Zo staan we click events toe, zie: https://github.com/clauderic/react-sortable-hoc#click-events-being-swallowed
          // distance={5}
          pressDelay={200}
        >
          {bestanden.map((bijlage, i) => {
            const bi =
              bijlage.bestand.type === BestandType.Lokaal
                ? null
                : bestandStore.bestandinfos[bijlage.bestand.bestand.ID] || null;

            return (
              <div key={bijlage.key}>
                <BijlageItemEl
                  index={i}
                  key={bijlage.key}
                  bestandinfo={bi}
                  bestand={bijlage}
                  onRemoveClick={
                    props.bestandenMuterenToegestaan && props.onBestandenChange !== undefined
                      ? () => handleRemoveClick(bijlage)
                      : undefined
                  }
                  onEnlargeClick={() => setMediaWeergaveId(bijlage.key)}
                  weergaveFocus={props.weergaveFocus || EWeergaveFocus.Bestand}
                />
              </div>
            );
          })}
        </HerordenenContainer>
      )}
      {mediaWeergaveId !== null && (
        <MediaWeergaveDialoog
          current={mediaWeergaveId}
          onCurrentChange={(id) => setMediaWeergaveId(id as string)}
          mediaWeergaven={mediaWeergaven}
          open
          onSuccess={() => setMediaWeergaveId(null)}
          onAnnuleren={() => setMediaWeergaveId(null)}
        />
      )}
    </Root>
  );
});

export default BijlagenContainer;
