import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
// import { KlantContext } from '../index';
import { IOphalenRelatiesResultElementV2 } from '../../../../../../shared/src/api/v2/relatie';
import {
  DXTableEditColumnCellComponent,
  DXTableEditColumnCommandComponent,
  GridStyleWrapper,
  TypedColumn,
  TypedTableColumnWidthInfo,
} from '../../../../helpers/dxTableGrid';
import api from '../../../../api';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { DataTypeProvider, EditingState } from '@devexpress/dx-react-grid';
import nameof from '../../../../core/nameOf';
import { Kleur as EKleur } from '../../../../bedrijfslogica/constanten';
import { IconToevoegen } from '../../../../components/Icons';
import useUrlState from '../../../../core/useUrlState';
import { RouteComponentProps, withRouter } from 'react-router';
import RekeningMuterenDialoog, {
  IFormikValues as IRekeningMuterenDialoogFormikValues,
} from './RekeningMuterenDialoog';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import MenuLayout from '../../../../components/MenuLayout';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import { EResultType } from '../../../../stores/CheckStore';
import styled from 'styled-components';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import { IFilterSchema } from '../../../../../../shared/src/models/filter';
import { format } from 'date-fns';
import { IEntiteitProps } from '../../../../components/kaart/EntiteitContainer';

export enum EFilter {
  AlleenGebruiken = 'GEBRUIKEN',
}

interface IRekening {
  id: number | null;
  naam: string;
  iban: string;
  nietGebruiken: boolean;
}

interface IRekeningToevoegenDialoogState {
  initialValues: IRekeningMuterenDialoogFormikValues;
}
interface IRekeningWijzigenDialoogState {
  id: number;
}

interface IUrlState {
  rekeningToevoegenDialoogState: IRekeningToevoegenDialoogState | null;
  rekeningWijzigenDialoogState: IRekeningWijzigenDialoogState | null;
  filterData: IFilterData<EFilter>[];
}

interface IProps extends IEntiteitProps, RouteComponentProps {
  relID: number;
}

interface IRekeningTrProps {
  kleur?: EKleur;
}

const ZoekgemeldTr = styled.tr<IRekeningTrProps>`
  td {
    ${(props) =>
      props !== undefined
        ? `
      color: ${props.kleur} !important;
    `
        : ''}
  }
`;

const Rekeningen: React.FC<IProps> = observer((props) => {
  const { checkStore } = useContext(RootStoreContext);
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(
    props,
    useMemo<IUrlState>(
      () => ({
        rekeningToevoegenDialoogState: null,
        rekeningWijzigenDialoogState: null,
        filterData: [
          {
            naam: EFilter.AlleenGebruiken,
            data: true,
            isActief: true,
          },
        ],
      }),
      [],
    ),
  );
  // const klantContext = useContext(KlantContext);
  const [relatie, setRelatie] = useState<IOphalenRelatiesResultElementV2 | null>(null);

  const ophalenRelatie = useCallback(async () => {
    setRelatie(null);
    const result = await api.v2.relatie.ophalenRelaties({
      filterSchema: {
        filters: [{ naam: 'IDS', data: [props.relID] }],
      },
    });

    setRelatie(result.relaties[0]);
  }, [props.relID]);

  useEffect(() => {
    ophalenRelatie();
  }, [ophalenRelatie]);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    useMemo(() => maakFilterSchema(urlState.filterData), []),
  );

  const rekeningen = useMemo<IRekening[] | null>(() => {
    if (relatie === null) {
      return null;
    }

    const alleenGebruiken =
      filterSchema.filters !== undefined &&
      filterSchema.filters.find((x) => x.naam === EFilter.AlleenGebruiken) !== undefined;

    return relatie.financieel.rekeningen
      .filter((x) => !alleenGebruiken || (alleenGebruiken && !x.NietGebruiken))
      .map((rekening) => ({
        id: rekening.RelRekID,
        naam: rekening.Rekeningnaam,
        iban: rekening.IBAN,
        nietGebruiken: rekening.NietGebruiken,
        RecordToegevoegd: rekening.RecordToegevoegd,
      }));
  }, [relatie, filterSchema.filters]);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.AlleenGebruiken,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return (
            <div className="d-flex align-items-center">
              <span>Alleen te gebruiken rekeningen</span>
            </div>
          );
        },
      },
    ],
    [],
  );

  const rekeningenKeyExtractor = useCallback((row: IRekening) => row.id, []);
  const rekeningenKolommen = useMemo<TypedColumn<IRekening>[]>(
    () => [
      {
        name: 'iban',
        title: 'IBAN',
      },
      {
        name: 'naam',
        title: 'Naam',
      },
      {
        name: '__isStandaard' as any,
        title: 'Standaard',
      },
      {
        name: 'nietGebruiken',
        title: 'Gebruiken',
      },
      {
        name: '__recordToegevoegd' as any,
        title: 'Geregistreerd',
        getCellValue: (x: any) =>
          x.RecordToegevoegd !== null
            ? format(new Date(x.RecordToegevoegd), 'dd-MM-yyyy HH:mm')
            : null,
      },
      {
        name: '__laatstGewijzigd' as any,
        title: 'Gewijzigd',
        getCellValue: (x: any) =>
          x.RecordGewijzigd ?? x.RecordToegevoegd !== null
            ? format(new Date(x.RecordGewijzigd ?? x.RecordToegevoegd), 'dd-MM-yyyy HH:mm')
            : null,
      },
    ],
    [],
  );
  const rekeningenKolomBreedtes = useMemo<TypedTableColumnWidthInfo<IRekening>[]>(
    () => [
      {
        columnName: 'iban',
        width: 250,
      },
      {
        columnName: 'naam',
        width: 200,
      },
      {
        columnName: 'nietGebruiken',
        width: 125,
      },
      {
        columnName: '__isStandaard' as any,
        width: 100,
      },
      {
        columnName: '__recordToegevoegd' as any,
        width: 150,
      },
      {
        columnName: '__laatstGewijzigd' as any,
        width: 150,
      },
    ],
    [],
  );

  /*
  *
    if (values.rekeningen.length > 0 && values.standaardRekeningIBAN === null) {
      errors.standaardRekeningIBAN = teksten.formulier.E_VERPLICHT_VELD({
        veldnaam: veldnamen.standaardRekeningIBAN!,
      });
    }
  * */

  return (
    <div className="d-flex flex-fill flex-column">
      {rekeningen === null || relatie === null ? (
        <div className="d-flex align-items-center justify-content-center flex-fill">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <MenuLayout
            menu={
              <div className="d-flex">
                <button
                  className="btn btn-sm btn-light d-flex align-items-center justify-content-center"
                  style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                  onClick={() =>
                    setUrlStateSync('rekeningToevoegenDialoogState', {
                      initialValues: {
                        naam: '',
                        iban: '',
                        nietGebruiken: false,
                      },
                    })
                  }
                >
                  <IconToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                  <span className="ml-2">Rekening toevoegen</span>
                </button>

                <div className="d-flex flex-fill ml-3">
                  <FilterBalkV2
                    filters={filters}
                    filterData={urlState.filterData}
                    onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                    onFilterSchemaChange={(x) => setFilterSchema(x)}
                  />
                </div>
              </div>
            }
            body={
              <div className="d-flex flex-column flex-fill">
                <GridStyleWrapper maxHeight={300} rowAmount={rekeningen.length}>
                  <Grid
                    getRowId={rekeningenKeyExtractor as any}
                    rows={rekeningen}
                    columns={rekeningenKolommen}
                  >
                    <DataTypeProvider
                      for={[nameof<IRekening>('nietGebruiken')]}
                      formatterComponent={(formatterProps) => {
                        const rij: IRekening = formatterProps.row;
                        return <span>{rij.nietGebruiken ? 'Nee' : 'Ja'}</span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__bijzonderheden']}
                      formatterComponent={(formatterProps) => {
                        const rij: IRekening = formatterProps.row;
                        return <span></span>;
                      }}
                    />

                    <DataTypeProvider
                      for={['__isStandaard']}
                      formatterComponent={(formatterProps) => {
                        const rij: IRekening = formatterProps.row;
                        if (relatie === null) {
                          return null;
                        }
                        const isStandaard =
                          rij.id === relatie.financieel.StandaardRekening_RelRekID;
                        return (
                          <span>
                            {isStandaard ? (
                              <a
                                href="#"
                                onClick={async () => {
                                  if (
                                    (
                                      await checkStore.bevestigen({
                                        inhoud: (
                                          <span>
                                            Hiermee wordt deze rekening niet meer aangemerkt als de
                                            standaardrekening. Het is aan te bevelen om tenminste 1
                                            rekening als standaard in te stellen.
                                          </span>
                                        ),
                                      })
                                    ).type === EResultType.Annuleren
                                  ) {
                                    return;
                                  }

                                  await api.v2.relatie.rekening.wijzigenIndicatieRekeningIsStandaard(
                                    {
                                      relRekID: rij.id!,
                                      isStandaard: false,
                                    },
                                  );

                                  await ophalenRelatie();
                                }}
                              >
                                Ja
                              </a>
                            ) : (
                              <a
                                href="#"
                                onClick={async () => {
                                  if (rij.nietGebruiken) {
                                    if (
                                      (
                                        await checkStore.bevestigen({
                                          inhoud:
                                            'Deze rekening staat op niet-gebruiken, wil je toch doorgaan?',
                                        })
                                      ).type === EResultType.Annuleren
                                    ) {
                                      return;
                                    }
                                  }

                                  if (
                                    (
                                      await checkStore.bevestigen({
                                        inhoud: (
                                          <span>
                                            Hiermee wordt de standaard te gebruiken rekening
                                            aangepast naar deze rekening.
                                            <br />
                                            <br />
                                            Nieuwe bankopdrachten voor op dit moment openstaande
                                            facturen of toekomstige facturen zullen aan deze
                                            bankrekening gekoppeld worden.
                                          </span>
                                        ),
                                      })
                                    ).type === EResultType.Annuleren
                                  ) {
                                    return;
                                  }

                                  await api.v2.relatie.rekening.wijzigenIndicatieRekeningIsStandaard(
                                    {
                                      relRekID: rij.id!,
                                      isStandaard: true,
                                    },
                                  );

                                  // await api.v2.relatie.wijzigenRekeningenVanRelatie({
                                  //   relID: props.relID,
                                  //   standaardRekeningRelRekID: rij.id,
                                  //   rekeningen: rekeningen!.map((rek) => ({
                                  //     id: rek.id,
                                  //     naam: rek.naam,
                                  //     IBAN: rek.iban,
                                  //     nietGebruiken: rek.nietGebruiken,
                                  //   })),
                                  // });

                                  await ophalenRelatie();
                                }}
                              >
                                Kies
                              </a>
                            )}
                          </span>
                        );
                      }}
                    />

                    {/* <DataTypeProvider
                      for={[nameof<IRekening>('recordToegevoegd')]}
                      formatterComponent={(formatterProps) => {
                        const rij: IRekening = formatterProps.row;
                        return (
                          <span>
                            {formatterProps.value !== null
                              ? format(new Date(formatterProps.value), 'dd-MM-yyyy')
                              : ''}
                          </span>
                        );
                      }}
                    /> */}

                    <EditingState
                      onCommitChanges={async (changes) => {
                        if (changes.deleted && changes.deleted.length > 0) {
                          const checkData = await api.v2.relatie.checkVerwijderenRekeningVanRelatie(
                            {
                              relRekIDs: changes.deleted as number[],
                            },
                          );
                          const controleResultaat = await checkStore.controleren({
                            checkData,
                          });
                          if (controleResultaat.type === EResultType.Annuleren) {
                            return;
                          }

                          if (
                            (
                              await checkStore.bevestigen({
                                inhoud: 'Bevestig verwijderen rekening',
                              })
                            ).type === EResultType.Annuleren
                          ) {
                            return;
                          }

                          const nieuweRekeningen = rekeningen.filter(
                            (rekening) =>
                              !changes.deleted!.includes(rekeningenKeyExtractor(rekening)!),
                          );

                          // Als de rekening die verwijderd wordt ook als standaard was ingesteld, dan ook standaard resetten.
                          const standaardRelRekID =
                            nieuweRekeningen.findIndex(
                              (rekening) =>
                                rekening.id === relatie!.financieel.StandaardRekening_RelRekID,
                            ) === -1
                              ? null
                              : relatie!.financieel.StandaardRekening_RelRekID;

                          await api.v2.relatie.wijzigenRekeningenVanRelatie({
                            relID: props.relID,
                            standaardRekeningRelRekID: standaardRelRekID,
                            rekeningen: nieuweRekeningen.map((rek) => ({
                              id: rek.id,
                              naam: rek.naam,
                              IBAN: rek.iban,
                              nietGebruiken: rek.nietGebruiken,
                            })),
                          });

                          await ophalenRelatie();
                        }
                      }}
                      onEditingRowIdsChange={(ids) => {
                        const rekID = ids[ids.length - 1] as number;
                        setUrlStateSync('rekeningWijzigenDialoogState', {
                          id: rekID,
                        });
                      }}
                      onAddedRowsChange={() =>
                        setUrlStateSync('rekeningToevoegenDialoogState', {
                          initialValues: {
                            naam: '',
                            iban: '',
                            nietGebruiken: false,
                          },
                        })
                      }
                    />

                    <VirtualTable
                      messages={{ noData: '' }}
                      rowComponent={(rowProps) => {
                        const rij: IRekening = rowProps.row;

                        const standaardRekeningRelRekID =
                          relatie.financieel.StandaardRekening_RelRekID;

                        // if (rij.id === standaardRekeningRelRekID) {
                        //   return (
                        //     <ZoekgemeldTr kleur={EKleur.Groen}>{rowProps.children}</ZoekgemeldTr>
                        //   );
                        // }

                        if (rij.nietGebruiken) {
                          return (
                            <ZoekgemeldTr kleur={EKleur.Rood}>{rowProps.children}</ZoekgemeldTr>
                          );
                        }

                        return <tr>{rowProps.children}</tr>;
                      }}
                    />
                    <TableColumnResizing defaultColumnWidths={rekeningenKolomBreedtes} />
                    <TableHeaderRow />
                    <TableEditColumn
                      width={65}
                      commandComponent={DXTableEditColumnCommandComponent}
                      cellComponent={DXTableEditColumnCellComponent}
                      showEditCommand
                      showDeleteCommand
                    />
                  </Grid>
                </GridStyleWrapper>
                {rekeningen.length > 0 &&
                  relatie !== null &&
                  relatie.financieel.StandaardRekening_RelRekID === null && (
                    <div className="mt-2" style={{ color: EKleur.Rood }}>
                      Het is verplicht om de standaard rekening op te geven als er één of meerdere
                      rekeningen voor deze relatie bestaan.
                    </div>
                  )}
              </div>
            }
          />
          {urlState.rekeningToevoegenDialoogState !== null && (
            <RekeningMuterenDialoog
              open
              initialValues={urlState.rekeningToevoegenDialoogState.initialValues}
              onSuccess={async (result) => {
                const nieuweRekening: IRekening = {
                  id: null,
                  iban: result.iban,
                  naam: result.naam,
                  nietGebruiken: result.nietGebruiken,
                };
                const bestaandeRekening = rekeningen!.find(
                  (rekening) =>
                    rekening.iban === nieuweRekening.iban && rekening.id !== nieuweRekening.id,
                );

                const params = {
                  relID: props.relID,
                  iban: nieuweRekening.iban,
                  nietGebruiken: result.nietGebruiken,
                };
                const checkData = await api.v2.relatie.rekening.checkToevoegenRekening(params);

                const checkResult = await checkStore.controleren({
                  checkData,
                });
                if (checkResult.type === EResultType.Annuleren) {
                  return;
                }

                const huidigeRekeningen = relatie!.financieel.rekeningen.map((rek) => ({
                  id: rek.RelRekID,
                  naam: rek.Rekeningnaam,
                  iban: rek.IBAN,
                  nietGebruiken: rek.NietGebruiken,
                }));

                await api.v2.relatie.wijzigenRekeningenVanRelatie({
                  relID: props.relID,
                  standaardRekeningRelRekID: relatie!.financieel.StandaardRekening_RelRekID,
                  rekeningen: [...huidigeRekeningen, nieuweRekening]!.map((rek) => {
                    return {
                      id: rek.id,
                      naam: rek.naam,
                      IBAN: rek.iban,
                      nietGebruiken: rek.nietGebruiken,
                    };
                  }),
                });

                await ophalenRelatie();
                setUrlStateSync('rekeningToevoegenDialoogState', null);
              }}
              onAnnuleren={() => setUrlStateSync('rekeningToevoegenDialoogState', null)}
            />
          )}
          {urlState.rekeningWijzigenDialoogState !== null &&
            relatie !== null &&
            rekeningen !== null &&
            (() => {
              const origineleRekening = relatie.financieel.rekeningen.find(
                (x) => x.RelRekID === urlState.rekeningWijzigenDialoogState!.id,
              );

              const formulierRekening = rekeningen?.find(
                (x) => x.id === urlState.rekeningWijzigenDialoogState!.id,
              );

              if (origineleRekening === undefined || formulierRekening === undefined) {
                return null;
              }

              return (
                <RekeningMuterenDialoog
                  open
                  initialValues={{
                    naam: formulierRekening.naam,
                    iban: formulierRekening.iban,
                    nietGebruiken: formulierRekening.nietGebruiken,
                  }}
                  onSuccess={async (result) => {
                    const nieuweRekening: IRekening = {
                      id: origineleRekening.RelRekID,
                      iban: result.iban,
                      naam: result.naam,
                      nietGebruiken: result.nietGebruiken,
                    };
                    // const bestaandeRekening = rekeningen!.find(
                    //   (rekening) =>
                    //     rekening.iban === nieuweRekening.iban && rekening.id !== nieuweRekening.id,
                    // );

                    // if (bestaandeRekening !== undefined) {
                    //   await checkStore.melden({
                    //     titel:
                    //       'Het is niet mogelijk om een rekening te wijzigen met een al voorkomende IBAN van een andere rekening',
                    //   });
                    //   return;
                    // }

                    const huidigeRekeningen = relatie!.financieel.rekeningen.map((rek) => ({
                      id: rek.RelRekID,
                      naam: rek.Rekeningnaam,
                      iban: rek.IBAN,
                      nietGebruiken: rek.NietGebruiken,
                    }));

                    await api.v2.relatie.wijzigenRekeningenVanRelatie({
                      relID: props.relID,
                      standaardRekeningRelRekID: relatie.financieel.StandaardRekening_RelRekID,
                      rekeningen: huidigeRekeningen.map((rekening) => {
                        const rek =
                          rekening.iban === formulierRekening.iban ? nieuweRekening : rekening;
                        return {
                          id: rek.id,
                          naam: rek.naam,
                          IBAN: rek.iban,
                          nietGebruiken: rek.nietGebruiken,
                        };
                      }),
                    });

                    await ophalenRelatie();
                    setUrlStateSync('rekeningWijzigenDialoogState', null);
                  }}
                  onAnnuleren={() => setUrlStateSync('rekeningWijzigenDialoogState', null)}
                />
              );
            })()}
        </>
      )}
    </div>
  );
});

export default withRouter(Rekeningen);
