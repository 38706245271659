import React, { useMemo, useRef, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { IconKruis, IconUitklappen } from '../../Icons';
import { Kleur } from '../../../bedrijfslogica/constanten';
import { Input, Optie, Representatie } from './style';
import ResizeObserver from 'react-resize-observer';

export interface IOptie<TIdentifier> {
  id: TIdentifier;
  label: string | JSX.Element;
}

interface IOptions {
  legeOptieTekst?: string;
}

interface IProps<TIdentifier> {
  geselecteerd: TIdentifier | null;
  onSelectieChange?: (id: TIdentifier | null) => void;
  opties: IOptie<TIdentifier>[];
  legeOptieTonen?: boolean;
  disabled?: boolean;
  isWisbaar?: boolean;
  options?: IOptions;
  style?: React.CSSProperties;
  className?: string;
}

const defaultOptions: IOptions = {
  legeOptieTekst: 'Maak een keuze',
};

const Combobox = <TIdentifier extends string | number>(props: IProps<TIdentifier>) => {
  const { geselecteerd, opties, onSelectieChange, legeOptieTonen, disabled, isWisbaar } = props;
  const options = useMemo<IOptions>(() => ({ ...defaultOptions, ...props.options }), [
    props.options,
  ]);

  const popoverWidthRef = useRef<number | null>(null);

  const gekozenOptie = useMemo<IOptie<TIdentifier> | null>(() => {
    const value = geselecteerd === null ? null : opties.find((x) => x.id === geselecteerd)!;

    if (value === undefined) {
      console.log('Gekozen optie niet gevonden info: ', {
        geselecteerd,
        opties,
      });
      // Check tijdens development, veel voorkomende fout.
      throw new Error(
        `Value van combobox is undefined, dit komt waarschijnlijk omdat de waarde ${geselecteerd} niet gekoppeld kon worden aan een optie. Zie console voor meer info.`,
      );
    }

    return value;
  }, [geselecteerd, opties]);

  return (
    <OverlayTrigger
      overlay={
        <Popover
          id="combobox-popover"
          // style={{ maxWidth: kolommen.map((x) => x.breedte).reduce((acc, curr) => acc + curr, 0) }}
          style={{
            maxHeight: 300,
            overflowY: 'auto',
            minWidth: popoverWidthRef.current ?? 'min-content',
          }}
        >
          {legeOptieTonen && (
            <Optie
              onClick={() => {
                if (onSelectieChange === undefined) return;
                onSelectieChange(null);
                document.body.click();
              }}
              style={{ fontStyle: 'italic' }}
            >
              {options.legeOptieTekst}
            </Optie>
          )}
          {opties.map((optie) => (
            <Optie
              key={optie.id}
              onClick={() => {
                if (onSelectieChange === undefined) return;
                onSelectieChange(optie.id);
                document.body.click();
              }}
            >
              {optie.label}
            </Optie>
          ))}
        </Popover>
      }
      rootClose
      trigger={disabled ? [] : 'click'}
      placement="bottom-start"
    >
      <Input disabled={disabled} style={props.style} className={props.className}>
        <ResizeObserver
          onResize={(rect) => {
            popoverWidthRef.current = rect.width;
            // console.log(rect.width);
          }}
        />
        <Representatie style={{ fontStyle: gekozenOptie === null ? 'italic' : undefined }}>
          {gekozenOptie === null ? options.legeOptieTekst : gekozenOptie.label}
        </Representatie>
        {isWisbaar && gekozenOptie !== null && (
          <div
            style={{
              width: 25,
              marginTop: 3,
            }}
            className="d-flex justify-content-center align-items-center mr-1"
            onClick={(ev) => {
              ev.stopPropagation();
              if (onSelectieChange !== undefined) {
                onSelectieChange(null);
              }
            }}
          >
            <IconKruis style={{ width: 18, height: 18, fill: Kleur.Grijs }} />
          </div>
        )}
        <div
          style={{
            borderLeft: `1px solid ${Kleur.LichtGrijs}`,
            width: 25,
            backgroundColor: disabled ? 'rgb(197, 197, 197)' : Kleur.HeelLichtGrijs,
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <IconUitklappen style={{ width: 20, height: 20, fill: Kleur.Grijs }} />
        </div>
      </Input>
    </OverlayTrigger>
  );
};

export default Combobox;
