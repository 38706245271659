import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import useUrlState from '../../core/useUrlState';
import Verkenner, { EWeergaveKeuze, VerkennerSortering } from '../../components/drive/Verkenner';
import { EVerkennerItemKolom } from '../../components/drive/TabelWeergave/types';
import { ESortering } from '../../components/tabel/ASPTabel/types';
import { Helmet } from 'react-helmet';

export interface IAspDriveUrlState {
  gefocustItemId: string | null;
  selectie: string[];
  bestandsmapID: number | null;
  sortering: VerkennerSortering;
  zoekterm: '';
  weergaveKeuze: EWeergaveKeuze;
}

export const defaultAspDriveUrlState: IAspDriveUrlState = {
  gefocustItemId: null,
  selectie: [],
  bestandsmapID: null,
  sortering: [
    {
      key: EVerkennerItemKolom.Type,
      sortering: ESortering.Ascending,
    },
    {
      key: EVerkennerItemKolom.DatumToegevoegd,
      sortering: ESortering.Descending,
    },
  ],
  zoekterm: '',
  weergaveKeuze: EWeergaveKeuze.Lijst,
};

interface IProps extends RouteComponentProps {}

const ASPDrive = (props: IProps) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultAspDriveUrlState);

  return (
    <div className="d-flex flex-fill flex-column">
      <Helmet>
        <title>Drive</title>
      </Helmet>
      <Verkenner
        bestandsmapID={urlState.bestandsmapID}
        onBestandsmapIDChange={(id) => setUrlStateSync('bestandsmapID', id)}
        selectie={urlState.selectie}
        onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
        gefocustItemID={urlState.gefocustItemId}
        onGefocustItemIDChange={(id) => setUrlStateSync('gefocustItemId', id)}
        sortering={urlState.sortering}
        onSorteringChange={(sortering) => setUrlStateSync('sortering', sortering)}
        zoekterm={urlState.zoekterm}
        onZoektermChange={(zoekterm) => setUrlStateSync('zoekterm', zoekterm)}
        weergaveKeuze={urlState.weergaveKeuze}
        onWeergaveKeuzeChange={(weergaveKeuze) => setUrlStateSync('weergaveKeuze', weergaveKeuze)}
      />
    </div>
  );
};

export default ASPDrive;
