const urlCriteria = {
  overzicht: (pad: string) => pad.startsWith('/overzicht'),
  communicatie: (pad: string) => pad.startsWith('/communicatie'),
  transport: (pad: string) => pad.startsWith('/transport'),
  facturen: (pad: string) => pad.startsWith('/facturen'),
  contactpersonen: (pad: string) => pad.startsWith('/contactpersonen'),
  leverancier: (pad: string) => pad.startsWith('/leverancier'),
  diensten: (pad: string) => pad.startsWith('/diensten'),
  inkoopdienst: (pad: string) => pad.startsWith('/inkoopdienst'),
  transportdienst: (pad: string) => pad.startsWith('/transportdienst'),
  pendeldienst: (pad: string) => pad.startsWith('/pendeldienst'),
  magazijndienst: (pad: string) => pad.startsWith('/magazijndienst'),
  incassodienst: (pad: string) => pad.startsWith('/incassodienst'),
  smsdienst: (pad: string) => pad.startsWith('/smsdienst'),
  emaildienst: (pad: string) => pad.startsWith('/emaildienst'),
  servicedienst: (pad: string) => pad.startsWith('/servicedienst'),
  reviewdienst: (pad: string) => pad.startsWith('/reviewdienst'),
  sponsordienst: (pad: string) => pad.startsWith('/sponsordienst'),
  afdelingen: (pad: string) => pad.startsWith('/afdelingen'),
  financieel: (pad: string) => pad.startsWith('/financieel'),
  boekingen: (pad: string) => pad.startsWith('/boekingen'),
  documenten: (pad: string) => pad.startsWith('/bestanden'),
  rekeningen: (pad: string) => pad.startsWith('/rekeningen'),
  bankzaken: (pad: string) => pad.startsWith('/bankzaken'),
  creditmanagementdienst: (pad: string) => pad.startsWith('/creditmanagementdienst'),
};

export default urlCriteria;
