import {
  IOphalenActieclaimsParams,
  IOphalenActieclaimsResult,
  IGoedkeurenActieclaimsParams,
  IGoedkeurenActieclaimsResult,
  IAfkeurenActieclaimsParams,
  IAfkeurenActieclaimsResult,
  IVerwijderenActieclaimsParams,
  IVerwijderenActieclaimsResult,
  IToevoegenActieclaimParams,
  IToevoegenActieclaimResult,
  IToewijzenActieclaimsParams,
  IToewijzenActieclaimResult,
  IAfwijzenActieclaimsParams,
  IAfwijzenActieclaimsResult,
} from '../../../../../../shared/src/api/v2/actie/klantWerftKlant';
import api from '../../../index';
import ICheckData from '../../../../../../shared/src/models/ICheckData';

const klantWerftKlant = {
  ophalenActieclaims: async (
    params: IOphalenActieclaimsParams,
  ): Promise<IOphalenActieclaimsResult> => {
    return await api.post('/v2/klantactie/klantwerftklant/ophalen-actieclaims', params);
  },
  checkGoedkeurenActieclaims: async (params: IGoedkeurenActieclaimsParams): Promise<ICheckData> => {
    return await api.post('/v2/actie/klantwerftklant/check-goedkeuren-actieclaims', params);
  },
  goedkeurenActieclaims: async (
    params: IGoedkeurenActieclaimsParams,
  ): Promise<IGoedkeurenActieclaimsResult> => {
    return await api.post('/v2/actie/klantwerftklant/goedkeuren-actieclaims', params);
  },
  checkAfkeurenActieclaims: async (params: IAfkeurenActieclaimsParams): Promise<ICheckData> => {
    return await api.post('/v2/actie/klantwerftklant/check-Afkeuren-actieclaims', params);
  },
  AfkeurenActieclaims: async (
    params: IAfkeurenActieclaimsParams,
  ): Promise<IAfkeurenActieclaimsResult> => {
    return await api.post('/v2/actie/klantwerftklant/Afkeuren-actieclaims', params);
  },
  checkVerwijderenActieclaims: async (
    params: IVerwijderenActieclaimsParams,
  ): Promise<ICheckData> => {
    return await api.post('/v2/actie/klantwerftklant/check-Verwijderen-actieclaims', params);
  },
  VerwijderenActieclaims: async (
    params: IVerwijderenActieclaimsParams,
  ): Promise<IVerwijderenActieclaimsResult> => {
    return await api.post('/v2/actie/klantwerftklant/verwijderen-actieclaims', params);
  },
  checkToevoegenActieclaim: async (params: IToevoegenActieclaimParams): Promise<ICheckData> => {
    return await api.post('/v2/actie/klantwerftklant/check-toevoegen-actieclaim', params);
  },
  toevoegenActieclaim: async (
    params: IToevoegenActieclaimParams,
  ): Promise<IToevoegenActieclaimResult> => {
    return await api.post('/v2/actie/klantwerftklant/toevoegen-actieclaim', params);
  },
  checkToewijzenActieclaims: async (params: IToewijzenActieclaimsParams): Promise<ICheckData> => {
    return await api.post('/v2/actie/klantwerftklant/check-toewijzen-actieclaims', params);
  },
  toewijzenActieclaims: async (
    params: IToewijzenActieclaimsParams,
  ): Promise<IToewijzenActieclaimResult> => {
    return await api.post('/v2/actie/klantwerftklant/toewijzen-actieclaims', params);
  },
  checkAfwijzenActieclaims: async (params: IAfwijzenActieclaimsParams): Promise<ICheckData> => {
    return await api.post('/v2/actie/klantwerftklant/check-afwijzen-actieclaims', params);
  },
  afwijzenActieclaims: async (
    params: IAfwijzenActieclaimsParams,
  ): Promise<IAfwijzenActieclaimsResult> => {
    return await api.post('/v2/actie/klantwerftklant/afwijzen-actieclaims', params);
  },
};

export default klantWerftKlant;
