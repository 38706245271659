import React, { useCallback, useContext, useMemo, useRef } from 'react';
import {
  ASPKolom,
  ESortering,
  ESorteringModus,
  IASPKolomBase,
  IAspKolomSorteringItem,
  IASPTabelSorteringKolomOpties,
  IASPTabelSorteringOpties,
} from '../types';
import styled from 'styled-components';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import { Grid, Index } from 'react-virtualized';
import { GridCellProps } from 'react-virtualized/dist/es/Grid';
import NaamTableHeading from './NaamTableHeading';
import TableHeading from './TableHeading';
import useBijGewijzigdEffect from '../../../../core/useBijGewijzigdEffect';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import ToevoegenHeading from './ToevoegenHeading';

export const ASP_STANDAARD_HEADER_HOOGTE = 40;
export const STANDAARD_SORTERINGS_MODUS: ESorteringModus = ESorteringModus.Blacklist;

const magSorterenVoorSorteringsmodus = (
  sorteringsModus: ESorteringModus,
  kolomOpties: IASPTabelSorteringKolomOpties | null,
): boolean => {
  if (sorteringsModus === ESorteringModus.Whitelist) {
    if (kolomOpties === null) {
      return false;
    }

    return kolomOpties.magSorteren === true;
  } else if (sorteringsModus === ESorteringModus.Blacklist) {
    if (kolomOpties === null) {
      return true;
    }

    return kolomOpties.magSorteren === true;
  }

  throw new Error('Ongeimplementeerd voor sorteringsmodus');
};

const Root = styled.div`
  .header-grid {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }

  border-bottom: 1px solid ${Kleur.LichtGrijs};
`;

export interface IHeaderProps {
  height?: number;
  width: number;
  children: React.ReactNode;
}

export const DefaultHeaderComponent = (props: IHeaderProps) => {
  return (
    <Root
      style={{
        height: props.height,
        width: props.width,
      }}
    >
      {props.children}
    </Root>
  );
};

export interface IKolomRenderMapItem<TKey extends keyof any, TRow> {
  kolom:
    | ASPKolom<TKey, TRow>
    | 'padding'
    | 'vigerend'
    | 'uitklap'
    | 'toevoegen'
    | 'selectie'
    | 'wijzigen'
    | 'verwijderen';
  breedte: number;
}

export type KolomRenderMap<TKey extends keyof any, TRow> = Record<
  number,
  IKolomRenderMapItem<TKey, TRow>
>;

interface IProps<TKey extends keyof any, TRow> {
  height?: number;
  width: number;
  scrollLeft: number;
  kolommen: ASPKolom<TKey, TRow>[];
  getColumnWidth: (index: Index) => number;
  heeftUitklapRij: boolean;
  kolomRenderMap: KolomRenderMap<TKey, TRow>;
  sortering?: IAspKolomSorteringItem<TKey>[];
  onSorteringChange?: (sortering: IAspKolomSorteringItem<TKey>[]) => void;
  sorteringOpties?: IASPTabelSorteringOpties<TKey>;
  headerComponent?: React.ComponentType<IHeaderProps>;
  onToevoegenClick: () => void;
}

const Header = observer(<TKey extends keyof any, TRow>(props: IProps<TKey, TRow>) => {
  const { toetsenbordStore } = useContext(RootStoreContext);
  const gridRef = useRef<Grid>();

  const cellRenderer = useCallback(
    (gridCellProps: GridCellProps) => {
      const kolomRenderItem = props.kolomRenderMap[gridCellProps.columnIndex];

      if (kolomRenderItem.kolom === 'padding') {
        return <div style={gridCellProps.style} />;
      }

      if (kolomRenderItem.kolom === 'toevoegen') {
        return (
          <div style={gridCellProps.style}>
            <ToevoegenHeading onClick={props.onToevoegenClick} />
          </div>
        );
      }

      if (
        kolomRenderItem.kolom === 'uitklap' ||
        kolomRenderItem.kolom === 'vigerend' ||
        kolomRenderItem.kolom === 'selectie' ||
        kolomRenderItem.kolom === 'wijzigen' ||
        kolomRenderItem.kolom === 'verwijderen'
      ) {
        return <div style={gridCellProps.style} />;
      }
      const kolomBase = kolomRenderItem.kolom as IASPKolomBase<TKey, TRow>;

      const sortertingItemIdx = props.sortering?.findIndex((x) => x.key === kolomBase.key) ?? null;
      const sorteringItem =
        sortertingItemIdx === null || sortertingItemIdx === -1
          ? null
          : props.sortering?.[sortertingItemIdx] ?? null;
      const sorteringsnummer =
        props.sortering?.length === 1 ? null : sortertingItemIdx! + 1 ?? null;

      const sorteringsmodus = props.sorteringOpties?.modus ?? STANDAARD_SORTERINGS_MODUS;
      const sorteringsOptie = props.sorteringOpties?.kolomOpties?.[kolomBase.key] ?? null;
      const magSorteren = magSorterenVoorSorteringsmodus(sorteringsmodus, sorteringsOptie);

      const onClickHandler = magSorteren
        ? (e: React.MouseEvent<HTMLDivElement>) => {
            // Meta betekent uitschakelen sortering van de kolom
            if (toetsenbordStore.meta) {
              props.onSorteringChange?.(
                toetsenbordStore.shift
                  ? props.sortering?.filter((x) => x.key !== kolomBase.key) ?? []
                  : [],
              );
              return;
            }

            const baseSortering = toetsenbordStore.shift
              ? props.sortering?.filter((x) => x.key !== kolomBase.key) ?? []
              : [];

            // Als er een sortering is, dan moeten we de sortering omdraaien.
            if (sorteringItem) {
              props.onSorteringChange?.([
                ...baseSortering,
                {
                  key: kolomBase.key,
                  sortering:
                    sorteringItem.sortering === ESortering.Ascending
                      ? ESortering.Descending
                      : ESortering.Ascending,
                },
              ]);
            } else {
              props.onSorteringChange?.([
                ...baseSortering,
                {
                  key: kolomBase.key,
                  sortering: ESortering.Ascending,
                },
              ]);
            }
          }
        : undefined;

      let content;
      if (kolomRenderItem.kolom.label === undefined || kolomRenderItem.kolom.label === null) {
        content = (
          <TableHeading
            sortering={sorteringItem?.sortering}
            onClick={onClickHandler}
            sorteringsnummer={sorteringsnummer ?? undefined}
            sorteringStyle={{ marginLeft: 0 }}
          />
        );
      } else if (typeof kolomRenderItem.kolom.label === 'string') {
        content = (
          <NaamTableHeading
            naam={kolomRenderItem.kolom.label}
            sortering={sorteringItem?.sortering}
            onClick={onClickHandler}
            sorteringsnummer={sorteringsnummer ?? undefined}
          />
        );
      } else {
        const LabelComp = kolomRenderItem.kolom.label;
        content = (
          <TableHeading
            sortering={sorteringItem?.sortering}
            onClick={onClickHandler}
            sorteringsnummer={sorteringsnummer ?? undefined}
          >
            <LabelComp />
          </TableHeading>
        );
      }

      return <div style={gridCellProps.style}>{content}</div>;
    },
    [props.kolomRenderMap, props.sortering, toetsenbordStore.shift, toetsenbordStore.meta],
  );

  useBijGewijzigdEffect(() => {
    // Kolom render map is aangepast, het is dus mogelijk dat groottes veranderd zijn
    gridRef.current?.forceUpdate();
    gridRef.current?.recomputeGridSize();
  }, [props.kolomRenderMap]);

  const HeaderComp = useMemo(() => props.headerComponent ?? DefaultHeaderComponent, [
    props.headerComponent,
  ]);

  return (
    <HeaderComp width={props.width} height={props.height}>
      <Grid
        ref={(node) => {
          gridRef.current = node!;
        }}
        className="header-grid"
        columnWidth={props.getColumnWidth}
        columnCount={Object.keys(props.kolomRenderMap).length}
        height={props.height ?? ASP_STANDAARD_HEADER_HOOGTE}
        cellRenderer={cellRenderer}
        rowHeight={props.height ?? ASP_STANDAARD_HEADER_HOOGTE}
        rowCount={1}
        scrollLeft={props.scrollLeft}
        width={props.width}
      />
    </HeaderComp>
  );
});

export default Header;
