import React, { useCallback, useContext, useMemo } from 'react';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { Formik, FormikActions, FormikProps, Field, FieldProps } from 'formik';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import * as Yup from 'yup';
import IDialoogProps from '../../../../../core/IDialoogProps';
import Dialoog from '../../../../../components/dialogen/Dialoog';
import teksten from '../../../../../bedrijfslogica/teksten';
import VinkVeld from '../../../../../components/formulier/VinkVeld';
import FormikVeldFout from '../../../../../components/formulier/FormikVeldFout';
import nameof from '../../../../../core/nameOf';
import { REGEX_IBAN } from '../../../../../bedrijfslogica/validatie';
import api from '../../../../../api';
import { RootStoreContext } from '../../../../../stores/RootStore';
import { EResultType } from '../../../../../stores/CheckStore';

interface IResult {
  iban: string;
  naam: string;
  nietGebruiken: boolean;
}

interface IProps extends IDialoogProps<IResult> {
  initialValues?: IFormikValues;
}

export interface IFormikValues {
  iban: string;
  naam: string;
  nietGebruiken: boolean;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  iban: 'IBAN',
  naam: 'Naam',
  nietGebruiken: 'Niet gebruiken',
};

const RekeningMuterenDialoog: React.FC<IProps> = (props) => {
  const { checkStore } = useContext(RootStoreContext);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        iban: Yup.string()
          .required()
          .matches(
            REGEX_IBAN,
            teksten.formulier.E_VOLDOET_NIET_AAN_FORMAAT({
              veldnaam: 'IBAN',
              voorbeeldFormaat: 'NL91ABNA0417164300',
            }),
          ),
        naam: Yup.string()
          .required()
          .min(
            1,
            teksten.formulier.E_MINIMAAL_AANTAL_KARAKTERS_BEVATTEN({ aantal: 1, veldnaam: 'Naam' }),
          ),
      }),
    [],
  );
  const initialValues = useMemo<IFormikValues>(() => {
    if (props.initialValues !== undefined) {
      return props.initialValues;
    }
    return {
      iban: '',
      naam: '',
      nietGebruiken: false,
    };
  }, [props.initialValues]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      props.onSuccess(values);

      actions.setSubmitting(false);
    },
    [props.onSuccess],
  );

  return (
    <Dialoog index={props.dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>Rekening toevoegen</ModalTitle>
      </ModalHeader>
      <Formik<IFormikValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<IFormikValues>) => {
          const { submitForm, isSubmitting, isValid } = formikProps;
          return (
            <>
              <ModalBody>
                <div className="form-group">
                  <div className="row">
                    <div className="col-12">
                      <label>{veldnamen.iban}</label>
                      <Field
                        name={nameof<IFormikValues>('iban')}
                        render={(fieldProps: FieldProps) => {
                          return (
                            <>
                              <input {...fieldProps.field} type="text" className="form-control" />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>
                    <div className="col-12 mt-2">
                      <label>{veldnamen.naam}</label>
                      <Field
                        name={nameof<IFormikValues>('naam')}
                        render={(fieldProps: FieldProps) => {
                          return (
                            <>
                              <input {...fieldProps.field} type="text" className="form-control" />
                              <FormikVeldFout fieldProps={fieldProps} />
                            </>
                          );
                        }}
                      />
                    </div>
                    <div className="col-12 mt-3">
                      <span className="d-flex align-items-center">
                        <Field
                          name={nameof<IFormikValues>('nietGebruiken')}
                          render={(fieldProps: FieldProps) => {
                            const { field, form } = fieldProps;
                            return (
                              <VinkVeld
                                aangevinkt={field.value}
                                onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                        <span className="ml-2">{veldnamen.nietGebruiken}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-start">
                <button
                  className="btn btn-primary"
                  onClick={submitForm}
                  style={{ width: 100 }}
                  disabled={isSubmitting || !isValid}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={props.onAnnuleren}
                  style={{ width: 100 }}
                  disabled={isSubmitting}
                >
                  Annuleren
                </button>
              </ModalFooter>
            </>
          );
        }}
      />
    </Dialoog>
  );
};

export default RekeningMuterenDialoog;
