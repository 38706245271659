import { createGlobalStyle } from 'styled-components';
import { colors } from './variables';
import { Kleur } from '../bedrijfslogica/constanten';

interface IGlobalStyleProps {
  customCss: string;
}

export const GlobalStyle = createGlobalStyle<IGlobalStyleProps>`

  //@font-face {
  //    font-family: "Eina 01 Regular";
  //    src: url("fonts/Eina-01-Regular") format("opentype");
  //}

  * {
    box-sizing: border-box;
  }

  ${() => {
    // if (navigator.userAgent.match(/Windows/i) || true) {
    //   return `
    //     ::-webkit-scrollbar {
    //       width: 10px;
    //       height: 10px;
    //
    //     }
    //
    //     /* Track */
    //     ::-webkit-scrollbar-track {
    //       //box-shadow: inset 0 0 5px grey;
    //       //border-radius: 10px;
    //       background-color: rgba(255, 255, 255, 0.5);
    //
    //     }
    //
    //     /* Handle */
    //     ::-webkit-scrollbar-thumb {
    //       background-color: ${Kleur.DonkerPaars};
    //     }
    //   `;
    // }

    return '';
  }}
  .modal-header {
    cursor: grab;

    .modal-title {
      cursor: text;
    }
  }

  .modal-xxl {
    max-width: 1500px !important;
  }


  .modal-bestandsselectie {
    max-width: 1200px !important;
  }

  .modal-boekingsdialoog {
    max-width: 1750px !important;
  }

  .modal-mergedialoog {
    max-width: 1750px !important;
  }

  .modal-sjabloondialoog {
    max-width: 1700px !important;
  }

  .modal-sjabloondialoog-selecteer {
    max-width: 1350px !important;
  }

  .modal-sjabloondialoog-ingeklapt {
    max-width: 1400px !important;
  }


  html {
    font-size: 14px;
    @media (max-width: 1400px) {
      font-size: 12px;
    }
  }

  body {
    line-height: 1.5;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: ${colors.text};
    font-family: 'Lato', sans-serif;  // Lato is de standaard
    height: 100vh;
    overflow: hidden;
  }

  a {
    color: ${Kleur.ZwembadBlauw};
  }

  /* // Verstop TinyMCE reclame */
  a[aria-label="Powered by Tiny"] {
    display: none;
  }

  h5 {
    font-weight: 700;
  }

  hr {
    margin: 0px;
  }

  .form-control {
    border: 1px solid ${colors.inputBorderColor};
  }

  input:disabled {
    background-color: ${colors.backgroundLightGrey2} !important;
    color: ${colors.textGreyDark};
  }

  /* // Zachtere versie van een btn-primary bootstrap knop */
  .btn-primary-soft {
    background-color: #F4F9FD;
    color: #0062AF;
    border: 0;

    &:hover {
      background-color: #F4F9FD;
      color: #0062AF;
    }
  }

  /* // Zachtere versie van een btn-danger bootstrap knop */
  .btn-danger-soft {
    background-color: #fddcd4;
    color: #af4244;
    border: 0;

    &:hover {
      background-color: #fddcd4;
      color: #af4244;
    }
  }

  .dialog-width-550 {
    max-width: 550px;
  }

  .form-control::placeholder {
    color: ${Kleur.BeetjeLichtGrijs};
    font-style: italic;
  }

  // React big calendar style resetten zodat we onze eigen event styling kunnen toepassen
  // .rbc-event {
  //   background-color: white !important;
  //   border-radius: 0 !important;
  //   color: ${Kleur.Zwart} !important;;
  //   padding: 2px 5px !important;
  //   border: 1px solid ${Kleur.LichtGrijs} !impoyyrtant;
  // }

  .rbc-event-label {
    display: none !important;
  }

  .rbc-event-content {
    //padding: 2px 0px;
  }

  .react-datepicker-popper {
    z-index: 9999 !important;
  }

  .modal-backdrop {
    // Fix voor backdrop van communicatie overlay
    transform: scale(100);
  }

  .modal-backdrop.show {
    opacity: 0.15 !important;
  }

  .react-draggable {
    z-index: 500;
  }

  .dx-g-bs4-no-data-cell {
    padding: 0 !important;
    padding-top: 7px !important;
  }

  //.dx-g-bs4-table-cell {
  //  overflow: visible !important;
  //}

  div[class*="IndicatorsContainer"] > div[class*="indicatorContainer"] {
    padding: 0 !important;
  }

  div[class*="IndicatorsContainer"] > div[class*="indicatorContainer"] > svg {
    width: 18px !important;
    height: 18px !important;
    fill: #999999;
    margin-top: 3px;
    margin-left: 3px;
  }

  div[class*="IndicatorsContainer"] > div[class*="DropdownIndicator"] > svg {
    width: 18px !important;
    height: 18px !important;
  }

  .fill-window {
    height: 100vh;
    position: absolute;
    left: 0;
    width: 100vw;
    overflow: hidden;
    top: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
  }

  .weergave-pdf {
    overflow: auto;
    padding: 30px 0px;
  }

  .boekstuk-weergave-pdf {
    max-height: 500px;
  }

  ${(props) => props.customCss}
`;
