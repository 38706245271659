import React, { useCallback, useContext, useMemo, useState } from 'react';
import NavigatieHeader from './Header';
import { Navigatie, NavigatieFooter } from './styles';
import {
  EFunctioneleIcon,
  EyeIcon,
  functioneleIconMap,
  IconBellen,
  IconBoekhouding,
  IconCloud,
  IconContentCopy,
  IconCreditcard,
  IconEmail,
  IconEscalator,
  IconEuro,
  IconFactuur,
  IconFrontoffice,
  IconKlantkaart,
  IconLeverancier,
  IconLink,
  IconMagazijn,
  IconMoveToInbox,
  IconOrganisatie,
  IconPersoon,
  IconSchedule,
  IconService,
  IconSettings,
  IconSMS,
  IconSponsoring,
  IconTransport,
  IconTrend,
  IconUitBedrijf,
  IconVlag,
  IconWasmachine,
  IconWhatsapp,
} from '../../components/Icons';
import RealtimeStatus from '../../one-off-components/realtime/RealtimeStatus';
import { Kleur } from '../../bedrijfslogica/constanten';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import GebruikerSectie from './GebruikerSectie';
import HorizontaleScheidingslijn from '../../components/layout/HorizontaleScheidingslijn';
import GlobaalZoekveld from './GlobaalZoekveld';
import VerticaleNavigatieBalk, {
  ELink,
  IGroepLink,
  ILink,
  IWeergaveProps,
  Link,
} from '../../components/navigatie/VerticaleNavigatieBalk';
import heeftAutorisatie from '../../helpers/heeftAutorisatie';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../stores/RootStore';
import { EPermissie } from '../../bedrijfslogica/enums';
import CommunicatieSectie from './CommunicatieSectie';
import ToestelSectie from './ToestelSectie';
import { MeldingIndicatieSoort } from '../../../../shared/src/api/v2/meldingIndicatie';
import IRemoteData, {
  createPendingRemoteData,
  createReadyRemoteData,
  ERemoteDataState,
} from '../../models/IRemoteData';

const IconOpvolgen = functioneleIconMap[EFunctioneleIcon.Opvolgen];
const IconCommunicatie = functioneleIconMap[EFunctioneleIcon.Communicatie];
const IconMonitoring = functioneleIconMap[EFunctioneleIcon.Monitoring];
const IconReview = functioneleIconMap[EFunctioneleIcon.Review];
const IconControltaak = functioneleIconMap[EFunctioneleIcon.Controltaak];
const IconSignalering = functioneleIconMap[EFunctioneleIcon.Signalering];
const IconBeheer = functioneleIconMap[EFunctioneleIcon.Instellingen];
const IconInkoop = functioneleIconMap[EFunctioneleIcon.Inkoop];
const IconValutateken = functioneleIconMap[EFunctioneleIcon.Valutateken];
const IconAanbod = functioneleIconMap[EFunctioneleIcon.Aanbod];
const IconMarketing = functioneleIconMap[EFunctioneleIcon.Marketing];
const IconWebsite = functioneleIconMap[EFunctioneleIcon.Website];
const IconAdministratie = functioneleIconMap[EFunctioneleIcon.Administratie];
const IconFacilitair = functioneleIconMap[EFunctioneleIcon.Facilitair];
const IconKwaliteitsbeheer = functioneleIconMap[EFunctioneleIcon.Kwaliteitsbeheer];

interface INavigatieLink {
  path: string;
  weergave: React.ComponentType<IWeergaveProps>;
}

enum GroepLinkId {
  Frontoffice,
  Backoffice,
  Duplicaten,
  Financieel,
  Marketing,
  MeerFinancieel,
  Magazijn,
  Website,
  Administratie,
  AdministratieVerlof,
  Inkoop,
  Communicatie,
  Facilitair,
  Aanbod,
  Beheer,
  Technisch,
  Opvolging,
  Monitoring,
  Stambeheer,
  Kwaliteitsbeheer,
}

const iconSize = 18;

const stateKey = 'PRIMAIRE_NAVIGATIE_GROEP_LINKS_STATE';

const LinkZoekterm = (props: { zoekterm: string; tekst: string }) => {
  return (
    <span>
      {useMemo(() => {
        if (props.zoekterm.length === 0) {
          return <>{props.tekst}</>;
        }

        const regexp = new RegExp(props.zoekterm, 'gi');
        const matches = [...props.tekst.matchAll(regexp)];

        const isPartOfMatch = (index: number) => {
          return matches.some(
            (match) => match.index! <= index && match.index! + match[0].length - 1 >= index,
          );
        };

        return (
          <React.Fragment>
            {props.tekst.split('').map((char, i) => {
              const isPartOf = isPartOfMatch(i);
              return (
                <span
                  key={i}
                  style={isPartOf ? { fontWeight: 'bold', color: Kleur.Wit } : undefined}
                >
                  {char}
                </span>
              );
            })}
          </React.Fragment>
        );
      }, [props.zoekterm, props.tekst])}
    </span>
  );
};

const PrimaireNavigatie = observer((props: RouteComponentProps) => {
  const { autorisatieStore, meldingIndicatieStore } = useContext(RootStoreContext);
  const [uitgeklapteGroepLinksState, setUitgeklapteGroepLinksState] = useState<
    Partial<Record<string | number, boolean>>
  >(
    useMemo(() => {
      const state = localStorage.getItem(stateKey);
      if (state === null) {
        return {};
      }

      return JSON.parse(state);
    }, []),
  );

  const uitgeklapteGroepLinks = useMemo(() => uitgeklapteGroepLinksState, [
    uitgeklapteGroepLinksState,
  ]);
  const setUitgeklapteGroepLinks = useCallback(
    (state: Partial<Record<string | number, boolean>>) => {
      setUitgeklapteGroepLinksState(state);

      localStorage.setItem(stateKey, JSON.stringify(state));
    },
    [setUitgeklapteGroepLinksState],
  );

  const [zoekterm, setZoekterm] = useState<string>('');

  const links = useMemo<Link<GroepLinkId>[]>(() => {
    const communicatieMeldingIndicatie: IRemoteData<number> =
      meldingIndicatieStore.indicaties.state === ERemoteDataState.Pending
        ? createPendingRemoteData()
        : createReadyRemoteData(
            ([
              'WHATSAPP',
              'TELEFOON',
              'SMS',
              'EMAIL',
              'CONTACTVERZOEK',
              'TERUGBELVERZOEK',
            ] as MeldingIndicatieSoort[]).reduce(
              (acc, soort) => acc + (meldingIndicatieStore.indicaties.data![soort] ?? 0),
              0,
            ),
          );
    const frontofficeMeldingIndicatie: IRemoteData<number> =
      meldingIndicatieStore.indicaties.state === ERemoteDataState.Pending
        ? createPendingRemoteData()
        : createReadyRemoteData(
            (['TAKEN', 'EXTERNE_DATA', 'SIGNALERINGEN'] as MeldingIndicatieSoort[]).reduce(
              (acc, soort) => acc + (meldingIndicatieStore.indicaties.data![soort] ?? 0),
              0,
            ),
          );

    const backofficeMeldingIndicatie: IRemoteData<number> =
      meldingIndicatieStore.indicaties.state === ERemoteDataState.Pending
        ? createPendingRemoteData()
        : createReadyRemoteData(
            ([
              'DUPLICATEN_RELATIE',
              'DUPLICATEN_PERSOON',
              'REVIEWS',
              'BELTAKEN',
              'ESCALATIES',
            ] as MeldingIndicatieSoort[]).reduce(
              (acc, soort) => acc + (meldingIndicatieStore.indicaties.data![soort] ?? 0),
              0,
            ),
          );

    const backofficeKwaliteitsbeheerMeldingIndicatie: IRemoteData<number> =
      meldingIndicatieStore.indicaties.state === ERemoteDataState.Pending
        ? createPendingRemoteData()
        : createReadyRemoteData(
            (['REVIEWS'] as MeldingIndicatieSoort[]).reduce(
              (acc, soort) => acc + (meldingIndicatieStore.indicaties.data![soort] ?? 0),
              0,
            ),
          );

    const backofficeDuplicatenMeldingIndicatie: IRemoteData<number> =
      meldingIndicatieStore.indicaties.state === ERemoteDataState.Pending
        ? createPendingRemoteData()
        : createReadyRemoteData(
            (['DUPLICATEN_RELATIE', 'DUPLICATEN_PERSOON'] as MeldingIndicatieSoort[]).reduce(
              (acc, soort) => acc + (meldingIndicatieStore.indicaties.data![soort] ?? 0),
              0,
            ),
          );

    return [
      {
        type: ELink.Link,
        path: '/dashboard',
        weergave: (weergaveProps) => {
          const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
          return (
            <li>
              <IconDashboard
                style={{
                  width: iconSize,
                  height: iconSize,
                  fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                }}
              />
              <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                <LinkZoekterm zoekterm={zoekterm} tekst="Dashboard" />
              </span>
            </li>
          );
        },
      },
      {
        type: ELink.Link,
        path: '/klant',
        weergave: (weergaveProps) => (
          <li>
            <IconKlantkaart
              style={{
                width: iconSize,
                height: iconSize,
                fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
              }}
            />
            <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
              <LinkZoekterm zoekterm={zoekterm} tekst="Klant" />
            </span>
          </li>
        ),
      },
      {
        type: ELink.Link,
        path: '/leverancier',
        weergave: (weergaveProps) => (
          <li>
            <IconLeverancier
              style={{
                width: iconSize,
                height: iconSize,
                fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
              }}
            />
            <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
              <LinkZoekterm zoekterm={zoekterm} tekst="Leverancier" />
            </span>
          </li>
        ),
      },
      {
        type: ELink.GroepLink,
        id: GroepLinkId.Frontoffice,
        badgeAantal: frontofficeMeldingIndicatie.data ?? undefined,
        weergave: (weergaveProps) => (
          <div className="d-flex align-items-center" style={{ marginLeft: 3 }}>
            <IconFrontoffice
              style={{
                width: iconSize,
                height: iconSize,
                fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
              }}
            />
            <span
              style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs, marginLeft: 10 }}
            >
              <LinkZoekterm zoekterm={zoekterm} tekst="Frontoffice" />
            </span>
          </div>
        ),
        sublinks: [
          {
            type: ELink.Link,
            path: '/taken',
            badgeAantal: meldingIndicatieStore.indicaties.data?.TAKEN ?? undefined,
            weergave: (weergaveProps) => (
              <li>
                <IconControltaak
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Taken" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/signaleringen',
            badgeAantal: meldingIndicatieStore.indicaties.data?.SIGNALERINGEN ?? undefined,
            weergave: (weergaveProps) => {
              const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
              return (
                <li>
                  <IconSignalering
                    style={{
                      width: iconSize,
                      height: iconSize,
                      fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                    }}
                  />
                  <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                    <LinkZoekterm zoekterm={zoekterm} tekst="Signaleringen" />
                  </span>
                </li>
              );
            },
          },
          {
            type: ELink.Link,
            path: '/externe-data',
            badgeAantal: meldingIndicatieStore.indicaties.data?.EXTERNE_DATA ?? undefined,
            weergave: (weergaveProps) => (
              <li>
                <IconMoveToInbox
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Externe data" />
                </span>
              </li>
            ),
          },
          {
            id: GroepLinkId.Opvolging,
            type: ELink.GroepLink,
            weergave: (weergaveProps) => (
              <div className="d-flex align-items-center" style={{ marginLeft: 3 }}>
                <IconOpvolgen
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span
                  style={{
                    color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                    marginLeft: 10,
                  }}
                >
                  <LinkZoekterm zoekterm={zoekterm} tekst="Opvolging" />
                </span>
              </div>
            ),
            sublinks: [
              {
                type: ELink.Link,
                path: '/monitoring/acceptatie',
                weergave: (weergaveProps) => (
                  <li>
                    {/* <IconKlantkaart
                      style={{
                        width: iconSize,
                        height: iconSize,
                        fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                      }}
                    /> */}
                    <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                      <LinkZoekterm zoekterm={zoekterm} tekst="Acceptatie" />
                    </span>
                  </li>
                ),
              },
              {
                type: ELink.Link,
                path: '/opvolging/concept-contracten',
                weergave: (weergaveProps) => (
                  <li>
                    {/* <IconOpvolgen
                      style={{
                        width: iconSize,
                        height: iconSize,
                        fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                      }}
                    /> */}
                    <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                      <LinkZoekterm zoekterm={zoekterm} tekst="Conceptcontracten" />
                    </span>
                  </li>
                ),
              },
              {
                type: ELink.Link,
                path: '/opvolging/overdracht',
                weergave: (weergaveProps) => (
                  <li>
                    {/* <IconOpvolgen
                      style={{
                        width: iconSize,
                        height: iconSize,
                        fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                      }}
                    /> */}
                    <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                      <LinkZoekterm zoekterm={zoekterm} tekst="Overdrachten" />
                    </span>
                  </li>
                ),
              },
              // {
              //   type: ELink.Link,
              //   path: '/opvolging/actieclaims',
              //   weergave: (weergaveProps) => (
              //     <li>
              //       {/* <IconOpvolgen
              //         style={{
              //           width: iconSize,
              //           height: iconSize,
              //           fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
              //         }}
              //       /> */}
              //       <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
              //         <LinkZoekterm zoekterm={zoekterm} tekst="Actieclaims KwK" />
              //       </span>
              //     </li>
              //   ),
              // },
              {
                type: ELink.Link,
                path: '/opvolging/klantacties',
                weergave: (weergaveProps) => (
                  <li>
                    {/* <IconOpvolgen
                      style={{
                        width: iconSize,
                        height: iconSize,
                        fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                      }}
                    /> */}
                    <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                      <LinkZoekterm zoekterm={zoekterm} tekst="Klantacties" />
                    </span>
                  </li>
                ),
              },
              {
                type: ELink.Link,
                path: '/opvolging/verkoopvoorstellen',
                weergave: (weergaveProps) => (
                  <li>
                    {/* <IconOpvolgen
                      style={{
                        width: iconSize,
                        height: iconSize,
                        fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                      }}
                    /> */}
                    <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                      <LinkZoekterm zoekterm={zoekterm} tekst="Verkoopvoorstellen" />
                    </span>
                  </li>
                ),
              },
              {
                type: ELink.Link,
                path: '/opvolging/accounts',
                weergave: (weergaveProps) => (
                  <li>
                    {/* <IconOpvolgen
                      style={{
                        width: iconSize,
                        height: iconSize,
                        fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                      }}
                    /> */}
                    <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                      <LinkZoekterm zoekterm={zoekterm} tekst="Accounts" />
                    </span>
                  </li>
                ),
              },
              {
                type: ELink.Link,
                path: '/opvolging/feedbacks',
                weergave: (weergaveProps) => (
                  <li>
                    <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                      <LinkZoekterm zoekterm={zoekterm} tekst="Feedbacks" />
                    </span>
                  </li>
                ),
              },
            ],
          },
        ],
      },
      {
        id: GroepLinkId.Backoffice,
        type: ELink.GroepLink,
        badgeAantal: backofficeMeldingIndicatie.data ?? undefined,
        weergave: (weergaveProps) => (
          <div className="d-flex align-items-center" style={{ marginLeft: 3 }}>
            <IconOrganisatie
              style={{
                width: iconSize,
                height: iconSize,
                fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
              }}
            />
            <span
              style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs, marginLeft: 10 }}
            >
              <LinkZoekterm zoekterm={zoekterm} tekst="Backoffice" />
            </span>
          </div>
        ),
        sublinks: [
          {
            type: ELink.Link,
            path: '/backoffice/beltaken',
            badgeAantal: meldingIndicatieStore.indicaties.data?.BELTAKEN ?? undefined,
            weergave: (weergaveProps) => (
              <li>
                <IconControltaak
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Beltaken" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/escalaties',
            badgeAantal: meldingIndicatieStore.indicaties.data?.ESCALATIES ?? undefined,
            weergave: (weergaveProps) => (
              <li>
                <IconEscalator
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Escalaties" />
                </span>
              </li>
            ),
          },
          {
            id: GroepLinkId.Kwaliteitsbeheer,
            type: ELink.GroepLink,
            badgeAantal: backofficeKwaliteitsbeheerMeldingIndicatie.data ?? undefined,
            weergave: (weergaveProps) => (
              <div className="d-flex align-items-center" style={{ marginLeft: 3 }}>
                <IconKwaliteitsbeheer
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span
                  style={{
                    color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                    marginLeft: 10,
                  }}
                >
                  <LinkZoekterm zoekterm={zoekterm} tekst="Kwaliteitsbeheer" />
                </span>
              </div>
            ),
            sublinks: [
              {
                type: ELink.Link,
                path: '/reviews/voorstel',
                badgeAantal: meldingIndicatieStore.indicaties.data?.REVIEWS ?? undefined,
                weergave: (weergaveProps) => {
                  const IconDashboard = functioneleIconMap[EFunctioneleIcon.Dashboard];
                  return (
                    <li>
                      <IconReview
                        style={{
                          width: iconSize,
                          height: iconSize,
                          fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                        }}
                      />
                      <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                        <LinkZoekterm zoekterm={zoekterm} tekst="Reviews" />
                      </span>
                    </li>
                  );
                },
              },
              {
                type: ELink.Link,
                path: '/kwaliteitsbeheer/productadvies',
                weergave: (weergaveProps) => (
                  <li>
                    <IconReview
                      style={{
                        width: iconSize,
                        height: iconSize,
                        fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                      }}
                    />
                    <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                      <LinkZoekterm zoekterm={zoekterm} tekst="Productadvies" />
                    </span>
                  </li>
                ),
              },

              {
                id: GroepLinkId.Opvolging,
                type: ELink.GroepLink,
                weergave: (weergaveProps) => (
                  <div className="d-flex align-items-center" style={{ marginLeft: 3 }}>
                    <IconKwaliteitsbeheer
                      style={{
                        width: iconSize,
                        height: iconSize,
                        fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                      }}
                    />
                    <span
                      style={{
                        color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                        marginLeft: 10,
                      }}
                    >
                      <LinkZoekterm zoekterm={zoekterm} tekst="Opvolging" />
                    </span>
                  </div>
                ),
                sublinks: [
                  {
                    type: ELink.Link,
                    path: '/kwaliteitsbeheer/leveringen',
                    weergave: (weergaveProps) => (
                      <li>
                        <IconReview
                          style={{
                            width: iconSize,
                            height: iconSize,
                            fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                          }}
                        />
                        <span
                          style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}
                        >
                          <LinkZoekterm zoekterm={zoekterm} tekst="Leveringen" />
                        </span>
                      </li>
                    ),
                  },
                ],
              },
            ],
          },
          {
            id: GroepLinkId.Duplicaten,
            type: ELink.GroepLink,
            badgeAantal: backofficeDuplicatenMeldingIndicatie.data ?? undefined,
            weergave: (weergaveProps) => (
              <div className="d-flex align-items-center" style={{ marginLeft: 3 }}>
                <IconContentCopy
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span
                  style={{
                    color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                    marginLeft: 10,
                  }}
                >
                  <LinkZoekterm zoekterm={zoekterm} tekst="Duplicaten" />
                </span>
              </div>
            ),
            sublinks: [
              {
                type: ELink.Link,
                path: '/backoffice/duplicaten/relatie',
                badgeAantal: meldingIndicatieStore.indicaties.data?.DUPLICATEN_RELATIE ?? undefined,
                weergave: (weergaveProps) => (
                  <li>
                    <IconKlantkaart
                      style={{
                        width: iconSize,
                        height: iconSize,
                        fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                      }}
                    />
                    <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                      <LinkZoekterm zoekterm={zoekterm} tekst="Relatie" />
                    </span>
                  </li>
                ),
              },
              {
                type: ELink.Link,
                path: '/backoffice/duplicaten/persoon',
                badgeAantal: meldingIndicatieStore.indicaties.data?.DUPLICATEN_PERSOON ?? undefined,
                weergave: (weergaveProps) => (
                  <li>
                    <IconPersoon
                      style={{
                        width: iconSize,
                        height: iconSize,
                        fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                      }}
                    />
                    <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                      <LinkZoekterm zoekterm={zoekterm} tekst="Persoon" />
                    </span>
                  </li>
                ),
              },
            ],
          },
          {
            id: GroepLinkId.Monitoring,
            type: ELink.GroepLink,
            weergave: (weergaveProps) => (
              <div className="d-flex align-items-center" style={{ marginLeft: 3 }}>
                <IconMonitoring
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span
                  style={{
                    color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                    marginLeft: 10,
                  }}
                >
                  <LinkZoekterm zoekterm={zoekterm} tekst="Monitoring" />
                </span>
              </div>
            ),
            sublinks: [
              {
                type: ELink.Link,
                path: '/monitoring/klantmutaties',
                weergave: (weergaveProps) => (
                  <li>
                    {/* <IconKlantkaart
                      style={{
                        width: iconSize,
                        height: iconSize,
                        fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                      }}
                    /> */}
                    <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                      <LinkZoekterm zoekterm={zoekterm} tekst="Klantmutaties" />
                    </span>
                  </li>
                ),
              },
              {
                type: ELink.Link,
                path: '/monitoring/contracten',
                weergave: (weergaveProps) => (
                  <li>
                    {/* <IconKlantkaart
                      style={{
                        width: iconSize,
                        height: iconSize,
                        fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                      }}
                    /> */}
                    <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                      <LinkZoekterm zoekterm={zoekterm} tekst="Contractmutaties" />
                    </span>
                  </li>
                ),
              },
              {
                type: ELink.Link,
                path: '/monitoring/winkelwagens',
                weergave: (weergaveProps) => (
                  <li>
                    {/* <IconKlantkaart
                      style={{
                        width: iconSize,
                        height: iconSize,
                        fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                      }}
                    /> */}
                    <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                      <LinkZoekterm zoekterm={zoekterm} tekst="Winkelwagens" />
                    </span>
                  </li>
                ),
              },
              {
                type: ELink.Link,
                path: '/monitoring/contacten',
                weergave: (weergaveProps) => (
                  <li>
                    {/* <IconKlantkaart
                      style={{
                        width: iconSize,
                        height: iconSize,
                        fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                      }}
                    /> */}
                    <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                      <LinkZoekterm zoekterm={zoekterm} tekst="Contacten" />
                    </span>
                  </li>
                ),
              },
              {
                type: ELink.Link,
                path: '/monitoring/website',
                weergave: (weergaveProps) => (
                  <li>
                    {/* <IconKlantkaart
                      style={{
                        width: iconSize,
                        height: iconSize,
                        fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                      }}
                    /> */}
                    <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                      <LinkZoekterm zoekterm={zoekterm} tekst="Website" />
                    </span>
                  </li>
                ),
              },
              {
                type: ELink.Link,
                path: '/monitoring/account-logging',
                weergave: (weergaveProps) => (
                  <li>
                    {/* <IconKlantkaart
                      style={{
                        width: iconSize,
                        height: iconSize,
                        fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                      }}
                    /> */}
                    <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                      <LinkZoekterm zoekterm={zoekterm} tekst="Accounts logging" />
                    </span>
                  </li>
                ),
              },
              {
                type: ELink.Link,
                path: '/monitoring/vertegenwoordigingen',
                weergave: (weergaveProps) => (
                  <li>
                    {/* <IconKlantkaart
                      style={{
                        width: iconSize,
                        height: iconSize,
                        fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                      }}
                    /> */}
                    <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                      <LinkZoekterm zoekterm={zoekterm} tekst="Vertegenwoordigingen" />
                    </span>
                  </li>
                ),
              },
              {
                type: ELink.Link,
                path: '/monitoring/events',
                weergave: (weergaveProps) => (
                  <li>
                    {/* <IconKlantkaart
                      style={{
                        width: iconSize,
                        height: iconSize,
                        fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                      }}
                    /> */}
                    <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                      <LinkZoekterm zoekterm={zoekterm} tekst="Events" />
                    </span>
                  </li>
                ),
              },
            ],
          },
          {
            type: ELink.Link,
            path: '/takenplanner',
            weergave: (weergaveProps) => (
              <li>
                <IconSchedule
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Takenplanner" />
                </span>
              </li>
            ),
          },
        ],
      },
      {
        id: GroepLinkId.Communicatie,
        type: ELink.GroepLink,
        badgeAantal: communicatieMeldingIndicatie.data ?? undefined,
        weergave: (weergaveProps) => (
          <div className="d-flex align-items-center" style={{ marginLeft: 3 }}>
            <IconCommunicatie
              style={{
                width: iconSize,
                height: iconSize,
                fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
              }}
            />
            <span
              style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs, marginLeft: 10 }}
            >
              <LinkZoekterm zoekterm={zoekterm} tekst="Communicatie" />
            </span>
          </div>
        ),
        sublinks: [
          {
            type: ELink.Link,
            path: '/communicatie/whatsapp',
            badgeAantal: meldingIndicatieStore.indicaties.data?.WHATSAPP ?? undefined,
            weergave: (weergaveProps) => (
              <li>
                <IconWhatsapp
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="WhatsApp" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/communicatie/email',
            badgeAantal: meldingIndicatieStore.indicaties.data?.EMAIL ?? undefined,
            weergave: (weergaveProps) => (
              <li>
                <IconEmail
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Email" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/communicatie/telefoon',
            badgeAantal: meldingIndicatieStore.indicaties.data?.TELEFOON ?? undefined,
            weergave: (weergaveProps) => (
              <li>
                <IconBellen
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Telefoon" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/communicatie/sms',
            badgeAantal: meldingIndicatieStore.indicaties.data?.SMS ?? undefined,
            weergave: (weergaveProps) => (
              <li>
                <IconSMS
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="SMS" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/communicatie/contactverzoek',
            badgeAantal: meldingIndicatieStore.indicaties.data?.CONTACTVERZOEK ?? undefined,
            weergave: (weergaveProps) => (
              <li>
                <IconCommunicatie
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Contactverzoek" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/communicatie/terugbelverzoek',
            badgeAantal: meldingIndicatieStore.indicaties.data?.TERUGBELVERZOEK ?? undefined,
            weergave: (weergaveProps) => (
              <li>
                <IconUitBedrijf
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Terugbelverzoek" />
                </span>
              </li>
            ),
          },
        ],
      },
      {
        type: ELink.Link,
        path: '/transport',
        weergave: (weergaveProps) => (
          <li>
            <IconTransport
              style={{
                width: iconSize,
                height: iconSize,
                fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
              }}
            />
            <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
              <LinkZoekterm zoekterm={zoekterm} tekst="Transport" />
            </span>
          </li>
        ),
      },
      {
        type: ELink.Link,
        path: '/service',
        weergave: (weergaveProps) => (
          <li>
            <IconService
              style={{
                width: iconSize,
                height: iconSize,
                fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
              }}
            />
            <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
              <LinkZoekterm zoekterm={zoekterm} tekst="Service" />
            </span>
          </li>
        ),
      },
      {
        type: ELink.Link,
        path: '/voorraad',
        weergave: (weergaveProps) => {
          const Icon = functioneleIconMap[EFunctioneleIcon.Voorraad];
          return (
            <li>
              <Icon
                style={{
                  width: iconSize,
                  height: iconSize,
                  fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                }}
              />
              <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                <LinkZoekterm zoekterm={zoekterm} tekst="Voorraad" />
              </span>
            </li>
          );
        },
      },
      {
        id: GroepLinkId.Magazijn,
        type: ELink.GroepLink,
        weergave: (weergaveProps) => (
          <div className="d-flex align-items-center" style={{ marginLeft: 3 }}>
            <IconMagazijn
              style={{
                width: iconSize,
                height: iconSize,
                fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
              }}
            />
            <span
              style={{
                color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                marginLeft: 10,
              }}
            >
              <LinkZoekterm zoekterm={zoekterm} tekst="Magazijn" />
            </span>
          </div>
        ),
        sublinks: [
          {
            type: ELink.Link,
            path: '/magazijn/depot',
            weergave: (weergaveProps) => (
              <li>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Depotbeheer" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/magazijn/pendelopdrachten',
            weergave: (weergaveProps) => (
              <li>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Pendelopdrachten" />
                </span>
              </li>
            ),
          },
          // {
          //   type: ELink.Link,
          //   path: '/magazijn/afhaalopdrachten',
          //   weergave: (weergaveProps) => (
          //     <li>
          //       <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
          //         <LinkZoekterm zoekterm={zoekterm} tekst="Afhaalopdrachten" />
          //       </span>
          //     </li>
          //   ),
          // },
          {
            type: ELink.Link,
            path: '/magazijn/inventarisatie',
            weergave: (weergaveProps) => (
              <li>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Inventarisatie" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/magazijn/mutaties',
            weergave: (weergaveProps) => (
              <li>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Mutaties" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/magazijn/beheer',
            weergave: (weergaveProps) => (
              <li>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Beheer" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/magazijn/magazijndiensten',
            weergave: (weergaveProps) => (
              <li>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Diensten" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/magazijn/pendeldiensten',
            weergave: (weergaveProps) => (
              <li>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Pendeldiensten" />
                </span>
              </li>
            ),
          },
        ],
      },
      {
        id: GroepLinkId.Inkoop,
        type: ELink.GroepLink,
        weergave: (weergaveProps) => (
          <div className="d-flex align-items-center" style={{ marginLeft: 3 }}>
            <IconInkoop
              style={{
                width: iconSize,
                height: iconSize,
                fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
              }}
            />
            <span
              style={{
                color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                marginLeft: 10,
              }}
            >
              <LinkZoekterm zoekterm={zoekterm} tekst="Inkoop" />
            </span>
          </div>
        ),
        sublinks: [
          {
            type: ELink.Link,
            path: '/inkoop/opdrachten',
            weergave: (weergaveProps) => (
              <li>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Opdrachten" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/inkoop/retouren',
            weergave: (weergaveProps) => (
              <li>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Retouren" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/inkoop/voorraad',
            weergave: (weergaveProps) => (
              <li>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Voorraad" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/inkoop/aanbod',
            weergave: (weergaveProps) => (
              <li>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Aanbod leveranciers" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/inkoop/diensten',
            weergave: (weergaveProps) => (
              <li>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Diensten" />
                </span>
              </li>
            ),
          },
        ],
      },
      {
        type: ELink.GroepLink,
        id: GroepLinkId.Aanbod,
        weergave: (weergaveProps) => (
          <div className="d-flex align-items-center" style={{ marginLeft: 3 }}>
            <IconAanbod
              style={{
                width: iconSize,
                height: iconSize,
                fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
              }}
            />
            <span
              style={{
                color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                marginLeft: 10,
              }}
            >
              <LinkZoekterm zoekterm={zoekterm} tekst="Aanbod" />
            </span>
          </div>
        ),
        sublinks: [
          {
            type: ELink.Link,
            path: '/aanbod/tarieven',
            weergave: (weergaveProps) => (
              <li>
                <IconEuro
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Tarieven" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/aanbod/producten',
            weergave: (weergaveProps) => (
              <li>
                <IconWasmachine
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Producten" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/aanbod/specificaties',
            weergave: (weergaveProps) => (
              <li>
                <IconSettings
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Specificaties" />
                </span>
              </li>
            ),
          },
        ],
      },
      {
        type: ELink.GroepLink,
        id: GroepLinkId.Financieel,
        weergave: (weergaveProps) => (
          <div className="d-flex align-items-center" style={{ marginLeft: 3 }}>
            <IconValutateken
              style={{
                width: iconSize,
                height: iconSize,
                fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
              }}
            />
            <span
              style={{
                color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                marginLeft: 10,
              }}
            >
              <LinkZoekterm zoekterm={zoekterm} tekst="Financieel" />
            </span>
          </div>
        ),
        sublinks: [
          {
            type: ELink.Link,
            path: '/facturering',
            weergave: (weergaveProps) => (
              <li>
                <IconFactuur
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Facturering" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/debiteuren',
            weergave: (weergaveProps) => (
              <li>
                <IconCreditcard
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Debiteuren" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/crediteuren',
            weergave: (weergaveProps) => (
              <li>
                <IconCreditcard
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Crediteuren" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/bankzaken',
            weergave: (weergaveProps) => (
              <li>
                <IconEuro
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Bankzaken" />
                </span>
              </li>
            ),
            heeftAutorisatie: () =>
              heeftAutorisatie({
                isAdmin: autorisatieStore.isAdmin!,
                gebruikersPermissies: autorisatieStore.permissies!,
                vereistePermissies: [EPermissie.FinancieelBanzakenWeergeven],
              }).isToegestaan,
          },
          {
            type: ELink.Link,
            path: '/boekhouding',
            weergave: (weergaveProps) => (
              <li>
                <IconBoekhouding
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Boekhouding" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/indexering',
            weergave: (weergaveProps) => (
              <li>
                <IconFactuur
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Indexering" />
                </span>
              </li>
            ),
          },
        ],
      },
      {
        type: ELink.GroepLink,
        id: GroepLinkId.Marketing,
        weergave: (weergaveProps) => (
          <div className="d-flex align-items-center" style={{ marginLeft: 3 }}>
            <IconMarketing
              style={{
                width: iconSize,
                height: iconSize,
                fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
              }}
            />
            <span
              style={{
                color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                marginLeft: 10,
              }}
            >
              <LinkZoekterm zoekterm={zoekterm} tekst="Marketing" />
            </span>
          </div>
        ),
        sublinks: [
          {
            type: ELink.Link,
            path: '/sponsoring',
            weergave: (weergaveProps) => (
              <li>
                <IconSponsoring
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Sponsoring" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/sponsoringv2',
            weergave: (weergaveProps) => (
              <li>
                <IconSponsoring
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Sponsoring" /> V2
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/bedrijfsinfo',
            weergave: (weergaveProps) => (
              <li>
                <IconTrend
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Bedrijfsinformatie" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/concurrentie',
            weergave: (weergaveProps) => (
              <li>
                <IconTrend
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Concurrentie" />
                </span>
              </li>
            ),
          },
        ],
      },
      {
        type: ELink.GroepLink,
        id: GroepLinkId.Website,
        weergave: (weergaveProps) => (
          <div className="d-flex align-items-center" style={{ marginLeft: 3 }}>
            <IconWebsite
              style={{
                width: iconSize,
                height: iconSize,
                fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
              }}
            />
            <span
              style={{
                color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                marginLeft: 10,
              }}
            >
              <LinkZoekterm zoekterm={zoekterm} tekst="Website" />
            </span>
          </div>
        ),
        sublinks: [
          {
            type: ELink.Link,
            path: '/website/webteksten',
            weergave: (weergaveProps) => (
              <li>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Webteksten" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/website/website-afbeeldingen',
            weergave: (weergaveProps) => (
              <li>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Afbeeldingen" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/website/webpagina',
            weergave: (weergaveProps) => (
              <li>
                {/* <IconLink
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                /> */}
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Pagina's" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/website/webreroutes',
            weergave: (weergaveProps) => (
              <li>
                {/* <IconLink
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                /> */}
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Reroutes" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/website/faq',
            weergave: (weergaveProps) => (
              <li>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Faq" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/website/enquete',
            weergave: (weergaveProps) => (
              <li>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Enquete" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/website/blog',
            weergave: (weergaveProps) => (
              <li>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Blog" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/website/themas',
            weergave: (weergaveProps) => (
              <li>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Themas" />
                </span>
              </li>
            ),
          },
        ],
      },
      // {
      //   type: ELink.GroepLink,
      //   id: GroepLinkId.Kwaliteitsbeheer,
      //   weergave: (weergaveProps) => (
      //     <div className="d-flex align-items-center" style={{ marginLeft: 0 }}>
      //       <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
      //         <LinkZoekterm zoekterm={zoekterm} tekst="Kwaliteitsbeheer" />
      //       </span>
      //     </div>
      //   ),
      //   sublinks: [
      //     {
      //       type: ELink.Link,
      //       path: '/kwaliteitsbeheer/proces',
      //       weergave: (weergaveProps) => (
      //         <li>
      //           <IconReview
      //             style={{
      //               width: iconSize,
      //               height: iconSize,
      //               fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
      //             }}
      //           />
      //           <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
      //             <LinkZoekterm zoekterm={zoekterm} tekst="Processen" />
      //           </span>
      //         </li>
      //       ),
      //     },
      //     {
      //       type: ELink.Link,
      //       path: '/kwaliteitsbeheer/productadvies',
      //       weergave: (weergaveProps) => (
      //         <li>
      //           <IconReview
      //             style={{
      //               width: iconSize,
      //               height: iconSize,
      //               fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
      //             }}
      //           />
      //           <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
      //             <LinkZoekterm zoekterm={zoekterm} tekst="Productadvies" />
      //           </span>
      //         </li>
      //       ),
      //     },
      //     {
      //       type: ELink.Link,
      //       path: '/kwaliteitsbeheer/service',
      //       weergave: (weergaveProps) => (
      //         <li>
      //           <IconReview
      //             style={{
      //               width: iconSize,
      //               height: iconSize,
      //               fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
      //             }}
      //           />
      //           <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
      //             <LinkZoekterm zoekterm={zoekterm} tekst="Service" />
      //           </span>
      //         </li>
      //       ),
      //     },
      //     {
      //       type: ELink.Link,
      //       path: '/kwaliteitsbeheer/levensduur',
      //       weergave: (weergaveProps) => (
      //         <li>
      //           <IconReview
      //             style={{
      //               width: iconSize,
      //               height: iconSize,
      //               fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
      //             }}
      //           />
      //           <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
      //             <LinkZoekterm zoekterm={zoekterm} tekst="Levensduur" />
      //           </span>
      //         </li>
      //       ),
      //     },
      //   ],
      // },
      {
        type: ELink.GroepLink,
        id: GroepLinkId.Facilitair,
        weergave: (weergaveProps) => (
          <div className="d-flex align-items-center" style={{ marginLeft: 3 }}>
            <IconFacilitair
              style={{
                width: iconSize,
                height: iconSize,
                fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
              }}
            />
            <span
              style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs, marginLeft: 10 }}
            >
              <LinkZoekterm zoekterm={zoekterm} tekst="Facilitair" />
            </span>
          </div>
        ),
        sublinks: [
          {
            type: ELink.Link,
            path: '/facilitair/sjablonen',
            weergave: (weergaveProps) => (
              <li>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Sjablonen" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/facilitair/documentatie',
            weergave: (weergaveProps) => (
              <li>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Documentatie" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/facilitair/import-export',
            weergave: (weergaveProps) => (
              <li>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Import & Export" />
                </span>
              </li>
            ),
            heeftAutorisatie: () =>
              heeftAutorisatie({
                isAdmin: autorisatieStore.isAdmin!,
                gebruikersPermissies: autorisatieStore.permissies!,
                vereistePermissies: [EPermissie.FacilitairImportExportWeergeven],
              }).isToegestaan,
          },
          {
            type: ELink.Link,
            path: '/stambeheer/feestdagen',
            weergave: (weergaveProps) => (
              <li>
                {/* <IconVlag
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                /> */}
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Feestdagen" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/stambeheer/telefoonoproep-contexten',
            weergave: (weergaveProps) => (
              <li>
                {/* <IconVlag
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                /> */}
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Telefoonoproepcontexten" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/facilitair/bulkberichten',
            weergave: (weergaveProps) => (
              <li>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Bulkberichten" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/facilitair/bulkverzender',
            weergave: (weergaveProps) => (
              <li>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Bulkverzender" />
                </span>
              </li>
            ),
          },
        ],
      },
      // {
      //   type: ELink.GroepLink,
      //   id: GroepLinkId.Stambeheer,
      //   weergave: (weergaveProps) => (
      //     <div className="d-flex align-items-center" style={{ marginLeft: 0 }}>
      //       <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
      //         <LinkZoekterm zoekterm={zoekterm} tekst="Stambeheer" />
      //       </span>
      //     </div>
      //   ),
      //   sublinks: [
      //     {
      //       type: ELink.Link,
      //       path: '/stambeheer/feestdagen',
      //       weergave: (weergaveProps) => (
      //         <li>
      //           <IconVlag
      //             style={{
      //               width: iconSize,
      //               height: iconSize,
      //               fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
      //             }}
      //           />
      //           <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
      //             <LinkZoekterm zoekterm={zoekterm} tekst="Feestdagen" />
      //           </span>
      //         </li>
      //       ),
      //     },
      //   ],
      // },
      {
        type: ELink.GroepLink,
        id: GroepLinkId.Administratie,
        weergave: (weergaveProps) => (
          <div className="d-flex align-items-center" style={{ marginLeft: 3 }}>
            <IconAdministratie
              style={{
                width: iconSize,
                height: iconSize,
                fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
              }}
            />
            <span
              style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs, marginLeft: 10 }}
            >
              <LinkZoekterm zoekterm={zoekterm} tekst="Administratie" />
            </span>
          </div>
        ),
        sublinks: [
          {
            type: ELink.Link,
            path: '/medewerkers',
            weergave: (weergaveProps) => (
              <li>
                <IconReview
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                />
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Medewerkers" />
                </span>
              </li>
            ),
            heeftAutorisatie: () =>
              heeftAutorisatie({
                isAdmin: autorisatieStore.isAdmin!,
                gebruikersPermissies: autorisatieStore.permissies!,
                vereistePermissies: [EPermissie.AdministratieMedewerkersWeergeven],
              }).isToegestaan,
          },
          {
            type: ELink.GroepLink,
            id: GroepLinkId.AdministratieVerlof,
            weergave: (weergaveProps) => (
              <div className="d-flex align-items-center" style={{ marginLeft: 0 }}>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Verlof" />
                </span>
              </div>
            ),
            sublinks: [
              {
                type: ELink.Link,
                path: '/verlof/overzicht',
                weergave: (weergaveProps) => (
                  <li>
                    <IconReview
                      style={{
                        width: iconSize,
                        height: iconSize,
                        fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                      }}
                    />
                    <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                      <LinkZoekterm zoekterm={zoekterm} tekst="Overzicht" />
                    </span>
                  </li>
                ),
              },
              {
                type: ELink.Link,
                path: '/verlof/registratie',
                weergave: (weergaveProps) => (
                  <li>
                    <IconReview
                      style={{
                        width: iconSize,
                        height: iconSize,
                        fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                      }}
                    />
                    <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                      <LinkZoekterm zoekterm={zoekterm} tekst="Registratie" />
                    </span>
                  </li>
                ),
                heeftAutorisatie: () =>
                  heeftAutorisatie({
                    isAdmin: autorisatieStore.isAdmin!,
                    gebruikersPermissies: autorisatieStore.permissies!,
                    vereistePermissies: [EPermissie.AdministratieVerlofRegistratieWeergeven],
                  }).isToegestaan,
              },
            ],
          },
          // {
          //   type: ELink.Link,
          //   path: '/bedrijfsgegevens',
          //   weergave: (weergaveProps) => (
          //     <li>
          //       <IconReview
          //         style={{
          //           width: iconSize,
          //           height: iconSize,
          //           fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
          //         }}
          //       />
          //       <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
          //         <LinkZoekterm zoekterm={zoekterm} tekst="Bedrijfsgegevens" />
          //       </span>
          //     </li>
          //   ),
          // },
        ],
      },
      {
        type: ELink.GroepLink,
        id: GroepLinkId.Beheer,
        weergave: (weergaveProps) => (
          <div className="d-flex align-items-center" style={{ marginLeft: 3 }}>
            <IconSettings
              style={{
                width: iconSize,
                height: iconSize,
                fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
              }}
            />
            <span
              style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs, marginLeft: 10 }}
            >
              <LinkZoekterm zoekterm={zoekterm} tekst="Beheer" />
            </span>
          </div>
        ),
        sublinks: [
          {
            type: ELink.Link,
            path: '/beheer/instellingen',
            weergave: (weergaveProps) => (
              <li>
                {/* <IconSettings
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                /> */}
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Instellingen" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/beheer/gebruikers',
            weergave: (weergaveProps) => (
              <li>
                {/* <IconSettings
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                /> */}
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Gebruikers" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/beheer/sepalanden',
            weergave: (weergaveProps) => (
              <li>
                {/* <IconSettings
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                /> */}
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="SEPA Landen" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.Link,
            path: '/beheer/rollen-en-permissies',
            weergave: (weergaveProps) => (
              <li>
                {/* <IconSettings
                  style={{
                    width: iconSize,
                    height: iconSize,
                    fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                  }}
                /> */}
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Rollen & Permissies" />
                </span>
              </li>
            ),
          },
          {
            type: ELink.GroepLink,
            id: GroepLinkId.Technisch,
            weergave: (weergaveProps) => (
              <div className="d-flex align-items-center" style={{ marginLeft: 0 }}>
                <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                  <LinkZoekterm zoekterm={zoekterm} tekst="Technisch" />
                </span>
              </div>
            ),
            sublinks: [
              {
                type: ELink.Link,
                path: '/beheer/technisch/logs',
                weergave: (weergaveProps) => (
                  <li>
                    <EyeIcon
                      style={{
                        width: iconSize,
                        height: iconSize,
                        fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                      }}
                    />
                    <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                      <LinkZoekterm zoekterm={zoekterm} tekst="Logs" />
                    </span>
                  </li>
                ),
              },
              {
                type: ELink.Link,
                path: '/beheer/technisch/signaleringstypen',
                weergave: (weergaveProps) => (
                  <li>
                    <EyeIcon
                      style={{
                        width: iconSize,
                        height: iconSize,
                        fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                      }}
                    />
                    <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                      <LinkZoekterm zoekterm={zoekterm} tekst="Signaleringstypen" />
                    </span>
                  </li>
                ),
              },
              {
                type: ELink.Link,
                path: '/beheer/technisch/eventtypen',
                weergave: (weergaveProps) => (
                  <li>
                    <EyeIcon
                      style={{
                        width: iconSize,
                        height: iconSize,
                        fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
                      }}
                    />
                    <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
                      <LinkZoekterm zoekterm={zoekterm} tekst="Eventtypen" />
                    </span>
                  </li>
                ),
              },
            ],
          },
        ],
      },
      {
        type: ELink.Link,
        path: '/asp-drive',
        weergave: (weergaveProps) => (
          <li>
            <IconCloud
              style={{
                width: iconSize,
                height: iconSize,
                fill: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs,
              }}
            />
            <span style={{ color: weergaveProps.actief ? Kleur.Wit : Kleur.LichtGrijs }}>
              <LinkZoekterm zoekterm={zoekterm} tekst="Drive" />
            </span>
          </li>
        ),
      },
    ];
  }, [autorisatieStore.permissies, meldingIndicatieStore.indicaties, zoekterm]);

  const linksFilterData = useMemo<Array<{ path: string; keywords: string[] }>>(
    () => [
      { path: '/dashboard', keywords: ['dashboard'] },
      { path: '/backoffice/duplicaten/relatie', keywords: ['relatie', 'duplicaat'] },
      { path: '/backoffice/duplicaten/persoon', keywords: ['persoon', 'duplicaat'] },
      { path: '/communicatie/whatsapp', keywords: ['whatsapp'] },
      { path: '/communicatie/email', keywords: ['email'] },
      { path: '/communicatie/telefoon', keywords: ['telefoon'] },
      { path: '/communicatie/sms', keywords: ['sms'] },
      { path: '/communicatie/contactverzoek', keywords: ['contactverzoek'] },
      { path: '/taken', keywords: ['taken', 'taak'] },
      { path: '/externe-data', keywords: ['externe data'] },
      { path: '/opvolging', keywords: ['opvolging'] },
      { path: '/monitoring', keywords: ['monitoring'] },
      { path: '/kwaliteitsbeheer', keywords: ['kwaliteitsbeheer'] },
      { path: '/takenplanner', keywords: ['taak', 'takenplanner'] },
      { path: '/klant', keywords: ['klant'] },
      { path: '/leverancier', keywords: ['leverancier'] },
      { path: '/service', keywords: ['service'] },
      { path: '/transport', keywords: ['transport'] },
      { path: '/voorraad', keywords: ['voorraad'] },
      { path: '/asp-drive', keywords: ['drive', 'aspdrive', 'asp-drive'] },
      { path: '/inkoop/opdrachten', keywords: ['opdrachten', 'inkoop'] },
      { path: '/inkoop/voorraad', keywords: ['voorraad'] },
      { path: '/inkoop/aanbod', keywords: ['aanbod'] },
      { path: '/inkoop/diensten', keywords: ['diensten'] },
      { path: '/magazijn/beheer', keywords: ['beheer', 'magazijn'] },
      { path: '/magazijn/pendelopdrachten', keywords: ['pendelopdrachten', 'pendel'] },
      { path: '/magazijn/afhaalopdrachten', keywords: ['afhaalopdrachten', 'afhaal'] },
      { path: '/magazijn/inventarisatie', keywords: ['inventarisatie'] },
      { path: '/magazijn/mutaties', keywords: ['mutaties'] },
      { path: '/magazijn/magazijndiensten', keywords: ['magazijndiensten'] },
      { path: '/magazijn/pendeldiensten', keywords: ['pendeldiensten', 'pendel'] },
      { path: '/aanbod/tarieven', keywords: ['tarieven', 'tarief'] },
      { path: '/aanbod/producten', keywords: ['producten', 'aanbod'] },
      { path: '/aanbod/specificaties', keywords: ['specificaties', 'aanbod'] },
      { path: '/facturering', keywords: ['facturering', 'factuur'] },
      {
        path: '/debiteuren',
        keywords: [
          'debiteuren',
          'verrekeningen',
          'aanmaningen',
          'betalingsregelingen',
          'incassozaken',
          'herincassozaken',
          'rechtzaken',
          'incassodiensten',
        ],
      },
      { path: '/crediteuren', keywords: ['crediteuren', 'inkoop', 'koppelen', 'werkbon'] },
      {
        path: '/bankzaken',
        keywords: ['bankzaken', 'batch', 'stornering', 'storno', 'specificaties'],
      },
      { path: '/boekhouding', keywords: ['boekhouding', 'export', 'memo', 'boeking', 'controle'] },
      { path: '/sponsoring', keywords: ['sponsoring'] },
      { path: '/sponsoringv2', keywords: ['sponsoring'] },
      { path: '/bedrijfsinfo', keywords: ['bedrijfsinfo'] },
      { path: '/facilitair/sjablonen', keywords: ['sjablonen', 'sjabloon'] },
      {
        path: '/facilitair/documentatie',
        keywords: ['documentatie', 'gebruiksaanwijzing', 'handleiding'],
      },
      {
        path: '/website/webteksten',
        keywords: ['tekst', 'website', 'webteksten'],
      },
      {
        path: '/website/faq',
        keywords: ['tekst', 'website', 'faq'],
      },
      {
        path: '/website/blog',
        keywords: ['tekst', 'website', 'blog'],
      },
      {
        path: '/website/themas',
        keywords: ['website', 'themas'],
      },
      {
        path: '/website/website-afbeeldingen',
        keywords: ['website afbeeldingen', 'afbeelding'],
      },
      { path: '/facilitair/import-export', keywords: ['import-export'] },
      {
        path: '/medewerkers',
        keywords: ['personeelszaken', 'medewerker'],
      },
      {
        path: '/verlof/overzicht',
        keywords: ['verlof', 'vakantie'],
      },
      {
        path: '/verlof/registratie',
        keywords: ['verlof', 'vakantie'],
      },
      { path: '/bedrijfsgegevens', keywords: ['bedrijfsgegevens'] },
      { path: '/beheer/instellingen', keywords: ['instellingen'] },
      { path: '/beheer/gebruikers', keywords: ['gebruikers'] },
      { path: '/beheer/rollen-en-permissies', keywords: ['rollen', 'permissies'] },
      { path: '/beheer/technisch/logs', keywords: ['logs'] },
      { path: '/beheer/technisch/eventtypen', keywords: ['eventtypen', 'events'] },
      { path: '/beheer/technisch/webpagina', keywords: ['webpagina'] },
    ],
    [],
  );
  const groeplinkFilterData = useMemo<Record<GroepLinkId, string[]>>(
    () => ({
      [GroepLinkId.Frontoffice]: ['control'],
      [GroepLinkId.Backoffice]: ['backoffice'],
      [GroepLinkId.Duplicaten]: ['duplicaten'],
      [GroepLinkId.Financieel]: ['financieel'],
      [GroepLinkId.Marketing]: ['marketing'],
      [GroepLinkId.MeerFinancieel]: ['meer', 'financieel'],
      [GroepLinkId.Magazijn]: ['magazijn'],
      [GroepLinkId.Website]: ['website'],
      [GroepLinkId.Administratie]: ['administratie'],
      [GroepLinkId.AdministratieVerlof]: ['verlof'],
      [GroepLinkId.Inkoop]: ['inkoop'],
      [GroepLinkId.Communicatie]: ['communicatie'],
      [GroepLinkId.Facilitair]: ['facilitair'],
      [GroepLinkId.Aanbod]: ['aanbod'],
      [GroepLinkId.Beheer]: ['beheer'],
      [GroepLinkId.Technisch]: ['technisch'],
      [GroepLinkId.Opvolging]: ['opvolging'],
      [GroepLinkId.Monitoring]: ['monitoring'],
      [GroepLinkId.Stambeheer]: ['stambeheer'],
      [GroepLinkId.Kwaliteitsbeheer]: ['kwaliteitsbeheer'],
    }),
    [],
  );

  const gefilterdeLinks = useMemo<Link<GroepLinkId>[]>(() => {
    const lcZoekterm = zoekterm.toLowerCase();

    const linkTest = (l: ILink): boolean => {
      const lfd = linksFilterData.find((lfd) => lfd.path === l.path);
      if (lfd === undefined) {
        return true;
      }
      return lfd.keywords.some((keyword) => keyword.includes(lcZoekterm));
    };
    const groepLinkTest = (gl: IGroepLink<GroepLinkId>): { goed: boolean; matches: boolean } => {
      const gld = groeplinkFilterData[gl.id];
      const matches = gld.some((keyword) => keyword.includes(lcZoekterm));
      if (matches) {
        return {
          goed: true,
          matches,
        };
      }

      return {
        goed: gl.sublinks.some((sl) => {
          switch (sl.type) {
            case ELink.Link: {
              return linkTest(sl);
            }
            case ELink.GroepLink: {
              return groepLinkTest(sl).goed;
            }
          }
        }),
        matches,
      };
    };

    const r = (l: Link<GroepLinkId>): Link<GroepLinkId> | null => {
      switch (l.type) {
        case ELink.Link: {
          return linkTest(l) ? l : null;
        }
        case ELink.GroepLink: {
          const { goed, matches } = groepLinkTest(l);
          if (!goed) {
            return null;
          }
          const sublinks = matches
            ? l.sublinks
            : (l.sublinks.map((sl) => r(sl)).filter((sl) => sl !== null) as Link<GroepLinkId>[]);
          return {
            ...l,
            sublinks,
          };
        }
      }
    };

    return links.map(r).filter((x) => x !== null) as Link<GroepLinkId>[];
  }, [links, zoekterm, linksFilterData, groeplinkFilterData]);

  const toegepasteUitgeklapteGroepLinks = useMemo<Partial<Record<GroepLinkId, boolean>>>(() => {
    if (zoekterm.length === 0) {
      return uitgeklapteGroepLinks;
    }
    return {
      [GroepLinkId.Frontoffice]: true,
      [GroepLinkId.Backoffice]: true,
      [GroepLinkId.Duplicaten]: true,
      [GroepLinkId.Beheer]: true,
      [GroepLinkId.Financieel]: true,
      [GroepLinkId.Marketing]: true,
      [GroepLinkId.MeerFinancieel]: true,
      [GroepLinkId.Magazijn]: true,
      [GroepLinkId.Website]: true,
      [GroepLinkId.Administratie]: true,
      [GroepLinkId.AdministratieVerlof]: true,
      [GroepLinkId.Inkoop]: true,
      [GroepLinkId.Communicatie]: true,
      [GroepLinkId.Facilitair]: true,
      [GroepLinkId.Aanbod]: true,
      [GroepLinkId.Technisch]: true,
      [GroepLinkId.Kwaliteitsbeheer]: true,
      [GroepLinkId.Opvolging]: true,
      [GroepLinkId.Monitoring]: true,
    };
  }, [zoekterm, uitgeklapteGroepLinks]);

  const toegepasteOnUitgeklapteGroepLinksChange = useMemo<
    ((uitgeklapteGroepLinks: Partial<Record<GroepLinkId, boolean>>) => void) | null
  >(() => {
    if (zoekterm.length === 0) {
      return setUitgeklapteGroepLinks;
    }
    return null;
  }, [zoekterm, setUitgeklapteGroepLinks]);

  return (
    <Navigatie>
      <NavigatieHeader />

      {/*<CommunicatieOverlayActies />*/}
      <div className="pl-2 pr-2 pt-1 pb-2">
        <GlobaalZoekveld input={zoekterm} onInputChange={setZoekterm} />
      </div>
      {/*<HorizontaleScheidingslijn color={Kleur.Paars} />*/}
      <VerticaleNavigatieBalk
        links={gefilterdeLinks}
        uitgeklapteGroepLinks={toegepasteUitgeklapteGroepLinks}
        onUitgeklapteGroepLinksChange={toegepasteOnUitgeklapteGroepLinksChange}
        actieveLinkBackgroundColor="rgba(76, 114, 255, 0.76)"
        menuBalkIconKleur={Kleur.Wit}
      />
      <NavigatieFooter>
        <ToestelSectie />
        <div className="w-100">
          <HorizontaleScheidingslijn color="rgba(136, 136, 136, 0.2)" />
        </div>
        <CommunicatieSectie />
        <div className="mb-2 w-100">
          <HorizontaleScheidingslijn color="rgba(136, 136, 136, 0.2)" />
        </div>
        <GebruikerSectie />

        <div className="mt-2 w-100">{/*<HorizontaleScheidingslijn color={Kleur.Grijs} />*/}</div>

        <div className="hoverable w-100">
          <RealtimeStatus />
        </div>
      </NavigatieFooter>
    </Navigatie>
  );
});

export default withRouter(PrimaireNavigatie);
