import React from 'react';
import { IFormikValues, veldnamen } from '../index';
import { Field, FieldProps } from 'formik';
import nameOf from '../../../../../../core/nameOf';
import MeertaligTekstveld from '../../../../../../components/formulier/MeertaligTekstveld';

const TaalafhankelijkTab = () => {
  return (
    <div className="form-group p-3">
      <div className="row">
        <div className="col-12">
          <label>{veldnamen.naamEnkelvoud}</label>
          <Field
            name={nameOf<IFormikValues>('naamEnkelvoud')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              return (
                <MeertaligTekstveld
                  waarden={fieldProps.field.value}
                  onChange={(x) => fieldProps.form.setFieldValue(fieldProps.field.name, x)}
                />
              );
            }}
          />
        </div>

        <div className="col-12 mt-2">
          <label>{veldnamen.naamMeervoud}</label>
          <Field
            name={nameOf<IFormikValues>('naamMeervoud')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              return (
                <MeertaligTekstveld
                  waarden={fieldProps.field.value}
                  onChange={(x) => fieldProps.form.setFieldValue(fieldProps.field.name, x)}
                />
              );
            }}
          />
        </div>

        <div className="col-12 mt-2">
          <label>{veldnamen.slug}</label>
          <Field
            name={nameOf<IFormikValues>('slug')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              return (
                <MeertaligTekstveld
                  waarden={fieldProps.field.value}
                  onChange={(x) => fieldProps.form.setFieldValue(fieldProps.field.name, x)}
                />
              );
            }}
          />
        </div>

        <div className="col-12 mt-2">
          <label>{veldnamen.overzichtOmschrijving}</label>
          <Field
            name={nameOf<IFormikValues>('overzichtOmschrijving')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              return (
                <MeertaligTekstveld
                  waarden={fieldProps.field.value}
                  onChange={(x) => fieldProps.form.setFieldValue(fieldProps.field.name, x)}
                  inputComponent="multiline"
                  multilineConfig={{
                    rows: 3,
                  }}
                />
              );
            }}
          />
        </div>

        <div className="col-12 mt-2">
          <label>{veldnamen.toelichting1}</label>
          <Field
            name={nameOf<IFormikValues>('toelichting1')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              return (
                <MeertaligTekstveld
                  waarden={fieldProps.field.value}
                  onChange={(x) => fieldProps.form.setFieldValue(fieldProps.field.name, x)}
                  inputComponent="multiline"
                  multilineConfig={{
                    rows: 3,
                  }}
                />
              );
            }}
          />
        </div>

        <div className="col-12 mt-2">
          <label>{veldnamen.toelichting2}</label>
          <Field
            name={nameOf<IFormikValues>('toelichting2')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              return (
                <MeertaligTekstveld
                  waarden={fieldProps.field.value}
                  onChange={(x) => fieldProps.form.setFieldValue(fieldProps.field.name, x)}
                  inputComponent="multiline"
                  multilineConfig={{
                    rows: 3,
                  }}
                />
              );
            }}
          />
        </div>

        <div className="col-12 mt-2">
          <label>{veldnamen.toelichting3}</label>
          <Field
            name={nameOf<IFormikValues>('toelichting3')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              return (
                <MeertaligTekstveld
                  waarden={fieldProps.field.value}
                  onChange={(x) => fieldProps.form.setFieldValue(fieldProps.field.name, x)}
                  inputComponent="multiline"
                  multilineConfig={{
                    rows: 3,
                  }}
                />
              );
            }}
          />
        </div>

        <div className="col-12 mt-2">
          <label>{veldnamen.paginatitel}</label>
          <Field
            name={nameOf<IFormikValues>('paginatitel')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              return (
                <MeertaligTekstveld
                  waarden={fieldProps.field.value}
                  onChange={(x) => fieldProps.form.setFieldValue(fieldProps.field.name, x)}
                />
              );
            }}
          />
        </div>

        <div className="col-12 mt-2">
          <label>{veldnamen.metadata}</label>
          <Field
            name={nameOf<IFormikValues>('metadata')}
            render={(fieldProps: FieldProps<IFormikValues>) => {
              return (
                <MeertaligTekstveld
                  waarden={fieldProps.field.value}
                  onChange={(x) => fieldProps.form.setFieldValue(fieldProps.field.name, x)}
                  inputComponent="multiline"
                  multilineConfig={{
                    rows: 3,
                  }}
                />
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TaalafhankelijkTab;
