import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { IWinweekWinnaar } from '../../../../../../shared/src/api/v2/klantactie/winweek';
import { IFilterSchema } from '../../../../../../shared/src/models/filter';
import api from '../../../../api';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../../../components/FilterBalkV2';
import { IconSend, IconToevoegen, IconVerwijderen } from '../../../../components/Icons';
import MenuLayout from '../../../../components/MenuLayout';
import RelatieVisualisatie from '../../../../components/personalia/RelatieVisualisatie';
import ASPTabel from '../../../../components/tabel/ASPTabel';
import TableData, { ITableDataProps } from '../../../../components/tabel/ASPTabel/Body/TableData';
import { ASPKolom, EAspKolomBreedteType } from '../../../../components/tabel/ASPTabel/types';
import useUrlState from '../../../../core/useUrlState';
import { EResultType } from '../../../../stores/CheckStore';
import { RootStoreContext } from '../../../../stores/RootStore';
import MuterenDialoog from '../MuterenDialoog';
import { format } from 'date-fns';

type Kolom =
  | 'RelID'
  | 'Weeknummer'
  | 'Jaar'
  | 'Adres'
  | 'Publicatiedatum'
  | 'StatusOk'
  | 'Foto_BestandID'
  | 'VorderingGemaaktOp';

export enum EFilter {
  StatusOk = 'OK',
}

interface IProps extends RouteComponentProps {}

interface IMuterenDialoogState {
  id: number | null;
}

interface IUrlState {
  selectie: number[];
  filterData: IFilterData<EFilter>[];
  muterenDialoogState: IMuterenDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterData: [],
  muterenDialoogState: null,
};

const Winnaars: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [rijen, setRijen] = useState<IWinweekWinnaar[] | null>(null);

  const ophalenRijen = async () => {
    let results: IWinweekWinnaar[] = await api.v2.klantactie.winweek.ophalenWinnaars({});
    setRijen(results);
  };

  useEffect(() => {
    ophalenRijen();
  }, []);

  const handleToevoegen = useCallback(async () => {
    setUrlStateSync('muterenDialoogState', { id: null });
  }, []);

  const handleVerwijderen = useCallback(async () => {
    const params = { ids: urlState.selectie };
    if (
      (
        await checkStore.bevestigen({
          inhoud: <span>Geselecteerde winnaars verwijderen?</span>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.klantactie.winweek.verwijderenWinnaars(params);
    setUrlStateSync('selectie', []);

    await ophalenRijen();
  }, [urlState.selectie]);

  const keyExtractor = useCallback((row: IWinweekWinnaar) => (row.ID == null ? 0 : row.ID), [
    rijen,
  ]);

  const kolommen = useMemo<ASPKolom<Kolom, IWinweekWinnaar>[]>(
    () => [
      {
        key: 'RelID',
        label: 'Relatie',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 250,
        renderer: (x) =>
          x.RelID == null ? (
            'Geen relatie'
          ) : (
            <RelatieVisualisatie relID={x.RelID!} options={{ geenLinkToepassen: false }} />
          ),
      },
      {
        key: 'Weeknummer',
        label: 'Week',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (x) => x.Weeknummer,
      },
      {
        key: 'Jaar',
        label: 'Jaar',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 100,
        renderer: (x) => x.Jaar,
      },
      {
        key: 'Adres',
        label: 'Adres',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 225,
        renderer: (x) => x.Adres,
      },
      {
        key: 'Publicatiedatum',
        label: 'Publicatiedatum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (x) =>
          x.Publicatiedatum !== null ? format(new Date(x.Publicatiedatum), 'dd-MM-yyyy') : '- -',
      },
      {
        key: 'Foto_BestandID',
        label: 'Bestand',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 110,
        renderer: (x) => (x.Foto_BestandID ? 'Ja' : 'Nee'),
      },
      {
        key: 'StatusOk',
        label: 'Status Ok',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 110,
        renderer: (x) => (x.StatusOk ? 'Ja' : 'Nee'),
      },
      {
        key: 'VorderingGemaaktOp',
        label: 'Creditvordering gemaakt',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (x) =>
          x.VorderingGemaaktOp !== null
            ? format(new Date(x.VorderingGemaaktOp), 'dd-MM-yyyy')
            : 'Nog niet',
      },
    ],
    [],
  );

  const handleVersturenWinbericht = useCallback(async (relID: number, persID: number | null) => {
    const params = { relID, persID };
    if (
      (
        await checkStore.bevestigen({
          inhoud: <div>Wil je een winbericht versturen naar de geselecteerde relatie?</div>,
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.klantactie.winweek.versturenWinbericht(params);
    ophalenRijen();
  }, []);

  const handleBelonenWinnaar = useCallback(async () => {
    const params = { id: urlState.selectie[0] };

    const checkData = await api.v2.klantactie.winweek.checkBelonenWinnaar(params);
    if ((await checkStore.controleren({ checkData })).type === EResultType.Annuleren) {
      return;
    }

    if (
      (
        await checkStore.bevestigen({
          inhoud: (
            <div>
              Wil je een creditvordering maken als beloning?
              <br />
              Je dient zelf nog daarvoor een factuur te maken.
            </div>
          ),
        })
      ).type === EResultType.Annuleren
    ) {
      return;
    }

    await api.v2.klantactie.winweek.belonenWinnaar(params);
    ophalenRijen();
  }, [urlState.selectie]);

  return (
    <>
      <Helmet>
        <title>Winweek winnaars</title>
      </Helmet>
      <MenuLayout
        menu={
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-light d-flex align-items-center"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={false}
              onClick={() => handleToevoegen()}
            >
              <IconToevoegen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Toevoegen</span>
            </button>

            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={() => handleVerwijderen()}
            >
              <IconVerwijderen style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Verwijderen</span>
            </button>

            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length === 0}
              onClick={() => {
                const relID = rijen!.find((x) => x.ID === urlState.selectie[0])!.RelID;
                handleVersturenWinbericht(relID, null);
              }}
            >
              <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Winbericht versturen</span>
            </button>

            <button
              className="btn btn-sm btn-light d-flex align-items-center ml-3"
              style={{ border: `1px solid ${Kleur.LichtGrijs}` }}
              disabled={urlState.selectie.length !== 1}
              onClick={() => {
                handleBelonenWinnaar();
              }}
            >
              <IconSend style={{ width: 16, height: 16, fill: Kleur.Grijs }} />
              <span className="ml-2">Winnaar belonen</span>
            </button>
          </div>
        }
        body={
          rijen == null ? (
            <span></span>
          ) : (
            <ASPTabel
              rijen={rijen}
              kolommen={kolommen}
              keyExtractor={keyExtractor}
              selectie={urlState.selectie}
              onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
              onWijzigenRij={async (rij) => {
                setUrlStateSync('muterenDialoogState', { id: rij.ID ?? null });
              }}
              tdComponent={TdComponent}
            />
          )
        }
      />
      {urlState.muterenDialoogState !== null && (
        <MuterenDialoog
          open
          id={urlState.muterenDialoogState.id}
          onSuccess={async () => {
            setUrlStateSync('muterenDialoogState', null);
            await ophalenRijen();
          }}
          onAnnuleren={() => setUrlStateSync('muterenDialoogState', null)}
        />
      )}
    </>
  );
};

const TdComponent = (props: ITableDataProps<Kolom, IWinweekWinnaar>) => {
  return (
    <TableData
      {...props}
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        display: 'block',
        paddingTop: '8px',
      }}
    />
  );
};

export default withRouter(Winnaars);
