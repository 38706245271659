import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Field, FieldProps, Formik, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../stores/RootStore';
import Combobox from '../../../../components/formulier/Combobox';
import nameOf from '../../../../core/nameOf';
import api from '../../../../api';
import { Kleur } from '../../../../bedrijfslogica/constanten';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import { EResultType } from '../../../../stores/CheckStore';
import Skeleton from 'react-loading-skeleton';
import { format } from 'date-fns';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import {
  IOphalenAanmeldingStatussenResultElement,
  IOphalenAanmeldingenResultElement,
} from '../../../../../../shared/src/api/v2/inkoop/retour';
import { dagDatum } from '../../../../helpers/datum';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';

interface IDialoogResult {}

interface IProps extends IDialoogProps<IDialoogResult> {
  id: number;
}

interface IFormikValues {
  omruilen: boolean;
  toelichting: string;
  externeReferentie: string;
  notities: string;
  afgehandeld: boolean;
  status: number;
  aangemeldOp: Date | string | null;
}

const veldnamen: Record<keyof IFormikValues, string> = {
  omruilen: 'Omruilen (anders Crediteren)',
  toelichting: 'Toelichting (komt op de bon)',
  externeReferentie: 'Externe referentie (b.v. een RMA-nummer)',
  notities: 'Notities',
  afgehandeld: 'Afgehandeld',
  status: 'Status',
  aangemeldOp: 'Aangemeld op',
};

const WijzigenDialoog: React.FC<IProps> = observer((props) => {
  const { children, dialoogIndex, onAnnuleren, onSuccess, open } = props;
  const { checkStore, klantkaartStore } = useContext(RootStoreContext);

  const [aanmelding, setAanmelding] = useState<IOphalenAanmeldingenResultElement | null>(null);

  const [statussen, setStatussen] = useState<IOphalenAanmeldingStatussenResultElement[] | null>(
    null,
  );

  const ophalenAanmeldingen = useCallback(async () => {
    const resultaat = await api.v2.inkoop.retour.ophalenAanmeldingen({
      filterSchema: {
        filters: [{ naam: 'IDS', data: [props.id] }],
      },
    });
    setAanmelding(resultaat.aanmeldingen[0]);
  }, [props.id]);

  useEffect(() => {
    ophalenAanmeldingen();
  }, [ophalenAanmeldingen]);

  const ophalenStatussen = useCallback(async () => {
    const resultaat = await api.v2.inkoop.retour.ophalenAanmeldingStatussen({});
    setStatussen(resultaat.statussen);
  }, []);

  useEffect(() => {
    ophalenStatussen();
  }, [ophalenStatussen]);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      if (aanmelding === null) {
        return;
      }

      actions.setSubmitting(true);

      const params = {
        id: props.id,
        registratiedatum: aanmelding.Registratiedatum,
        prodID: aanmelding.product!.ProdID,
        inkOpdID: aanmelding.inkoopopdracht!.InkOpdID,
        omruilen: values.omruilen,
        inkFactID: aanmelding.InkFactID,
        toelichting: values.toelichting !== '' ? values.toelichting : null,
        externeReferentie: values.externeReferentie !== '' ? values.externeReferentie : null,
        aangemeldOp: values.aangemeldOp,
        retouraanmeldingStatusID: values.status,
        notities: values.notities !== '' ? values.notities : null,
        afgehandeldOp: values.afgehandeld
          ? aanmelding.AfgehandeldOp !== null
            ? aanmelding.AfgehandeldOp
            : new Date()
          : null,
      };

      const checkData = await api.v2.inkoop.retour.checkWijzigenAanmelding(params);
      const checkResult = await checkStore.controleren({
        checkData,
      });
      if (checkResult.type === EResultType.Annuleren) {
        actions.setSubmitting(false);
        return;
      }

      // const inhoud = <span>Wijzigingen vastleggen?</span>;
      // if (
      //   (
      //     await checkStore.bevestigen({
      //       inhoud,
      //     })
      //   ).type === EResultType.Annuleren
      // ) {
      //   actions.setSubmitting(false);
      //   return;
      // }

      await api.v2.inkoop.retour.wijzigenAanmelding(params);

      props.onSuccess({});
      actions.setSubmitting(false);
    },
    [props.id, aanmelding],
  );

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (aanmelding === null) {
      return null;
    }

    return {
      omruilen: aanmelding.Omruilen,
      toelichting: aanmelding.Toelichting ?? '',
      externeReferentie: aanmelding.ExterneReferentie ?? '',
      notities: aanmelding.Notities ?? '',
      afgehandeld: aanmelding.AfgehandeldOp !== null,
      status: aanmelding.status.ID,
      aangemeldOp:
        aanmelding.AangemeldOp !== null ? dagDatum(new Date(aanmelding.AangemeldOp)) : null,
    };
  }, [aanmelding]);

  const validationSchema = useMemo(() => Yup.object().shape({}), []);

  return (
    <Dialoog index={dialoogIndex || 0} modalProps={{ size: 'lg' }}>
      <ModalHeader>
        <ModalTitle>Wijzigen Retouraanmelding</ModalTitle>
      </ModalHeader>
      {initialValues !== null && (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          isInitialValid
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isValid, isSubmitting } = formikProps;

            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <label>{veldnamen.status}</label>
                        <Field
                          name={nameOf<IFormikValues>('status')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (statussen === null) {
                              return <LoadingSpinner />;
                            }

                            return (
                              <Combobox
                                geselecteerd={field.value}
                                opties={statussen.map((x) => {
                                  return {
                                    id: x.ID,
                                    label: x.Naam,
                                  };
                                })}
                                onSelectieChange={(x) => form.setFieldValue(field.name, x)}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.aangemeldOp}</label>
                        <Field
                          name={nameOf<IFormikValues>('aangemeldOp')}
                          render={({ field, form }: FieldProps<IFormikValues>) => {
                            return (
                              <div className="d-flex ">
                                <DatumKiezer
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  waarde={field.value}
                                  determineValidDate={() => {
                                    return true;
                                  }}
                                  determinePreviousValidDate="ONBEGRENST"
                                  determineNextValidDate="ONBEGRENST"
                                  isClearable
                                />
                              </div>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <Field
                          name={nameOf<IFormikValues>('omruilen')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <span className="d-flex align-items-center">
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                  <span className="ml-2">{veldnamen.omruilen}</span>
                                </span>
                              </>
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.externeReferentie}</label>
                        <Field
                          name={nameOf<IFormikValues>('externeReferentie')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return <input {...field} className="form-control" />;
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.toelichting}</label>
                        <Field
                          name={nameOf<IFormikValues>('toelichting')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return <textarea {...field} className="form-control" rows={2} />;
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.notities}</label>
                        <Field
                          name={nameOf<IFormikValues>('notities')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return <textarea {...field} className="form-control" rows={2} />;
                          }}
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <Field
                          name={nameOf<IFormikValues>('afgehandeld')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;
                            return (
                              <>
                                <span className="d-flex align-items-center">
                                  <VinkVeld
                                    aangevinkt={field.value}
                                    onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  />
                                  <span className="ml-2">{veldnamen.afgehandeld}</span>
                                </span>
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={!isValid || isSubmitting}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
});

export default WijzigenDialoog;
