import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { IConcurrent, IConcurrentProduct } from '../../../../../shared/src/api/v2/concurrent';
import api from '../../../api';
import { IFilterData } from '../../../components/FilterBalkV2';
import MenuLayout from '../../../components/MenuLayout';
import ASPTabel from '../../../components/tabel/ASPTabel';
import TableData, { ITableDataProps } from '../../../components/tabel/ASPTabel/Body/TableData';
import { ASPKolom, EAspKolomBreedteType } from '../../../components/tabel/ASPTabel/types';
import useUrlState from '../../../core/useUrlState';
import { EResultType } from '../../../stores/CheckStore';
import { RootStoreContext } from '../../../stores/RootStore';
import {
  IOphalenProductmodellenResult,
  IOphalenProductmodellenResultElement,
} from '../../../../../shared/src/api/v2/aanbod/productmodel';
import MultiCombobox, { IKolom } from '../../../components/formulier/MultiCombobox';
import UitgeklapteRij from './UitgeklapteRij';

export type Kolom = 'naam' | 'concurrent' | 'datum-in' | 'datum-uit' | 'gekoppelde-modellen';

export enum EFilter {
  Zoekterm = 'ZOEKTERM',
}

interface IProps extends RouteComponentProps {}

interface IToevoegenDialoogState {}
interface IWijzigenDialoogState {
  id: number;
}
interface IUrlState {
  selectie: number[];
  filterdata: IFilterData<EFilter>[];
  uitgeklapt: number[];
}

const defaultUrlState: IUrlState = {
  selectie: [],
  filterdata: [],
  uitgeklapt: [],
};

const Item: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [rijen, setRijen] = useState<IConcurrentProduct[] | null>(null);
  const [concurrenten, setConcurrenten] = useState<IConcurrent[] | null>(null);

  // rijen

  const ophalenRijen = useCallback(async () => {
    console.log('ophalenRijen');
    const results = await api.v2.concurrentie.ophalenProducten({});
    setRijen(results);
  }, []);

  useEffect(() => {
    ophalenRijen();
  }, [ophalenRijen]);

  // concurrenten

  const ophalenConcurrenten = useCallback(async () => {
    console.log('ophalenConcurrenten');
    const results = await api.v2.concurrentie.ophalenConcurrenten();
    setConcurrenten(results);
  }, []);

  useEffect(() => {
    ophalenConcurrenten();
  }, [ophalenConcurrenten]);

  // rij uitklappen

  const magRijUitklappen = useCallback((rij) => true, []);

  const keyExtractor = useCallback((row: IConcurrentProduct) => (row.ID == null ? 0 : row.ID), [
    rijen,
  ]);

  const kolommen = useMemo<ASPKolom<Kolom, IConcurrentProduct>[]>(
    () => [
      {
        key: 'naam',
        label: 'Naam',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 500,
        renderer: (x) => x.IdentificerendeNaam,
      },
      {
        key: 'concurrent',
        label: 'Concurrent',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 0.5,
        renderer: (x) =>
          concurrenten == null
            ? x.ConcurrentID
            : concurrenten.find((c) => c.ID == x.ConcurrentID)?.Naam,
      },
      {
        key: 'datum-in',
        label: 'Datum in aanbod',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (x) => <span>{x.DatumInAanbod}</span>,
      },
      {
        key: 'datum-uit',
        label: 'Datum uit aanbod',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (x) => <span>{x.DatumUitAanbod}</span>,
      },
      {
        key: 'gekoppelde-modellen',
        label: 'Gekoppelde productmodellen',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (x) => <span></span>,
      },
    ],
    [concurrenten],
  );

  return (
    <>
      <Helmet>
        <title>Gescande producten</title>
      </Helmet>
      <MenuLayout
        body={
          rijen == null ? (
            <span></span>
          ) : (
            <ASPTabel
              rijen={rijen}
              kolommen={kolommen}
              keyExtractor={keyExtractor}
              selectie={urlState.selectie}
              tdComponent={TdComponent}
              uitgeklapt={urlState.uitgeklapt}
              onUitgeklaptChange={(value) => setUrlStateSync('uitgeklapt', value)}
              uitgeklapteRijComponent={UitgeklapteRij}
              rijHoogte={40}
              uitgeklapteRijHoogte={200}
              magRijUitklappen={magRijUitklappen}
            />
          )
        }
      />
    </>
  );
};

const TdComponent = (props: ITableDataProps<Kolom, IConcurrentProduct>) => {
  return (
    <TableData
      {...props}
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        display: 'block',
        paddingTop: '8px',
      }}
    />
  );
};

export default withRouter(Item);
