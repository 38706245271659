import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import api from '../../api';
import { Kleur as EKleur } from '../../bedrijfslogica/constanten';
import { Sorting } from '@devexpress/dx-react-grid';
import useUrlState from '../../core/useUrlState';
import MenuLayout from '../../components/MenuLayout';
import GegevensLayout from '../../components/layout/GegevensLayout';
import { format } from 'date-fns';
import FilterBalkV2, {
  IFilter,
  IFilterData,
  maakFilterSchema,
} from '../../components/FilterBalkV2';
import { IFilterSchema } from '../../../../shared/src/models/filter';
import MultiCombobox from '../../components/formulier/MultiCombobox';
import { Helmet } from 'react-helmet';
import {
  IOphalenEscalatiecontextenResultElement,
  IOphalenEscalatiesResultElement,
} from '../../../../shared/src/api/v2/escalatie';
import LoadingSpinner from '../../components/Gedeeld/LoadingSpinner';
import Combobox from '../../components/formulier/Combobox';
import { RootStoreContext } from '../../stores/RootStore';
import { EResultType } from '../../stores/CheckStore';
import { IconToevoegen, IconVerwijderen } from '../../components/Icons';
import ASPTabel from '../../components/tabel/ASPTabel';
import { ASPKolom, EAspKolomBreedteType } from '../../components/tabel/ASPTabel/types';
import RelatieVisualisatie from '../../components/personalia/RelatieVisualisatie';
import ToevoegenEscalatieDialoog from './ToevoegenEscalatieDialoog';
import WijzigenEscalatieDialoog from './WijzigenEscalatieDialoog';
import BezigheidKnop from '../../components/BezigheidKnop';

interface IProps extends RouteComponentProps {}

const enum EKolom {
  Registratiedatum,
  Ontstaansdatum,
  KorteOmschrijving,
  Opgepakt,
  isAfgehandeld,
  Relatie,
  Context,
  DatumOpgelost,
}

enum EFilter {
  // EscalatieSoort = 'ESCALATIE_SOORT_IDS',
  IsAfgehandeld = 'IS_AFGEHANDELD',
}

interface IToevoegenEscalatieDialoogState {}
interface IWijzigenEscalatieDialoogState {
  id: number;
}

interface IUrlState {
  selectie: number[];
  sortering: Array<Sorting>;
  filterData: Array<IFilterData<EFilter>>;
  toevoegenEscalatieDialoogState: IToevoegenEscalatieDialoogState | null;
  wijzigenEscalatieDialoogState: IWijzigenEscalatieDialoogState | null;
}

const defaultUrlState: IUrlState = {
  selectie: [],
  sortering: [],
  filterData: [
    {
      naam: EFilter.IsAfgehandeld,
      data: false,
      isActief: true,
    },
    // {
    //   naam: EFilter.EscalatieSoort,
    //   data: [1],
    //   isActief: false,
    // },
  ],
  toevoegenEscalatieDialoogState: null,
  wijzigenEscalatieDialoogState: null,
};

const Escalaties: React.FC<IProps> = (props) => {
  const [urlState, setUrlState, setUrlStateSync] = useUrlState<IUrlState>(props, defaultUrlState);
  const { checkStore } = useContext(RootStoreContext);

  const [escalaties, setEscalaties] = useState<IOphalenEscalatiesResultElement[] | null>(null);
  const [escalatiecontexten, setEscalatiecontexten] = useState<
    IOphalenEscalatiecontextenResultElement[] | null
  >(null);

  const [filterSchema, setFilterSchema] = useState<IFilterSchema>(
    useMemo(() => maakFilterSchema(urlState.filterData), []),
  );
  const ophalenEscalaties = useCallback(async () => {
    const result = await api.v2.escalatie.ophalenEscalaties({
      filterSchema: {
        filters: [...filterSchema.filters!],
      },
      orderSchema: { orders: [{ naam: 'REGISTRATIEDATUM', richting: 'DESC' }] },
    });
    setEscalaties(result.escalaties);
  }, [filterSchema.filters]);

  useEffect(() => {
    ophalenEscalaties();
  }, [ophalenEscalaties]);

  const ophalenEscalatiecontexten = useCallback(async () => {
    const result = await api.v2.escalatie.ophalenEscalatiecontexten({});
    setEscalatiecontexten(result.contexten);
  }, []);

  const handleVerwijderenRij = useCallback(async (rij: IOphalenEscalatiesResultElement) => {
    await api.v2.escalatie.verwijderenEscalaties({ ids: [rij.ID] });
    await ophalenEscalaties();
  }, []);

  const handleWijzigenRij = useCallback(async (rij: IOphalenEscalatiesResultElement) => {
    setUrlStateSync('wijzigenEscalatieDialoogState', { id: rij.ID });
  }, []);

  useEffect(() => {
    ophalenEscalatiecontexten();
  }, [ophalenEscalatiecontexten]);

  const filters = useMemo<IFilter<EFilter>[]>(
    () => [
      {
        naam: EFilter.IsAfgehandeld,
        altijdWeergevenInBalk: true,
        weergave: (weergaveProps) => {
          return <span>Niet afgehandeld</span>;
        },
      },
    ],
    [],
  );

  const keyExtractor = useCallback((rij: IOphalenEscalatiesResultElement) => rij.ID, []);

  const kolommen = useMemo<ASPKolom<EKolom, IOphalenEscalatiesResultElement>[]>(
    () => [
      {
        key: EKolom.Registratiedatum,
        label: 'Registratiedatum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 175,
        renderer: (rij) => {
          return format(new Date(rij.Registratiedatum), 'dd-MM-yyyy HH:mm');
        },
      },
      {
        key: EKolom.Relatie,
        label: 'Relatie',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 250,
        renderer: (rij) => {
          return rij.RelID !== null ? <RelatieVisualisatie relID={rij.RelID} /> : <span></span>;
        },
      },
      {
        key: EKolom.Ontstaansdatum,
        label: 'Ontstaansdatum',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (rij) => {
          return rij.Ontstaansdatum !== null
            ? format(new Date(rij.Ontstaansdatum), 'dd-MM-yyyy')
            : '';
        },
      },
      {
        key: EKolom.DatumOpgelost,
        label: 'Datum opgelost',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 150,
        renderer: (rij) => {
          return rij.DatumOpgelost !== null
            ? format(new Date(rij.DatumOpgelost), 'dd-MM-yyyy')
            : '';
        },
      },
      {
        key: EKolom.KorteOmschrijving,
        label: 'Korte omschrijving',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 400,
        renderer: (rij) => rij.KorteOmschrijving,
      },
      {
        key: EKolom.Opgepakt,
        label: 'Opgepakt',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 125,
        renderer: (rij) => {
          return (
            <BezigheidKnop interactieToegestaan data={{ enum: 'ESCALATIE', escalatieID: rij.ID }} />
          );
        },
      },
      {
        key: EKolom.Context,
        label: 'Context',
        breedteType: EAspKolomBreedteType.Vast,
        vasteBreedte: 200,
        renderer: (rij) => {
          if (escalatiecontexten === null) {
            return '';
          }
          if (rij.EscalatiecontextID === null) {
            return '- -';
          }

          return escalatiecontexten.find((x) => x.ID === rij.EscalatiecontextID)!.Naam;
        },
      },
      {
        key: EKolom.isAfgehandeld,
        label: 'Afgehandeld',
        breedteType: EAspKolomBreedteType.Flex,
        flex: 1,
        renderer: (rij) => {
          return rij.IsAfgehandeld ? 'Ja' : 'Nee';
        },
      },
    ],
    [escalatiecontexten],
  );

  return (
    <>
      <Helmet>
        <title>Escalaties</title>
      </Helmet>
      <MenuLayout
        menu={
          <>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-sm btn-light d-flex align-items-center justify-content-center"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                onClick={async () => {
                  setUrlStateSync('toevoegenEscalatieDialoogState', {});

                  // if (
                  //   (
                  //     await checkStore.bevestigen({
                  //       inhoud: 'Escaltie toevoegen?',
                  //     })
                  //   ).type === EResultType.Annuleren
                  // ) {
                  //   return;
                  // }
                  // await api.v2.escalatie.toevoegenEscalatie({});
                  // await ophalenEscalaties();
                }}
              >
                <IconToevoegen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Toevoegen escalatie</span>
              </button>

              {/* <button
                className="btn btn-sm btn-light d-flex align-items-center justify-content-center ml-3"
                style={{ border: `1px solid ${EKleur.LichtGrijs}` }}
                disabled={urlState.selectie.length === 0}
                onClick={async () => {
                  const checkData = await api.v2.escalatie.checkVerwijderenEscalaties({
                    ids: urlState.selectie,
                  });

                  if (
                    (await checkStore.controleren({ checkData })).type === EResultType.Annuleren
                  ) {
                    return;
                  }

                  if (
                    (
                      await checkStore.bevestigen({
                        inhoud: <span>Wil je de geselecteerde esclaties verwijderen?</span>,
                      })
                    ).type === EResultType.Annuleren
                  ) {
                    return;
                  }
                  const result = await api.v2.escalatie.verwijderenEscalaties({
                    ids: urlState.selectie,
                  });

                  setUrlStateSync('selectie', []);

                  ophalenEscalaties();
                }}
              >
                <IconVerwijderen style={{ width: 16, height: 16, fill: EKleur.Grijs }} />
                <span className="ml-2">Verwijderen</span>
              </button> */}

              <div className="d-flex flex-fill ml-3">
                <FilterBalkV2
                  filters={filters}
                  filterData={urlState.filterData}
                  onFilterDataChange={(x) => setUrlStateSync('filterData', x)}
                  onFilterSchemaChange={setFilterSchema}
                />
              </div>
            </div>
          </>
        }
        body={
          escalaties === null ? (
            <div className="flex-fill d-flex align-items-center justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <ASPTabel
              rijen={escalaties}
              kolommen={kolommen}
              keyExtractor={keyExtractor}
              selectie={urlState.selectie}
              onSelectieChange={(selectie) => setUrlStateSync('selectie', selectie)}
              onVerwijderenRij={handleVerwijderenRij}
              onWijzigenRij={handleWijzigenRij}
              // sortering={urlState.sortering}
              // onSorteringChange={(sortering) => setUrlStateSync('sortering', sortering)}
              lokaalSorteren
            />
          )
        }
      />

      {urlState.toevoegenEscalatieDialoogState !== null && (
        <ToevoegenEscalatieDialoog
          open
          onSuccess={() => {
            setUrlStateSync('toevoegenEscalatieDialoogState', null);
            ophalenEscalaties();
          }}
          onAnnuleren={() => {
            setUrlStateSync('toevoegenEscalatieDialoogState', null);
          }}
        />
      )}
      {urlState.wijzigenEscalatieDialoogState !== null && (
        <WijzigenEscalatieDialoog
          open
          id={urlState.wijzigenEscalatieDialoogState.id}
          onSuccess={() => {
            setUrlStateSync('wijzigenEscalatieDialoogState', null);
            ophalenEscalaties();
          }}
          onAnnuleren={() => {
            setUrlStateSync('wijzigenEscalatieDialoogState', null);
          }}
        />
      )}
    </>
  );
};

export default withRouter(Escalaties);
