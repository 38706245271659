import api from '../..';
import {
  IVerwijderenMeldingenParams,
  IVerwijderenMeldingenResult,
  IOphalenProductenParams,
  OphalenProductenResult,
  CheckToevoegenServicemeldingParams,
  IToevoegenServicemeldingParams,
  IToevoegenServicemeldingResult,
  IInitServicemeldingParams,
  IInitServicemeldingResult,
  IMuterenAfbeeldingenMeldingParams,
  IMuterenAfbeeldingenMeldingResult,
  IOphalenMeldingenParams,
  IOphalenMeldingenResult,
  IWijzigenMeldingParams,
  IWijzigenMeldingResult,
  IKoppelenAanRelatieParams,
  IKoppelenAanRelatieResult,
  IVersturenProductdocumentatieParams,
  IVersturenProductdocumentatieResult,
  IMuterenServiceGebiedParams,
  IMuterenServiceGebiedResult,
  IVerwijderenServiceGebiedenParams,
  IVerwijderenServiceGebiedenResult,
  IKoppelenAanProductParams,
  IOphalenMeldingstatussenParams,
  IOphalenMeldingstatussenResult,
  IKoppelenAanPersoonParams,
  IKoppelenAanPersoonResult,
  IOphalenTerugkoppelingenParams,
  IOphalenTerugkoppelingenResult,
  IGenereerLinkVoorTerugkoppelingParams,
  IGenereerLinkVoorTerugkoppelingResult,
} from '../../../../../shared/src/api/v2/service/melding';
import {
  IOphalenServicedienstenParams,
  IOphalenServicedienstenResult,
  IToevoegenOpdrachtParams,
  IToevoegenOpdrachtResult,
  IVersturenServiceOpdrachtParams,
  IVersturenServiceOpdrachtResult,
  IVerwijderenServiceOpdrachtParams,
  IOphalenOpdrachtenParams,
  IOphalenOpdrachtenResult,
  IWijzigenServiceOpdrachtParams,
  IWijzigenServiceOpdrachtResult,
  ISelectieToevoegenOpdrachtParams,
  IOphalenServiceGebiedVoorkeurParams,
  IOphalenServiceGebiedVoorkeurResult,
  IVersturenServiceOpdrachtenParams,
  IVersturenServiceOpdrachtenResult,
  IAnnulerenServiceOpdrachtenParams,
  IAnnulerenServiceOpdrachtenResult,
  IOphalenOpdrachtstatussenParams,
  IOphalenOpdrachtstatussenResult,
  IVersturenBevestigingenServiceOpdrachtNaarRelatieParams,
  IVersturenBevestigingenServiceOpdrachtNaarRelatieResult,
  IWijzigenServiceopdrachtenAfgehandeldParams,
  IWijzigenServiceopdrachtenAfgehandeldResult,
  IWijzigenDocumentParams,
  IWijzigenDocumentResult,
  IVersturenAnnuleringsberichtParams,
  IVersturenAnnuleringsberichtResult,
  IVersturenContactgegevensNaarRelatieParams,
  IVersturenContactgegevensNaarRelatieResult,
  IOphalenServicedienstenV2Params,
  IOphalenServicedienstenV2Result,
} from '../../../../../shared/src/api/v2/service/opdracht';
import {
  IDoorberekenenKostenParams,
  IDoorberekenenKostenResult,
  IOphalenWerkbonnenParams,
  IOphalenWerkbonnenResult,
  IToevoegenWerkbonParams,
  IToevoegenWerkbonResult,
  IVerwijderenWerkbonnenParams,
  IVerwijderenWerkbonnenResult,
  IWijzigenWerkbonParams,
  IWijzigenWerkbonResult,
} from '../../../../../shared/src/api/v2/service/werkbon';
import ICheckData from '../../../../../shared/src/models/ICheckData';
import niveau from './niveau';

const service = {
  niveau,
  checkVerwijderenMeldingen: async (params: IVerwijderenMeldingenParams): Promise<ICheckData> => {
    return await api.post('v2/service/melding/check-verwijderen-meldingen', params);
  },
  verwijderenMeldingen: async (
    params: IVerwijderenMeldingenParams,
  ): Promise<IVerwijderenMeldingenResult> => {
    return await api.post('v2/service/melding/verwijderen-meldingen', params);
  },
  ophalenProducten: async (params: IOphalenProductenParams): Promise<OphalenProductenResult> => {
    return await api.post('v2/service/melding/ophalen-producten', params);
  },
  checkToevoegenServicemelding: async (
    params: CheckToevoegenServicemeldingParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/service/melding/check-toevoegen-servicemelding', params);
  },
  toevoegenServicemelding: async (
    params: IToevoegenServicemeldingParams,
  ): Promise<IToevoegenServicemeldingResult> => {
    return await api.post('v2/service/melding/toevoegen-servicemelding', params);
  },
  ophalenServicediensten: async (params: IOphalenServicedienstenParams) => {
    return await api.post<IOphalenServicedienstenResult>(
      'v2/service/opdracht/ophalen-servicediensten',
      params,
    );
  },
  ophalenServicedienstenV2: async (params: IOphalenServicedienstenV2Params) => {
    return await api.post<IOphalenServicedienstenV2Result>(
      'v2/service/opdracht/ophalen-servicediensten-v2',
      params,
    );
  },
  checkSelectieToevoegenOpdracht: async (params: ISelectieToevoegenOpdrachtParams) => {
    return await api.post<ICheckData>(
      'v2/service/opdracht/check-selectie-toevoegen-opdracht',
      params,
    );
  },
  checkToevoegenOpdracht: async (params: IToevoegenOpdrachtParams) => {
    return await api.post<ICheckData>('v2/service/opdracht/check-toevoegen-opdracht', params);
  },
  toevoegenOpdracht: async (params: IToevoegenOpdrachtParams) => {
    return await api.post<IToevoegenOpdrachtResult>(
      'v2/service/opdracht/toevoegen-opdracht',
      params,
    );
  },
  checkVersturenServiceOpdrachten: async (params: IVersturenServiceOpdrachtParams) => {
    return await api.post<ICheckData>('v2/service/opdracht/check-versturen-opdrachten', params);
  },
  versturenServiceOpdrachten: async (params: IVersturenServiceOpdrachtenParams) => {
    return await api.post<IVersturenServiceOpdrachtenResult>(
      'v2/service/opdracht/versturen-opdrachten',
      params,
    );
  },
  versturenBevestigingenServiceOpdrachtNaarRelatie: async (
    params: IVersturenBevestigingenServiceOpdrachtNaarRelatieParams,
  ) => {
    return await api.post<IVersturenBevestigingenServiceOpdrachtNaarRelatieResult>(
      'v2/service/opdracht/versturen-bevestigingen-service-opdracht-naar-relatie',
      params,
    );
  },
  checkAnnulerenServiceOpdrachten: async (params: IAnnulerenServiceOpdrachtenParams) => {
    return await api.post<ICheckData>(
      'v2/service/opdracht/check-annuleren-serviceopdrachten',
      params,
    );
  },
  annulerenServiceOpdrachten: async (params: IAnnulerenServiceOpdrachtenParams) => {
    return await api.post<IAnnulerenServiceOpdrachtenResult>(
      'v2/service/opdracht/annuleren-serviceopdrachten',
      params,
    );
  },
  checkVerwijderenServiceOpdracht: async (params: IVerwijderenServiceOpdrachtParams) => {
    return await api.post<ICheckData>('v2/service/opdracht/check-verwijderen-opdracht', params);
  },
  verwijderenServiceOpdracht: async (params: IVerwijderenServiceOpdrachtParams) => {
    return await api.post<ICheckData>('v2/service/opdracht/verwijderen-opdracht', params);
  },
  initServicemelding: async (params: IInitServicemeldingParams) => {
    return await api.post<IInitServicemeldingResult>(
      'v2/service/melding/init-servicemelding',
      params,
    );
  },
  muterenAfbeeldingenMelding: async (
    params: IMuterenAfbeeldingenMeldingParams,
  ): Promise<IMuterenAfbeeldingenMeldingResult> => {
    return await api.post<IInitServicemeldingResult>(
      'v2/service/melding/muteren-afbeeldingen-melding',
      params,
    );
  },
  ophalenMeldingstatussen: async (
    params: IOphalenMeldingstatussenParams,
  ): Promise<IOphalenMeldingstatussenResult> => {
    return await api.post('v2/service/melding/ophalen-meldingstatussen', params);
  },
  ophalenMeldingen: async (params: IOphalenMeldingenParams): Promise<IOphalenMeldingenResult> => {
    return await api.post('v2/service/melding/ophalen-meldingen', params);
  },
  wijzigenMelding: async (params: IWijzigenMeldingParams): Promise<IWijzigenMeldingResult> => {
    return await api.post('v2/service/melding/wijzigen-melding', params);
  },
  koppelenAanRelatie: async (
    params: IKoppelenAanRelatieParams,
  ): Promise<IKoppelenAanRelatieResult> => {
    return await api.post('v2/service/melding/koppelen-aan-relatie', params);
  },
  koppelenAanProduct: async (
    params: IKoppelenAanProductParams,
  ): Promise<IKoppelenAanProductParams> => {
    return await api.post('v2/service/melding/koppelen-aan-product', params);
  },
  koppelenAanPersoon: async (
    params: IKoppelenAanPersoonParams,
  ): Promise<IKoppelenAanPersoonResult> => {
    return await api.post('v2/service/melding/koppelen-aan-persoon', params);
  },
  ophalenOpdrachten: async (
    params: IOphalenOpdrachtenParams,
    signal?: AbortSignal,
  ): Promise<IOphalenOpdrachtenResult> => {
    return await api.post('v2/service/opdracht/ophalen-opdrachten', params, {
      signal,
    });
  },
  versturenProductdocumentatie: async (
    params: IVersturenProductdocumentatieParams,
  ): Promise<IVersturenProductdocumentatieResult> => {
    return await api.post('v2/service/melding/versturen-productdocumentatie', params);
  },
  checkWijzigenServiceOpdracht: async (
    params: IWijzigenServiceOpdrachtParams,
  ): Promise<ICheckData> => {
    return await api.post('v2/service/opdracht/check-wijzigen-service-opdracht', params);
  },
  wijzigenServiceOpdracht: async (
    params: IWijzigenServiceOpdrachtParams,
  ): Promise<IWijzigenServiceOpdrachtResult> => {
    return await api.post('v2/service/opdracht/wijzigen-service-opdracht', params);
  },
  wijzigenServiceopdrachtAfgehandeld: async (
    params: IWijzigenServiceopdrachtenAfgehandeldParams,
  ): Promise<IWijzigenServiceopdrachtenAfgehandeldResult> => {
    return await api.post('v2/service/opdracht/wijzigen-service-opdracht-afgehandeld', params);
  },
  ophalenWerkbonnen: async (
    params: IOphalenWerkbonnenParams,
  ): Promise<IOphalenWerkbonnenResult> => {
    return await api.post('v2/service/werkbon/ophalen-werkbonnen', params);
  },
  checkToevoegenWerkbon: async (params: IToevoegenWerkbonParams): Promise<ICheckData> => {
    return await api.post('v2/service/werkbon/check-toevoegen-werkbon', params);
  },
  toevoegenWerkbon: async (params: IToevoegenWerkbonParams): Promise<IToevoegenWerkbonResult> => {
    return await api.post('v2/service/werkbon/toevoegen-werkbon', params);
  },
  checkVerwijderenWerkbonnen: async (params: IVerwijderenWerkbonnenParams): Promise<ICheckData> => {
    return await api.post('v2/service/werkbon/check-verwijderen-werkbonnen', params);
  },
  verwijderenWerkbonnen: async (
    params: IVerwijderenWerkbonnenParams,
  ): Promise<IVerwijderenWerkbonnenResult> => {
    return await api.post('v2/service/werkbon/verwijderen-werkbonnen', params);
  },
  checkWijzigenWerkbon: async (params: IWijzigenWerkbonParams): Promise<ICheckData> => {
    return await api.post('v2/service/werkbon/check-wijzigen-werkbon', params);
  },
  wijzigenWerkbon: async (params: IWijzigenWerkbonParams): Promise<IWijzigenWerkbonResult> => {
    return await api.post('v2/service/werkbon/wijzigen-werkbon', params);
  },
  ophalenServiceGebiedVoorkeur: async (
    params: IOphalenServiceGebiedVoorkeurParams,
  ): Promise<IOphalenServiceGebiedVoorkeurResult> => {
    return await api.post('v2/service/opdracht/ophalen-service-gebied-voorkeur', params);
  },
  checkMuterenServicegebied: async (params: IMuterenServiceGebiedParams): Promise<ICheckData> => {
    return await api.post('v2/service/opdracht/check-muteren-servicegebied', params);
  },
  muterenServicegebied: async (
    params: IMuterenServiceGebiedParams,
  ): Promise<IMuterenServiceGebiedResult> => {
    return await api.post('v2/service/opdracht/muteren-servicegebied', params);
  },
  verwijderenServicegebieden: async (
    params: IVerwijderenServiceGebiedenParams,
  ): Promise<IVerwijderenServiceGebiedenResult> => {
    return await api.post('v2/service/opdracht/verwijderen-servicegebieden', params);
  },
  ophalenOpdrachtstatusssen: async (
    params: IOphalenOpdrachtstatussenParams,
  ): Promise<IOphalenOpdrachtstatussenResult> => {
    return await api.post('v2/service/opdracht/ophalen-opdrachtstatusssen', params);
  },
  versturenAnnuleringsbericht: async (params: IVersturenAnnuleringsberichtParams) => {
    return await api.post<IVersturenAnnuleringsberichtResult>(
      'v2/service/opdracht/versturen-annuleringsbericht-opdrachten',
      params,
    );
  },
  versturenContactgegevensNaarRelatie: async (
    params: IVersturenContactgegevensNaarRelatieParams,
  ) => {
    return await api.post<IVersturenContactgegevensNaarRelatieResult>(
      'v2/service/opdracht/versturen-contactgegevens-naar-relatie',
      params,
    );
  },
  doorberekenenKosten: async (
    params: IDoorberekenenKostenParams,
  ): Promise<IDoorberekenenKostenResult> => {
    return await api.post('v2/service/werkbon/doorberekenen-kosten', params);
  },
  ophalenTerugkoppelingen: async (
    params: IOphalenTerugkoppelingenParams,
  ): Promise<IOphalenTerugkoppelingenResult> => {
    return await api.post('v2/service/melding/ophalen-terugkoppelingen', params);
  },
  genereerLinkVoorTerugkoppeling: async (
    params: IGenereerLinkVoorTerugkoppelingParams,
  ): Promise<IGenereerLinkVoorTerugkoppelingResult> => {
    return await api.post('v2/service/melding/genereer-link-voor-terugkoppeling', params);
  },
};

export default service;
