import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import IDialoogProps from '../../../../core/IDialoogProps';
import Dialoog from '../../../../components/dialogen/Dialoog';
import { Field, FieldProps, Formik, FormikActions, FormikProps, yupToFormErrors } from 'formik';
import LoadingSpinner from '../../../../components/Gedeeld/LoadingSpinner';
import DatumKiezer from '../../../../components/formulier/DatumKiezer';
import MultiCombobox, { IKolom } from '../../../../components/formulier/MultiCombobox';
import NumeriekVeld from '../../../../components/formulier/NumeriekVeld';
import nameOf from '../../../../core/nameOf';
import api from '../../../../api';
import { RootStoreContext } from '../../../../stores/RootStore';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import teksten from '../../../../bedrijfslogica/teksten';
import { IOphalenProducttypenAlternatiefResultElement } from '../../../../../../shared/src/api/v2/inkoop/opdracht/uitstaand';
import { IOphalenOpdrachtenResultElement } from '../../../../../../shared/src/api/v2/inkoop/opdracht';
import { IOphalenMagazijnenResultElement } from '../../../../../../shared/src/api/v2/magazijn';
import { addDays } from 'date-fns';
import { dagDatum } from '../../../../helpers/datum';
import VeldWeergave from '../../../../components/formulier/VeldWeergave';
import VinkVeld from '../../../../components/formulier/VinkVeld';
import { EResultType } from '../../../../stores/CheckStore';
import { IOphalenDienstenResultElement } from '../../../../../../shared/src/api/v2/dienst/inkoop';
import { IOphalenVoorraadinfoResultElement } from '../../../../../../shared/src/api/v2/voorraad';
import IRemoteData, {
  ERemoteDataState,
  createPendingRemoteData,
  createReadyRemoteData,
} from '../../../../models/IRemoteData';

interface IProps extends IDialoogProps<null> {
  inkOpdID?: number;
}

interface IFormikValues {
  datumInslag: Date | null;
  typeID: number | null;
  aantal: number | null;
  magID: number;
  reserveren: boolean;
}
const veldnamen: Record<keyof IFormikValues, string> = {
  datumInslag: 'Inslagdatum',
  typeID: 'Geleverd producttype',
  aantal: 'Aantal',
  magID: 'Magazijn',
  reserveren: 'Reserveringen maken',
};

const aantalTeReserveren = (voorraadInfo: IOphalenVoorraadinfoResultElement, aantal: number) => {
  return aantal >=
    voorraadInfo.aantalInkoopGereserveerdPlanning + voorraadInfo.aantalInkoopGereserveerdUitstaand
    ? voorraadInfo.aantalInkoopGereserveerdPlanning + voorraadInfo.aantalInkoopGereserveerdUitstaand
    : aantal;
};

const InslagDialoog: React.FC<IProps> = (props) => {
  const { dialoogIndex, onAnnuleren, onSuccess } = props;
  const { checkStore } = useContext(RootStoreContext);
  const { instellingStore } = useContext(RootStoreContext);

  const [typeID, setTypeID] = useState<number | null>(null);

  // const [opdracht, setOpdracht] = useState<IOphalenOpdrachtenResultElement | null>(null);

  const [opdracht, setOpdracht] = useState<IRemoteData<IOphalenOpdrachtenResultElement | null>>(
    createPendingRemoteData(),
  );

  const [producttypen, setProducttypen] = useState<
    IOphalenProducttypenAlternatiefResultElement[] | null
  >(null);
  const [magazijnen, setMagazijnen] = useState<IOphalenMagazijnenResultElement[] | null>(null);
  const [dienst, setDienst] = useState<IOphalenDienstenResultElement | null>(null);
  const [voorraadInfo, setVoorraadInfo] = useState<IOphalenVoorraadinfoResultElement | null>(null);
  const [magID, setMagID] = useState<number | null>(null);

  useEffect(() => {
    if (opdracht.state === ERemoteDataState.Pending || magazijnen === null) {
      return;
    }

    setMagID(opdracht.data !== null ? opdracht.data.magazijn.MagID : magazijnen[0].MagID);
  }, [magazijnen, opdracht]);

  useEffect(() => {
    if (opdracht.state === ERemoteDataState.Pending || producttypen === null) {
      return;
    }
    setTypeID(
      opdracht.data !== null
        ? opdracht.data.producttypeVerwacht !== null
          ? opdracht.data.producttypeVerwacht.TypeID
          : opdracht.data.producttype.TypeID
        : producttypen[0].TypeID,
    );
  }, [producttypen, opdracht]);

  // Opdracht ophalen indien er een inkOpdID is meegegeven
  const ophalenOpdracht = useCallback(async () => {
    if (props.inkOpdID === undefined) {
      setOpdracht(createReadyRemoteData(null));
      return;
    }
    const result = await api.v2.inkoop.opdracht.ophalenOpdrachten({
      filterSchema: { filters: [{ naam: 'IDS', data: [props.inkOpdID] }] },
    });

    setOpdracht(createReadyRemoteData(result.inkoopopdrachten[0]));
  }, [props.inkOpdID]);

  useEffect(() => {
    ophalenOpdracht();
  }, [ophalenOpdracht]);

  // Rijbron met producttypen (afhankelijk of er een opdracht aanwezig is of niet)
  const ophalenProducttypen = useCallback(async () => {
    if (opdracht.state === ERemoteDataState.Pending) {
      return;
    }

    if (opdracht.data === null) {
      // Alle producttypen die inkoopwaardig zijn
      const result = await api.v2.inkoop.opdracht.uitstaand.ophalenProducttypenAlternatief({
        typeID: null,
        inkDienstID: null,
      });
      setProducttypen(result);
      return;
    }

    const result = await api.v2.inkoop.opdracht.uitstaand.ophalenProducttypenAlternatief({
      typeID: opdracht.data.producttype.TypeID,
      inkDienstID: opdracht.data.dienst.ID,
    });

    setProducttypen(result);
  }, [opdracht]);

  useEffect(() => {
    ophalenProducttypen();
  }, [ophalenProducttypen]);

  // Alle voorraadmagazijnen ophalen
  const ophalenMagazijnen = useCallback(async () => {
    const result = await api.v2.magazijn.ophalenMagazijnen({
      filterSchema: { filters: [{ naam: 'IS_VOORRAAD', data: true }] },
    });

    setMagazijnen(result.magazijnen);
  }, []);

  useEffect(() => {
    ophalenMagazijnen();
  }, [ophalenMagazijnen]);

  const ophalenDienst = useCallback(async () => {
    if (opdracht.state === ERemoteDataState.Pending || opdracht.data === null) {
      return;
    }

    const dienst = (
      await api.v2.dienst.inkoop.ophalenDiensten({
        filterSchema: { filters: [{ naam: 'IDS', data: [opdracht.data.dienst.ID] }] },
      })
    ).diensten[0];

    setDienst(dienst);
  }, [opdracht]);

  useEffect(() => {
    ophalenDienst();
  }, [ophalenDienst]);

  // Voorraadinfo ophalen van de vigerende TypeID
  const ophalenVoorraadInfo = useCallback(async () => {
    if (typeID === null || magID === null) {
      return;
    }

    const result = (
      await api.v2.voorraad.ophalenVoorraadinfo({
        typeIDs: [typeID],
        magID: magID,
      })
    ).voorraad[0];

    setVoorraadInfo(result);
  }, [typeID, magID]);

  useEffect(() => {
    ophalenVoorraadInfo();
  }, [ophalenVoorraadInfo]);

  const initialValues = useMemo<IFormikValues | null>(() => {
    if (opdracht.state === ERemoteDataState.Pending || magID === null) {
      return null;
    }

    const datumInslag =
      opdracht.data !== null
        ? opdracht.data.DatumVerwacht !== null
          ? dagDatum(new Date(opdracht.data.DatumVerwacht))
          : dagDatum(new Date())
        : dagDatum(new Date());

    return {
      datumInslag,
      typeID: typeID,
      aantal:
        opdracht.data !== null ? opdracht.data.Aantal - opdracht.data.AantalGeleverdeProducten : 1,
      magID,
      reserveren: true,
    };
  }, [opdracht, magID, typeID]);

  const validationSchema = useMemo(() => {
    const fields: Record<keyof IFormikValues, any> = {
      datumInslag: Yup.date().required(),
      typeID: Yup.number().required(),
      magID: Yup.number().required(),
      aantal: Yup.number()
        // .test('nietnul', '', (value) => value !== 0)
        .min(1, teksten.formulier.E_GETAL_MINIMAAL({ veldnaam: veldnamen.aantal, aantal: 1 }))
        .max(100, teksten.formulier.E_GETAL_MAXIMAAL({ veldnaam: veldnamen.aantal, aantal: 100 })),
      reserveren: Yup.boolean(),
    };
    return Yup.object().shape(fields);
  }, []);

  const typenKolommen = useMemo<IKolom<IOphalenProducttypenAlternatiefResultElement>[]>(() => {
    return [
      {
        key: 'Merknaam',
        label: 'Merk',
        breedte: 100,
      },
      {
        key: 'Typenaam',
        label: 'Type',
        breedte: 200,
      },
      {
        key: 'Kenmerk',
        label: 'Kenmerk',
        breedte: 200,
      },
    ];
  }, []);

  const magazijnKolommen = useMemo<IKolom<IOphalenMagazijnenResultElement>[]>(() => {
    return [
      {
        key: 'NaamKort',
        label: 'Naam',
        breedte: 200,
      },
      // {
      //   key: 'PlaatsNaam',
      //   label: 'Plaats',
      //   breedte: 150,
      // },
    ];
  }, []);

  const handleSubmit = useCallback(
    async (values: IFormikValues, actions: FormikActions<IFormikValues>) => {
      actions.setSubmitting(true);

      if (opdracht.state === ERemoteDataState.Pending) {
        actions.setSubmitting(false);
        return;
      }

      const type = producttypen?.find((x) => x.TypeID === values.typeID!)!;

      const aantalReserveren =
        values.aantal! + voorraadInfo!.aantalVrij >=
        voorraadInfo!.aantalInkoopGereserveerdPlanning +
          voorraadInfo!.aantalInkoopGereserveerdUitstaand
          ? voorraadInfo!.aantalInkoopGereserveerdPlanning +
            voorraadInfo!.aantalInkoopGereserveerdUitstaand
          : values.aantal! + voorraadInfo!.aantalVrij;

      const reserverenTekst =
        values.reserveren && aantalReserveren !== 0 ? (
          <span>
            <br />
            Er zullen direct {aantalReserveren} inkoopreserveringen worden toegewezen
          </span>
        ) : (
          <span></span>
        );

      if (
        (
          await checkStore.bevestigen({
            inhoud: (
              <>
                <span>
                  Leverancier {dienst!.Naam}
                  <br />
                  {values.aantal} x {type.Merknaam} {type.Typenaam}
                  <br />
                  Ref. {opdracht.data != null ? opdracht.data.Referentie : ''}
                  <br />
                  <br />
                  Wil je deze inslag vastleggen?
                </span>
                {reserverenTekst}
              </>
            ),
          })
        ).type === EResultType.Annuleren
      ) {
        actions.setSubmitting(false);
        return;
      }

      const params = {
        typeID: values.typeID!,
        aantal: values.aantal!,
        datum: new Date(values.datumInslag!),
        magID: values.magID,
        inkOpdID: props.inkOpdID!, // TODO Kan ook undefined zijn, hoe af te handelen
        afgehandeldAlsAllesGeleverd: true,
      };

      // const checkData = await api.v2.inkoop.opdracht.nieuw.checkToevoegenOpdracht(checkParams);
      // const controleResult = await checkStore.controleren({
      //   checkData,
      // });
      // if (controleResult.type === EResultType.Annuleren) {
      //   return;
      // }

      await api.v2.inkoop.opdracht.uitstaand.inslagProducten(params);

      if (values.reserveren) {
        const result = await api.v2.transport.reservering.levering.reserverenProducten({
          filterSchema: {
            filters: [
              { naam: 'MAG_IDS', data: [values.magID] },
              { naam: 'TYPE_IDS', data: [values.typeID!] },
            ],
          },
        });
      }

      actions.setSubmitting(false);
      onSuccess(null);
    },
    [props.inkOpdID, dienst, producttypen, voorraadInfo],
  );

  return (
    <Dialoog index={dialoogIndex || 0}>
      <ModalHeader>
        <ModalTitle>
          Inslag producten {opdracht.data !== null ? ` voor ref. ${opdracht.data.Referentie}` : ''}
        </ModalTitle>
      </ModalHeader>

      {initialValues === null || opdracht.state === ERemoteDataState.Pending ? (
        <div className="flex-fill d-flex align-items-center justify-content-center p-3">
          <LoadingSpinner />
        </div>
      ) : (
        <Formik<IFormikValues>
          onSubmit={handleSubmit}
          enableReinitialize
          initialValues={initialValues}
          isInitialValid
          validationSchema={validationSchema}
          render={(formikProps: FormikProps<IFormikValues>) => {
            const { submitForm, isSubmitting, isValid, values } = formikProps;
            return (
              <>
                <ModalBody>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <label>Besteld type</label>
                        <VeldWeergave>
                          <div>
                            {opdracht.data !== null ? (
                              <span>
                                {opdracht.data.producttype.Merknaam}{' '}
                                {opdracht.data.producttype.Typenaam}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        </VeldWeergave>
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.typeID}</label>

                        <Field
                          name={nameOf<IFormikValues>('typeID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (producttypen === null) {
                              return <LoadingSpinner />;
                            }

                            return (
                              <MultiCombobox<number, any>
                                sleutelExtractor={(
                                  row: IOphalenProducttypenAlternatiefResultElement,
                                ) => row.TypeID}
                                onWaardeChange={async (waarde: number | null) => {
                                  fieldProps.form.setFieldValue(field.name, waarde);
                                  setTypeID(waarde);
                                }}
                                representatieFabriek={(
                                  row: IOphalenProducttypenAlternatiefResultElement,
                                ) => `${row.Merknaam} - ${row.Typenaam}`}
                                waarde={fieldProps.field.value}
                                opties={
                                  producttypen !== null
                                    ? producttypen.map((x) => {
                                        return {
                                          TypeID: x.TypeID,
                                          Typenaam: x.Typenaam,
                                          Merknaam: x.Merknaam,
                                          Kenmerk: x.Kenmerk,
                                        };
                                      })
                                    : []
                                }
                                kolommen={typenKolommen}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-2">
                        <label>{veldnamen.magID}</label>

                        <Field
                          name={nameOf<IFormikValues>('magID')}
                          render={(fieldProps: FieldProps<IFormikValues>) => {
                            const { field, form } = fieldProps;

                            if (magazijnen === null) {
                              return <LoadingSpinner />;
                            }
                            return (
                              <MultiCombobox<number, any>
                                sleutelExtractor={(row) => row.MagID}
                                onWaardeChange={async (waarde: number | null) => {
                                  fieldProps.form.setFieldValue(field.name, waarde);
                                  setMagID(waarde);
                                }}
                                representatieFabriek={(row) => {
                                  return `${row.NaamKort} - ${row.Plaatsnaam}`;
                                }}
                                waarde={magID}
                                opties={
                                  magazijnen !== null
                                    ? magazijnen.map((x) => {
                                        return {
                                          MagID: x.MagID,
                                          NaamKort: x.NaamKort,
                                          Plaatsnaam: x.locatie.Plaatsnaam,
                                        };
                                      })
                                    : []
                                }
                                kolommen={magazijnKolommen}
                              />
                            );
                          }}
                        />
                      </div>

                      <div className="col-12 mt-2">
                        <div className="row d-flex align-items-end">
                          <div className="col-3">
                            <label>{veldnamen.aantal}</label>
                            <Field
                              name={nameOf<IFormikValues>('aantal')}
                              render={(fieldProps: FieldProps<IFormikValues>) => {
                                const { field, form } = fieldProps;
                                return (
                                  <NumeriekVeld
                                    waarde={field.value}
                                    onChange={(x) => form.setFieldValue(field.name, x)}
                                    min={1}
                                    max={100}
                                  />
                                );
                              }}
                            />
                          </div>

                          {/* <div className="col-9 mb-2">
                            <small className="text-muted ml-1">Negatief is correctie</small>
                          </div> */}
                        </div>
                      </div>

                      <div className="col-12 mt-3">
                        <label>{veldnamen.datumInslag}</label>
                        <Field
                          name={nameOf<IFormikValues>('datumInslag')}
                          render={({ field, form }: FieldProps<IFormikValues>) => {
                            const minimaleDatum = addDays(new Date(), -100);
                            const maximaleDatum = addDays(new Date(), +7);
                            return (
                              <div className="d-flex ">
                                <DatumKiezer
                                  onGewijzigd={(x) => form.setFieldValue(field.name, x)}
                                  waarde={field.value}
                                  determineValidDate={(date) => {
                                    return date >= minimaleDatum && date <= maximaleDatum;
                                  }}
                                  determineNextValidDate={(date) => {
                                    const nieuweDatum = addDays(date, +1);
                                    if (nieuweDatum <= maximaleDatum) {
                                      return nieuweDatum;
                                    }
                                    return null;
                                  }}
                                  determinePreviousValidDate={(date) => {
                                    const nieuweDatum = addDays(date, -1);
                                    if (nieuweDatum >= minimaleDatum) {
                                      return nieuweDatum;
                                    }
                                    return null;
                                  }}
                                  isClearable
                                />
                              </div>
                            );
                          }}
                        />
                      </div>

                      {voorraadInfo !== null && (
                        <>
                          {voorraadInfo.aantalInkoopGereserveerdPlanning +
                            voorraadInfo.aantalInkoopGereserveerdUitstaand >
                          0 ? (
                            <>
                              <div className="col-12 d-flex align-items-center mt-3">
                                <Field
                                  name={nameOf<IFormikValues>('reserveren')}
                                  render={(fieldProps: FieldProps<IFormikValues>) => {
                                    const { field, form } = fieldProps;
                                    return (
                                      <VinkVeld
                                        aangevinkt={field.value}
                                        onGewijzigd={(x) => {
                                          form.setFieldValue(field.name, x);
                                        }}
                                      />
                                    );
                                  }}
                                />

                                {values.aantal !== null && (
                                  <span className="ml-2">
                                    {veldnamen.reserveren}{' '}
                                    {values.aantal + voorraadInfo.aantalVrij >=
                                    voorraadInfo.aantalInkoopGereserveerdPlanning +
                                      voorraadInfo.aantalInkoopGereserveerdUitstaand
                                      ? `(${voorraadInfo.aantalInkoopGereserveerdPlanning +
                                          voorraadInfo.aantalInkoopGereserveerdUitstaand} van ${voorraadInfo.aantalInkoopGereserveerdPlanning +
                                          voorraadInfo.aantalInkoopGereserveerdUitstaand} opdrachten)`
                                      : `(${values.aantal +
                                          voorraadInfo.aantalVrij} van ${voorraadInfo.aantalInkoopGereserveerdPlanning +
                                          voorraadInfo.aantalInkoopGereserveerdUitstaand} opdrachten)`}
                                  </span>
                                )}
                              </div>
                            </>
                          ) : (
                            <div className="col-12 mt-3">
                              <VeldWeergave>
                                Er zijn voor het geleverde producttype geen inkoopreserveringen
                                aanwezig om toe te wijzen
                              </VeldWeergave>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="d-flex flex-row justify-content-start">
                  <button
                    className="btn btn-primary"
                    onClick={submitForm}
                    style={{ width: 100 }}
                    disabled={isSubmitting || !isValid}
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={onAnnuleren}
                    style={{ width: 100 }}
                  >
                    Annuleren
                  </button>
                </ModalFooter>
              </>
            );
          }}
        />
      )}
    </Dialoog>
  );
};

export default InslagDialoog;
